import React, { useContext, useEffect, useState } from "react";
import {
  useDashboardDate,
  useDetailsKpiData,
} from "../../../../../libs/data-access";
import { BASE_URL } from "../../../../../libs/data-access/constant";
import {
  calculateYearForDashboard,
  showToast,
} from "../../../../../utils/utility";
import { DashboardFilterContext } from "../../../../Dashboard/DashboardFilterContext";
import { schoolLevelMentoring } from "../../../../../types";
import moment from "moment";

const useSchoolLevelMentoring = () => {
  const [scoolLevelMentoring, setScoolLevelMentoring] = useState<
    schoolLevelMentoring[]
  >([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage] = useState(10);
  const [loader, setLoader] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);

  const [searchQuery, setSearchQuery] = useState("");
  const [currentItems, setCurrentItems] = useState<schoolLevelMentoring[]>([]); // Specify the type here
  const [recordsXL, setRecordsXL] = useState<schoolLevelMentoring[]>([]);
  const [currentChange,setCurrentChange] = useState<any>(false)

  const { fetchDetailKpi } = useDetailsKpiData();

  const { fetchDashboardDataStaging } = useDashboardDate();

  const {
    selectedMonth,
    selectedDistrict,
    selectedBlock,
    selectedCluster,
    districts,
    blocks,
    isSearching,
    setIsLoadingSchoolVisit,
    setIsSearching,
    selectedSession,
  } = useContext(DashboardFilterContext);

  const getSchoolMentoringApi = async () => {
    setLoader(true);
    // const res = await fetchDetailKpi(`Reports/Dashboard/GetMentoringStatusByFilter/${selectedDistrict ?? 0}/${selectedBlock ?? 0}/${selectedCluster ?? 0}?OFFSET=${currentPage}&PAGESIZE=${itemsPerPage}&GetMonthYearFromDate=${`${selectedMonth ? calculateYearForDashboard(+selectedMonth, Number(selectedSession)):moment().format('YYYY')}/${selectedMonth}/01`}`)

    // const res = await fetchDashboardDataStaging(
    //   `${BASE_URL}Reports/Dashboard/GetMentoringStatusByFilter/${
    //     selectedDistrict ?? 0
    //   }/${selectedBlock ?? 0}/${selectedCluster ?? 0}?FromDate=${`${
    //     selectedMonth ? calculateYearForDashboard(+selectedMonth, Number(selectedSession)) : moment().format('YYYY')
    //   }/${selectedMonth}/01`}&ToDate=${null}&OFFSET=${currentPage}&PAGESIZE=${itemsPerPage}`
    // );

    const res = await fetchDashboardDataStaging(
      `${BASE_URL}Reports/Dashboard/GetMentoringStatusByFilter/${
        selectedDistrict ? selectedDistrict : 0
      }/${selectedBlock ? selectedBlock : 0}/${
        selectedCluster ? selectedCluster : 0
      }?OFFSET=${currentPage * 10}&PAGESIZE=${itemsPerPage}`
    );

    // console.log("text", res);
    if (res.isSuccess) {
      let filterdata = res?.data?.map((item: any) => {
        return {
          districtName:
            item?.district_Name?.length > 0 ? item?.district_Name : null,
          blockName: item?.block_Name?.length > 0 ? item?.block_Name : null,
          clusterName:
            item?.cluster_School_Name?.length > 0
              ? item?.cluster_School_Name
              : null,
          schoolCode: item?.school_Code,
          schoolName: item?.school_Name,
          lastVisit: item?.lastVisitDate,
          daysSinceLastVisit: item?.daysSinceLastVisit,
        };
      });
      // Sorting filterData array
      // filterdata.sort((a:any, b:any) => {
      //   if (a.lastVisit === null && b.lastVisit === null) {
      //     return 0; // If both dates are null, leave them in their current order
      //   } else if (a.lastVisit === null) {
      //     return -1; // If only a's date is null, move it to the top
      //   } else if (b.lastVisit === null) {
      //     return 1; // If only b's date is null, move it to the top
      //   } else {
      //     // If both dates are not null, sort them based on the lastVisit date
      //     return 0;
      //   }
      // });

      if (!currentChange) {
        setCurrentChange(true);
      }
      setTotalRecords(res?.data[0]?.total);
      setScoolLevelMentoring(filterdata);
    } else {
      setScoolLevelMentoring([]);
      showToast({
        type: "error",
        message: res.message || "Something went wrong",
      });
    }
    setLoader(false);
  };

  // console.log("totalRecords", totalRecords);

  const getSchoolMentoringApiFortotal = async () => {
    // const res = await fetchDashboardDataStaging(
    //   `${BASE_URL}Reports/Dashboard/GetMentoringStatusByFilter/${
    //     selectedDistrict ?? 0
    //   }/${selectedBlock ?? 0}/${selectedCluster ?? 0}?FromDate=${`${
    //     selectedMonth ? calculateYearForDashboard(+selectedMonth, Number(selectedSession)) : moment().format('YYYY')
    //   }/${selectedMonth}/01`}&ToDate=${null}&OFFSET=${currentPage}&PAGESIZE=${totalRecords}`
    // );

    const res = await fetchDashboardDataStaging(
      `${BASE_URL}Reports/Dashboard/GetMentoringStatusByFilter/${
        selectedDistrict ?? 0
      }/${selectedBlock ?? 0}/${selectedCluster ?? 0}?OFFSET=${
        currentPage * 10
      }&PAGESIZE=${totalRecords}`
    );

    // console.log("forcsv", res);
    if (res.isSuccess) {
      let filterdata = res?.data?.map((item: any) => {
        return {
          districtName:
            item?.district_Name?.length > 0 ? item?.district_Name : null,
          blockName: item?.block_Name?.length > 0 ? item?.block_Name : null,
          clusterName:
            item?.cluster_School_Name?.length > 0
              ? item?.cluster_School_Name
              : null,
          schoolCode: item?.school_Code,
          schoolName: item?.school_Name,
          lastVisit: item?.lastVisitDate,
          daysSinceLastVisit: item?.daysSinceLastVisit,
        };
      });
      setRecordsXL(filterdata);
    } else {
      setRecordsXL([]);
      showToast({
        type: "error",
        message: res.message || "Something went wrong",
      });
    }
  };

  useEffect(() => {
    //   const indexOfLastItem = currentPage * itemsPerPage;
    // const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const filteredElements = scoolLevelMentoring?.filter((item: any) =>
      item?.schoolName?.toLowerCase()?.includes(searchQuery?.toLowerCase())
    );
    // const currentItems = filteredElements.slice(indexOfFirstItem, indexOfLastItem);
    setCurrentItems(filteredElements); // Update currentItems state with filtered data
  }, [searchQuery, scoolLevelMentoring]);

  const nextPage = () => setCurrentPage(currentPage + 1);
  const prevPage = () => setCurrentPage(currentPage - 1);

  useEffect(() => {
    if (isSearching || currentChange) {
      getSchoolMentoringApi();
    } 
  }, [currentPage, isSearching]);

  useEffect(() => {
    if (totalRecords > 0) {
      getSchoolMentoringApiFortotal();
    }
  }, [totalRecords]);

  return {
    scoolLevelMentoring,
    currentPage,
    setCurrentPage,
    itemsPerPage,
    setSearchQuery,
    nextPage,
    prevPage,
    searchQuery,
    currentItems,
    loader,
    totalRecords,
    recordsXL,
  };
};

export default useSchoolLevelMentoring;
