import React from 'react'
import PageLayout from '../../../PageLayout'
import { EButton, EEmpty, ELabelView, EPageTitle, ESectionDivider } from '../../../libs/ui'
import { Button, Checkbox, Grid, Modal, MultiSelect, Select, TextInput, Textarea } from '@mantine/core'
import useCreateNewSurvey from './hooks/useCreateNewSurvey'
import { DatePicker } from '@mantine/dates'
import moment from 'moment'
import { BsList, BsPlus } from 'react-icons/bs'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import SurveyFilter from '../Component/SurveyFilter'
import QuestionGroup from '../Component/QuestionGroup'
import SurveyGroup from '../Component/SurveyGroup'
import { MdPlaylistAdd } from 'react-icons/md'
import { SurveyFilterContext } from '../SurveyFilterContext'
import { showToast } from '../../../utils/utility'
import { useNavigate } from 'react-router-dom'
import { BiLeftArrow } from 'react-icons/bi'

export default function CreateNewSurvey() {

  const navigate = useNavigate();

  const {
    isSavingSurvey,
    confirmSaveSchedule,
    setConfirmSaveSchedule,
    isOpenNewQuestionModal,
    setISOpenNewQuestionModal,
    DefaultNewQuestion,
    DefaultNewQuestionGroup,
    newSurvey,
    setNewSurvey,
    newQuestionGroup,
    setNewQuestionGroup,
    newQuestion,
    setNewQuestion,
    newOption,
    setNewOption,
    handleSaveSurvey,
    handleAddNewQuestion,
    surveyQuestionTypeIds,
    surveySubQuestionTypeIds,
    selectedQuestionTypeIds,
    setSelectedQuestionTypeIds,
    selectedSubQuestionTypeIds,
    setSelectedSubQuestionTypeIds,
    selectedDistrict,
    setSelectedDistrict,
    selectedRole,
    setSelectedRole,
    classForStudent,
    setClassForStudent
  } = useCreateNewSurvey()

  return (
    <SurveyFilterContext.Provider
    value={{
      selectedDistrict,
      setSelectedDistrict,
      selectedRole,
      setSelectedRole,
      classForStudent,
      setClassForStudent
    }}
    >
    <PageLayout>
      <EPageTitle 
      title='Create New Survey' 
      end={
        <Button 
        leftIcon={<BiLeftArrow />}
        className='btn-blue'
        onClick={() => navigate("/survey")}
        >
          Back 
        </Button>
      }
      />
      {/* FILTERS */}
      <SurveyFilter studentWithClass />
      {/* Survey Details */}
      <ESectionDivider
      title='Survey Details'
      />

      <div className=''>
        <Grid className='mt-3'>
          <Grid.Col span={6}>
            <Textarea
              label="Title"
              placeholder='Enter Title'
              size='md'
              required
              value={newSurvey?.survey_Title ?? null}

              onChange={(e =>
                setNewSurvey({ ...newSurvey, survey_Title: e?.target?.value ?? "" }))}
            />
            <DatePicker
              label="Start Date"
              placeholder='Select Date'
              className='mt-3'
              size='md'
              required
              minDate={new Date(moment().format("YYYY-MM-DD"))}
              value={newSurvey?.survey_Start_Date ? new Date(moment(newSurvey?.survey_Start_Date).format("YYYY-MM-DD")) : null}
              onChange={(e) =>
                setNewSurvey(
                  {
                    ...newSurvey,
                    survey_End_Date: "",
                    survey_Start_Date: e ? moment(e)?.format("YYYY-MM-DD") : ""
                  })
              }
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <Textarea
              label="Description"
              placeholder='Enter Description'
              size='md'
              value={newSurvey?.survey_Description ?? null}
              onChange={(e) => {
                setNewSurvey({ ...newSurvey, survey_Description: e?.target?.value ?? "" })
              }}
            />
            <DatePicker
              label="End Date"
              placeholder='Select Date'
              className='mt-3'
              size='md'
              required
              minDate={newSurvey?.survey_Start_Date ? new Date(moment(newSurvey?.survey_Start_Date).add(1, "days").format("YYYY-MM-DD")) : undefined}
              value={newSurvey?.survey_End_Date ? new Date(moment(newSurvey?.survey_End_Date).format("YYYY-MM-DD")) : null}
              onChange={(e) => {
                setNewSurvey({ ...newSurvey, survey_End_Date: e ? moment(e)?.format("YYYY-MM-DD") : "" })
              }}
            />
          </Grid.Col>
        </Grid>
      </div>

      {/* Question Group */}

      <ESectionDivider
        title='Questions Group'
      />
      <Grid>
        <Grid.Col span={6}>
          <Textarea
            label="Question Section Title"
            size='md'
            placeholder='Enter Title'
            required
            value={newQuestionGroup?.question_Section_Title ?? ""}
            onChange={(e) => {
              setNewQuestionGroup(prev => ({ ...prev, question_Section_Title: e?.target?.value }))
            }}
          />
        </Grid.Col>
        <Grid.Col span={6}>
          <Textarea
            label="Question Section Description"
            size='md'
            placeholder='Enter Description'
            value={newQuestionGroup?.question_Section_Description ?? ""}
            onChange={(e) => {
              setNewQuestionGroup(prev => ({ ...prev, question_Section_Description: e?.target?.value }))
            }}
          />
        </Grid.Col>
      </Grid>

      {
        newQuestionGroup?.question_Section_Title?.trim().length > 4 ?
          <>
            {/* Question */}
            <ESectionDivider
              title='Survey Questions'
            />

            {newQuestionGroup?.questions?.length ?
              <>
                <div className='mb-2 flex justify-start'>
                  <EButton
                    size="md"
                    className="btn-blue"
                    onClick={() =>
                      setISOpenNewQuestionModal(!isOpenNewQuestionModal)
                    }
                  >
                    <BsPlus /> New Question
                  </EButton>
                </div>
                <QuestionGroup
                  questionGroup={newQuestionGroup}
                />
              </>
              :
              <EEmpty
                title='The list of questions is blank.'
                button={<EButton
                  size="md"
                  className="btn-blue"
                  onClick={() =>
                    setISOpenNewQuestionModal(!isOpenNewQuestionModal)
                  }
                >
                  <BsPlus /> Add Question
                </EButton>}
              />
            }
          </>
          :
          <div className='mt-5'>
            <EEmpty
              title='Step 1: Begin by inputting a question group title containing a minimum of 5 characters.'
            />
          </div>
      }
      <div className='mb-2 mt-10 border-t-2 pt-3 flex justify-end'>
        <EButton
          size="md"
          className="btn-blue"
          disabled={newQuestionGroup?.question_Section_Title && newQuestionGroup?.questions?.length ? false : true}
          onClick={() =>{
             setNewSurvey(prev=>({...prev,question_Groups:[...prev?.question_Groups,newQuestionGroup]}))
             setNewQuestionGroup(DefaultNewQuestionGroup)
          }
          }
        >
          <MdPlaylistAdd
           size={22} 
           className='mr-2' 
           /> Submit Question Group
        </EButton>
      </div>


      {/* Question group Question list */}
      <ESectionDivider
        title='Survey Questions Group List'
      />
      {newSurvey?.question_Groups?.length ?
        <>
          <SurveyGroup
            surveyQuestionsGroup={newSurvey}
          />
        </>
        :
        <></>
      }

      {/* Add Question  to Question group */}
      <Modal
        opened={isOpenNewQuestionModal}
        size={950}
        title="New Question"
        onClose={() => {
          setNewQuestion(DefaultNewQuestion)
          setISOpenNewQuestionModal(false)
        }}
        children={
          <>
            <Grid >
              <Grid.Col span={6}>
                <Textarea
                  label="Question Title"
                  required
                  size='md'
                  placeholder='Enter Title'
                  value={newQuestion?.survey_Question_Title ?? ''}
                  onChange={(e) => {
                    setNewQuestion(prev => ({ ...prev, survey_Question_Title: e?.target?.value }))
                  }}
                />
                <Textarea
                  className='mt-3'
                  label="Question Instruction"
                  size='md'
                  placeholder='Enter Instruction'
                  value={newQuestion?.survey_Question_Instructions ?? ' '}
                  onChange={(e) => {
                    setNewQuestion(prev => ({ ...prev, survey_Question_Instructions: e?.target?.value }))
                  }}
                />
              </Grid.Col>
              <Grid.Col span={6}>
                <Textarea
                  label="Question Description"
                  size='md'
                  placeholder='Enter Description'
                  value={newQuestion?.survey_Question_Description ?? ''}
                  onChange={(e) => {
                    setNewQuestion(prev => ({ ...prev, survey_Question_Description: e?.target?.value }))
                  }}
                />
                <Select
                  label="Survey Question Type"
                  size='md'
                  required
                  className='mt-3'
                  data={surveyQuestionTypeIds?.map(each=>({
                   label:each?.survey_Question_Type.replaceAll("_"," "),
                   value: String(each?.survey_Question_Type_Id)
                  }))}
                  value={String(newQuestion?.survey_Question_Type_Id ?? "")}
                  onChange={(e) => {
                    // console.log(e,`survey_Question_Type_Id`)
                    setNewQuestion({ ...newQuestion, survey_Question_Type_Id: e ? +e : 0})
                  }}
                />
              </Grid.Col>
            </Grid>

            <Grid className='mt-3'>
              { newQuestion?.survey_Question_Type_Id == 2 &&
                <Grid.Col span={6}>
              <TextInput
                  label="Survey Character Limit"
                  required
                  size='md'
                  value={newQuestion?.survey_Character_Limit ? newQuestion?.survey_Character_Limit : ""}
                  error={isNaN(+newQuestion?.survey_Character_Limit) ? "Value is not a number" : ""}
                  onChange={(e) => {
                    setNewQuestion({ ...newQuestion, survey_Character_Limit: Number(e?.target?.value)})
                  }}
                />
              </Grid.Col>}
              {/* <Grid.Col span={6}>
                <Select
                  label="Survey Sub Question Type Id"
                  size='md'
                  required
                  nothingFound="Sub question are not available for this question"
                  data={surveySubQuestionTypeIds?.map(each=>({
                    label:each?.survey_Sub_Question_Type,
                    value: String(each?.survey_Sub_Question_Type_Id)
                  }))}
                  value={String(selectedSubQuestionTypeIds) ?? ""}
                  onChange={(e)=>{
                    setSelectedSubQuestionTypeIds(e ? +e : undefined)
                  }}
                />
              </Grid.Col> */}
            </Grid>

           { newQuestion?.survey_Question_Type_Id == 1 || newQuestion?.survey_Question_Type_Id == 4 ?  <>
           <ESectionDivider
              title='Options'
            />
            { newQuestion?.survey_Options_List?.length < 2 && <p className='text-slate-300 text-xs'>Enter at least 2 options</p>}
            <div>
              {newQuestion?.survey_Options_List?.length ?
                newQuestion?.
                  survey_Options_List?.
                  map(
                    (each, index) =>
                    (
                      <div className='bg-slate-100 p-2 mt-3 text-lg flex justify-between font-semibold rounded-md text-center'>
                        {index + 1 + ". " + each?.survey_Options}
                        <AiOutlineCloseCircle
                          className='text-red-500 cursor-pointer'
                          size={20}
                          onClick={() => {
                            let newArr = [...newQuestion?.survey_Options_List]
                            newArr?.splice(index, 1)
                            setNewQuestion(prev => ({ ...prev, survey_Options_List: newArr }))
                          }}
                        />
                      </div>
                    ))
                :
                <EEmpty
                  title='Option list is Empty'
                />
              }
            </div>

            <div className='flex items-center pt-3 gap-5 h-full justify-center'>
              <TextInput
                placeholder='Enter Option'
                size='md'
                className='w-full'
                value={newOption}
                onChange={(e) => {
                  setNewOption(e?.target?.value)
                }}
              />
              <EButton
                className="btn-green"
                size="md"
                disabled={!newOption.trim()}
                onClick={() => {
                  let allOptions = [...newQuestion?.survey_Options_List, {
                    survey_Options: newOption
                  }]
                  setNewQuestion({ ...newQuestion, survey_Options_List: allOptions })
                  setNewOption('')
                }
                }
              >
                <BsPlus /> Add Option
              </EButton>
            </div>
            </>
          :<></>}
            <div className='flex justify-end gap-4 mt-6 p-4'>
              <EButton
                className="btn-light"
                size="md"
                onClick={() => {
                  setNewQuestion(DefaultNewQuestion)
                  setNewOption("")
                  setISOpenNewQuestionModal(false)
                }}
              >
                Cancel
              </EButton>
              <EButton
                size="md"
                className="btn-blue"
                // TODO : add condition of question id and survey option id 
                disabled={!newQuestion?.survey_Question_Title.trim() || !newQuestion?.survey_Question_Type_Id ? true : false}
                onClick={() => {
                  if(newQuestion?.survey_Question_Type_Id == 2 && !newQuestion?.survey_Character_Limit){
                     showToast({
                      type:"warning",
                      title:"Please enter survey character limit",
                      message:"Surevey character limit is required for input Box"
                     })
                  }
                  else if(newQuestion?.survey_Question_Type_Id == 1 && newQuestion?.survey_Options_List?.length < 2) {
                     showToast({
                      type:"warning",
                      title:"Please enter at least 2 options",
                      message:"At least 2 options are required for Radio type question"
                     })
                  }
                  else if( newQuestion?.survey_Question_Type_Id == 4 && newQuestion?.survey_Options_List?.length < 2) {
                     showToast({
                      type:"warning",
                      title:"Please enter at least 2 options",
                      message:"At least 2 options are required for checkbox type question"
                     })
                  }
                  else{
                  handleAddNewQuestion()
                }}}
              >
                <BsPlus /> Add New Question
              </EButton>
            </div>
          
          </>
          }
      />
      <div className="mt-5 flex justify-between border-t-2 pt-4">
          <Checkbox
            label={"Are you sure you want to save?"}
            checked={confirmSaveSchedule}
            onClick={() => setConfirmSaveSchedule(!confirmSaveSchedule)}
          />

          <EButton
            className="btn-blue"
            loading={isSavingSurvey}
            disabled={!confirmSaveSchedule}
            onClick={() =>handleSaveSurvey()}
          >
            Save
          </EButton>
        </div>
    </PageLayout>
    </SurveyFilterContext.Provider>
  )
}
