import React, { useContext, useEffect, useState } from 'react'
import { AssessmentFilterContext } from '../../Components/Hooks/useAssessmentFilter';
import { useStudentLearning } from '../../../../../libs/data-access';
import { calculateYearForDashboard, showToast } from '../../../../../utils/utility';
import moment from 'moment';

const useSpotAssessmentCompetencyWiseData = () => {

  const { fetchSpotAssessmentSchool } = useStudentLearning();

 const {
   handleChangeFilters,
   handleIsSearching,
   isSearching,
   district,
   block,
   cluster,
   school,
   grade,
   subject,
   competency /* In the Competency some are negative value which is specified to orf such as -21 in this 2 is 2nd class and 1 is subject id in orf  */,
   month,
   activeTab,
   session,
   selectedSession,
 } = useContext(AssessmentFilterContext);


    const [isLoading,setIsLoading] = useState(false)
    const [
      spotAssessmentCompetencyWiseData,
      setSpotAssessmentCompetencyWiseData,
    ] = useState([])
    const [orfResultResultCSV, setOrfResultResultCSV] = useState<any>({
      content: [],
      headers:[],
    });

      const formatORFResultReportData = (data: any) => {
        let array = [
          "session",
          "date",
          "district_Name",
          "block_Name",
          "cluster_School_Name",
          "school_Code",
          "school_Name",
          "class_Name",
          "subject_Name",
          "competency",
          "total_Spot_Tested_Student",
          "total_Mastery_Spot_Tested_Student",
        ];
        // let headers = Object.keys(data[0]).map((eachKey) => ({
        //   label: eachKey.replaceAll("_", " "),
        //   key: eachKey,
        // }));

        // let values = data;

        // Filter headers based on the keys in the array
        // let headers = Object.keys(data[0])
        //   .filter((key) => array.includes(key))
        //   .map((eachKey) => ({
         let headers = array
           .filter((key) => Object.keys(data[0]).includes(key)) // Only include keys that exist in data
           .map((eachKey) => ({
             label:
               eachKey === "session"
                 ? "Session"
                 : eachKey === "date"
                 ? "Month"
                 : eachKey === "district_Name"
                 ? "District"
                 : eachKey === "block_Name"
                 ? "Block"
                 : eachKey === "cluster_School_Name"
                 ? "Cluster School Name"
                 : eachKey === "school_Code"
                 ? "School Code"
                 : eachKey === "school_Name"
                 ? "School Name"
                 : eachKey === "class_Name"
                 ? "Class"
                 : eachKey === "subject_Name"
                 ? "Subject"
                 : eachKey === "competency"
                 ? "Competency"
                 : eachKey === "total_Spot_Tested_Student"
                 ? "No. of Students Tested"
                 : eachKey === "total_Mastery_Spot_Tested_Student"
                 ? "No. of Students Mastered"
                 : eachKey.replaceAll("_", " "), // Show 'Month' in the header
             key: eachKey,
           }));

        // Filter each object in data to include only keys in the array
        let values = data.map((item: any) => {
          let filteredItem: any = {};
          array.forEach((key) => {
            if (key in item) {
              filteredItem[key] = item[key];
            }
          });
          return filteredItem;
        });

        setOrfResultResultCSV({
          headers: headers,
          content: values,
        });
      };



  const fetchORFReport = async () => {
    setIsLoading(true);
   let academicYear = selectedSession ? `${
     +selectedSession - 1
   }-${selectedSession.toString().slice(-2)}` : 0
    const response = await fetchSpotAssessmentSchool(
      `GetSpotAssessmentData?&districtId=${district ?? 0}&blockId=${
        block ?? 0
      }&clusterSchoolId=${cluster ?? 0}&schoolId=${
        school ?? 0
      }&session=${academicYear}&monthYearFromDate=${`${
        month
          ? calculateYearForDashboard(+month, Number(selectedSession))
          : moment().format("YYYY")
      }-${month}-01`}&classId=${grade ?? 0}&subjectList=${[subject]}`
    );

    if (response.isSuccess) {
      handleIsSearching(false);
      setSpotAssessmentCompetencyWiseData(response.data);
      formatORFResultReportData(response.data);
    } else {
      handleIsSearching(false);
      console.log("Error while fetching ORF report: ", response.error);
      showToast({
        type: "error",
        message: response?.message || "Error while getting ORF result",
      });
    }
    setIsLoading(false);
  };


 useEffect(() => {
   if (isSearching && activeTab == "4") {
     fetchORFReport();
   } else {
     // console.log(isSearching);
   }
 }, [isSearching]);


    
  return {
    isLoading,
    spotAssessmentCompetencyWiseData,
    grade,
    orfResultResultCSV,
  };
}

export default useSpotAssessmentCompetencyWiseData