import React, { useEffect, useState } from 'react'
import { useSurveyData } from '../../../libs/data-access'
import { showToast } from '../../../utils/utility'
import { IDistrictBlock, IRole } from '../../../types'
import { ISurveyList } from '../../../libs/data-access/types'

export default function useSurveyList() {

    const {
        fetchSurveyData,
        postSurveyData
      } = useSurveyData()
      
    const [isLoading,setIsLoading] =useState<boolean>(false)
    const [isFirst,setIsFirst] =useState<boolean>(true)
    const [surveyList,setSurveyList]= useState<ISurveyList[]>([])
    const [selectedDistrict, setSelectedDistrict] = useState<IDistrictBlock[]>([])
    const [selectedRole, setSelectedRole] = useState<IRole[]>([])
    const [isSearching, setIsSearching] = useState<boolean>(false)
    const [classForStudent, setClassForStudent] = useState<string[]>([])

    useEffect(()=>{
            if(isFirst){
                setIsFirst(false)
            }
            else{
                // console.log("search")
                getSurveyList()
            }
    },[isFirst ,isSearching])
    
    // API function   
    const getSurveyList= async ()=>{
        setIsLoading(true)
         const res = await fetchSurveyData(`GetSurveyList`)
         if(res.isSuccess){
            setSurveyList(res?.data)
         }
         else{
            setSurveyList([])
            showToast({
                type:"error",
                message:res?.message ?? "Fail to fetch survey list"
            })
         }
        setIsLoading(false)
    }
  return {
   isLoading,
   isSearching,
   setIsSearching,
   surveyList,
   selectedDistrict,
   setSelectedDistrict,
   selectedRole,
   setSelectedRole,
   classForStudent,
        setClassForStudent
  }
}
