import React from 'react'
import PageLayout from '../../../../../PageLayout'
import { EButton, EPageTitle, ESectionDivider, ETable } from '../../../../../libs/ui'
import { Badge, Button, Card, CheckIcon, Checkbox, Grid, List, Switch, Tabs } from '@mantine/core'
import { BsArrowLeft, BsCheck2Square, BsCheckCircle, BsCheckSquare, BsList, BsPlus, BsPlusSquare, BsXCircle } from 'react-icons/bs'
import { useNavigate } from 'react-router-dom'
import useMarkAttendance from './hooks/useMarkAttendance'
import { ImCheckboxUnchecked } from 'react-icons/im'
import { FaUser } from 'react-icons/fa'
import { FiEdit } from 'react-icons/fi'
import AttendanceList from './Components/AttendanceList'
import EditLastAttendance from './Components/EditLastAttendance'
import MarkNewAttendance from './Components/MarkNewAttendance'

export default function MarkAttendance() {
    const navigate = useNavigate()

    const {
      teacherAttendanceList,
      setTeacherAttendanceList,
      activeTab,
      setActiveTab,
      isLoadingLastAttendanceDetails,
      lastAttendanceDetails,
      setLastAttendanceDetails,
      isLoadingAttendanceList,
      teacherMarkAttendanceList,
      handleSubmitAttendance,
      handleSubmitLastAttendance,
      isLoadingLastAttendanceUploading,
      index,
      getTeacherTrainingAttendanceStatus,
      isLoadingAttendanceStatus,
      disableTab,
    } = useMarkAttendance();

    const handleAttendanceChange = (index: number, event: any) => {
        const updatedAttendanceList = [...teacherAttendanceList ?? []];
        updatedAttendanceList[index].is_Present = event.target.checked;
        setTeacherAttendanceList(updatedAttendanceList);
    };
    const handleEditAttendanceChange = (index: number, event: any) => {
        const updatedLastAttendanceList = [...lastAttendanceDetails ?? []];
        updatedLastAttendanceList[index].is_Present = event.target.checked;
        setLastAttendanceDetails(updatedLastAttendanceList);
    };
    return (
      <>
        <PageLayout>
          <EPageTitle
            title="Attendance"
            end={
              <EButton
                size="md"
                className="btn-blue"
                onClick={() => navigate(`/block/schedule-list`)}
              >
                <p className="flex gap-1 items-center">
                  <BsArrowLeft size={18} /> {"  "} <span>Back</span>
                </p>
              </EButton>
            }
          />
          <Tabs value={activeTab} onTabChange={setActiveTab}>
            <Tabs.List className="gap-3 flex">
              <Tabs.Tab value="attendanceList" icon={<BsList size="0.8rem" />}>
                Attendance List
              </Tabs.Tab>
              <Tabs.Tab
                value="editLastAttendance"
                icon={<FiEdit size="0.8rem" />}
                disabled={disableTab}
              >
                Edit Latest / Last Attendance
              </Tabs.Tab>
              <Tabs.Tab
                value="markNewAttendance"
                icon={<BsPlusSquare size="0.8rem" />}
                disabled={disableTab}
              >
                Mark New Attendance
              </Tabs.Tab>
            </Tabs.List>

            <Tabs.Panel value="attendanceList" pt="xs">
              <AttendanceList
                isLoading={isLoadingAttendanceList}
                teacherMarkAttendanceList={teacherMarkAttendanceList ?? []}
                index={index}
                getTeacherTrainingAttendanceStatus={
                  getTeacherTrainingAttendanceStatus
                }
                isLoadingAttendanceStatus={isLoadingAttendanceStatus}
              />
            </Tabs.Panel>

            <Tabs.Panel value="editLastAttendance" pt="xs">
              <EditLastAttendance
                isLoading={isLoadingLastAttendanceDetails}
                isBtnLoading={isLoadingLastAttendanceUploading}
                teacherMarkAttendanceList={teacherMarkAttendanceList ?? []}
                lastAttendanceDetails={lastAttendanceDetails ?? []}
                handleSubmitLastAttendance={() => handleSubmitLastAttendance()}
                handleEditAttendanceChange={(index, event) =>
                  handleEditAttendanceChange(index, event)
                }
              />
            </Tabs.Panel>

            <Tabs.Panel value="markNewAttendance" pt="xs">
              <MarkNewAttendance
                isLoading={false}
                teacherMarkAttendanceList={teacherMarkAttendanceList ?? []}
                teacherListForAttendance={teacherAttendanceList ?? []}
                handleAttendanceChange={(index: number, event: any) =>
                  handleAttendanceChange(index, event)
                }
                handleSubmitAttendance={() => handleSubmitAttendance()}
              />
            </Tabs.Panel>
          </Tabs>
        </PageLayout>
      </>
    );
}
