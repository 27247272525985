import React, { useContext, useEffect, useState } from 'react'
import { DashboardFilterContext } from '../../../DashboardFilterContext';
import { IClassroomObservationMentorObservedCompliance, IClassroomObservationTeacherObservationCoverage, IDashboardMap, IDashboardMapData, IReportLevel } from '../../../../../libs/data-access/types';
import { useDashboardDate } from '../../../../../libs/data-access';
import moment from 'moment';
import { calculateYearForDashboard, showToast } from '../../../../../utils/utility';

export default function useClassroomObservation() {

  const
  {
      fetchDashboardData
  } = useDashboardDate()
  
  const {
    selectedMonth,
    selectedDistrict,
    selectedBlock,
    selectedCluster,
    districts,
    blocks,
    isSearching,
    setIsLoadingSchoolVisit,
    setIsSearching,
    selectedSession,
  } = useContext(DashboardFilterContext);
  
  const [isFirst, setIsFirst] = useState<boolean>(true)
  const [isLoadingClassroom, setIsLoadingClassroomObservation] = useState<boolean>(false)
  const [isLoadingTeacherObservationCoverage, setIsLoadingTeacherObservationCoverage] = useState<boolean>(false)
  const [isLoadingMentorObservedCompliance, setIsLoadingMentorObservedCompliance] = useState<boolean>(false)
  const [classroomObservation, setClassroomObservation] = useState<IDashboardMapData | undefined>(undefined)
  const [teacherObservationCoverage, setTeacherObservationCoverage] = useState<IClassroomObservationTeacherObservationCoverage | undefined>(undefined)
  const [mentorObservedCompliance, setMentorObservedCompliance] = useState<IClassroomObservationMentorObservedCompliance | undefined>(undefined)
  const [reportLevel, setReportLevel] = useState<IReportLevel>({levelNames:"state",districtName:"haryana", districtId:0 ,blockName :""});

  // useEffect(()=>{
  //       if(isFirst){
  //         setIsFirst(false)
  //       }
  //       else{
  //         getClassroomObservation()
  //         getTeacherObservationCoverage()
  //         getMentorObservedCompliance()
  //       }
  // },[isFirst])
 
   useEffect(()=>{
    if(isSearching){
    getClassroomObservation()
    getTeacherObservationCoverage()
    getMentorObservedCompliance()}
  },[isSearching])


  // API Functions

  const getClassroomObservation = async () => {
    setIsLoadingClassroomObservation(true)
    const res = await fetchDashboardData(
      `ClassRoomObservation/ClassRoomObservationClassWise?DistrictId=${
        selectedDistrict ?? 0
      }&BlockId=${selectedBlock ?? 0}&ClusterId=${
        selectedCluster ?? 0
      }&GetMonthYearFromDate=${`${
        selectedMonth
          ? calculateYearForDashboard(+selectedMonth, Number(selectedSession))
          : moment().format("YYYY")
      }/${selectedMonth}/01`}`
    );
    if (res.isSuccess) {
        setClassroomObservation(res?.data)
        // console.log(blocks ,selectedBlock)
        // console.log("test block",districts && selectedDistrict ? districts.filter((e) => e?.id == +selectedDistrict)[0]?.name : "Haryana" )
        setReportLevel(
          { 
          levelNames: selectedCluster ? "cluster" : selectedBlock ? "block" : selectedDistrict ? "district" : "state",
          districtName : districts && selectedDistrict ? districts.filter((e) => e?.id == +selectedDistrict)[0]?.name : "Haryana",
          districtId: selectedDistrict? +selectedDistrict : 0,
          blockName:  blocks && selectedBlock ? blocks.filter((e) => e?.id == +selectedBlock)[0]?.name : "" 
         })
    }
    else {
        setClassroomObservation(undefined)
        // showToast({
        //     type: "error",
        //     message: res?.message || "Fail to get classroom Observation"
        // })
    }
    setIsLoadingClassroomObservation(false)
    setIsSearching(false)
}
  const getTeacherObservationCoverage = async () => {
    setIsLoadingTeacherObservationCoverage(true)
    const res = await fetchDashboardData(
      `ClassRoomObservation/GetTeacherObservationCoverage?DistrictId=${
        selectedDistrict ?? 0
      }&BlockId=${selectedBlock ?? 0}&ClusterId=${
        selectedCluster ?? 0
      }&GetMonthYearFromDate=${`${
        selectedMonth
          ? calculateYearForDashboard(+selectedMonth, Number(selectedSession))
          : moment().format("YYYY")
      }/${selectedMonth}/01`}`
    );
    if (res.isSuccess) {
      let obj = {
        ...res.data,
        OverAll: {
          ...res.data.OverAll,
          Total_Teacher: res.data.OverAll.Total_teacher,
        },
      };
        // setTeacherObservationCoverage(res?.data)
        setTeacherObservationCoverage(obj);
    }
    else {
        setTeacherObservationCoverage(undefined)
        // showToast({
        //     type: "error",
        //     message: res?.message || "Fail to get teacher observation coverage "
        // })
    }
    setIsLoadingTeacherObservationCoverage(false)
}

  const getMentorObservedCompliance = async () => {
    setIsLoadingMentorObservedCompliance(true)
    const res = await fetchDashboardData(
      `ClassRoomObservation/GetMentorObservationCompliance?DistrictId=${
        selectedDistrict ?? 0
      }&BlockId=${selectedBlock ?? 0}&ClusterId=${
        selectedCluster ?? 0
      }&GetMonthYearFromDate=${`${
        selectedMonth
          ? calculateYearForDashboard(+selectedMonth, Number(selectedSession))
          : moment().format("YYYY")
      }/${selectedMonth}/01`}`
    );
    if (res.isSuccess) {
        setMentorObservedCompliance(res?.data)
    }
    else {
        setMentorObservedCompliance(undefined)
        // showToast({
        //     type: "error",
        //     message: res?.message || "Fail to get mentor observation compliance "
        // })
    }
    setIsLoadingMentorObservedCompliance(false)
}
  
  return {
      isLoadingClassroom,
      isLoadingTeacherObservationCoverage,
      isLoadingMentorObservedCompliance,
      teacherObservationCoverage,
      classroomObservation,
      mentorObservedCompliance,
      selectedBlock,
      selectedDistrict,
      districts,
      reportLevel
  }
}
