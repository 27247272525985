import React, { useContext, useEffect, useState } from 'react';

import { AppContext } from '../../../../../../AppContext';
import { showToast } from '../../../../../../utils/utility';
import { useTeacherTraining } from '../../../../../../libs/data-access';
import { IScheduleList, ITeacherTrainingScheduleDetails } from '../../../../../../libs/data-access/types';

export default function useScheduleList() {

  const { user } = useContext(AppContext);

  const {
    fetchTeacherTrainingData,
    PutTeacherTrainingData
  } = useTeacherTraining();


  const [isFirst, setIsFirst] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isChangingActiveStatus, setIsChangingActiveStatus] = useState<boolean>(false);
  const [isOpenSideDrawer, setIsOpenSideDrawer] = useState<boolean>(false);
  const [slotListWithGroupScheduleList, setSlotListWithGroupScheduleList] = useState<IScheduleList[]>([]);
  const [selectedSchedule, setSelectedSchedule] = useState<ITeacherTrainingScheduleDetails | undefined>(undefined);
  const [selectedSlot, setSelectedSlot] = useState<number | undefined>(undefined);
  const [isActiveTest, setIsActiveTest] = useState<boolean>(true);

  const [csvData, setCsvData] = useState<any>([])

  useEffect(() => {
    if (isFirst) {
      setIsFirst(false);
    } else {
      getScheduleList()
      
    };
  }, [isFirst]);

//  Handler function

  const ChangeTestActiveStatusForSelectedSchedule = (trainingData:ITeacherTrainingScheduleDetails , selectedTestId:number) => {
    // console.log("function call")
    const updatedTrainingData = { ...trainingData };
    updatedTrainingData.testDetails = updatedTrainingData.testDetails.map(test => {
      // console.log("update function enter")
      if (test.teacher_Training_Schedule_Test_Id === selectedTestId) {
        // console.log("test match with test id")
        return { ...test, is_Active: test?.is_Active ? false :true };
      }
      // console.log("test to update Selected Schedule",test)
      return test;
    });
    //  console.log("update schedule Data ",updatedTrainingData)
    setSelectedSchedule(updatedTrainingData); // Update the state with the modified object
  };
//  APi function
  const getScheduleList = async () => {
    setIsLoading(true);
    setSlotListWithGroupScheduleList([]);
    const res = await fetchTeacherTrainingData(`/BlockAdmin/GetTeacherTrainingSchedule/${user?.user_Id}`);
    if (res?.isSuccess) {
      setSlotListWithGroupScheduleList(res?.data);
    } else {
      showToast({
        type: "error",
        message: res?.message ?? "Error while getting schedule list"
      });
    };

    setIsLoading(false);
  };


  const deleteSchedule = async (id:any) => {
    setIsLoading(true);
    const res = await fetchTeacherTrainingData(
      `/DeleteTeacherTrainingSchedule/${id}`
    );
    if (res?.isSuccess) {
      getScheduleList()
    } else {
      showToast({
        type: "error",
        message: res?.message ?? "Error while getting schedule list",
      });
    }

    setIsLoading(false);
  };


// console.log("selectedSchedule",selectedSchedule)

    const getScheduleCsvData = async () => {
      setIsLoading(true);
      const res = await fetchTeacherTrainingData(
        `/GetTeacherTrainingScheduleFor_Excel?BlockId=${user?.user_Id}&Teacher_Training_Schedule_Id=${selectedSchedule?.teacher_Training_Schedule_Id}`
      );
      if (res?.isSuccess) {
        let allData: any = [];
          res?.data?.map((item: any) => {
          let rowData = {
            // District_Name: item.District_Name,
            // Block_Name: item.Block_Name,
            // School_Udise_Code: item.School_Udise_Code,
            "Employee Name": item.employee_Name,
            "School Name": item.school_Name,
            "Cluster School_Name": item.cluster_School_Name,
            // School_Code: item.School_Code,

            // Employee_Id: item.Employee_Id,

            // Designation: item.Designation,
          };
            allData.push(rowData);
          });
          setCsvData(allData);
      } else {
        showToast({
          type: "error",
          message: res?.message ?? "Error while downloading Data",
        });
      }
      setIsLoading(false);
    };

  const UpdateTestActiveStatus = async (testId: number, status: boolean) => {
    setIsChangingActiveStatus(true)
    const res = await PutTeacherTrainingData(`/BlockAdmin/ChangeTeacherTrainingTestStatus/${testId}/${!status}`, "")
    if (res.isSuccess) {
      showToast({
        type: "success",
        title: "successfully updated",
        message: "Test active status updated"
      })
      getScheduleList()
      selectedSchedule && ChangeTestActiveStatusForSelectedSchedule(selectedSchedule,testId)
    }
    else {
      showToast({
        type: "error",
        message: res?.message ?? "Error while changing active status"
      })
    }
    setIsChangingActiveStatus(false)
  };


  useEffect(() => {
    if (selectedSchedule){
getScheduleCsvData();
    } 
  }, [selectedSchedule])
  

  return {
    isLoading,
    slotListWithGroupScheduleList,
    isOpenSideDrawer,
    setIsOpenSideDrawer,
    selectedSchedule,
    setSelectedSchedule,
    selectedSlot,
    setSelectedSlot,
    isChangingActiveStatus,
    UpdateTestActiveStatus,

    csvData,
    deleteSchedule,
  };

}
