import React, { useContext, useEffect, useRef, useState } from "react";
import useMasterData from "../../../Hooks/useMasterData";
import { useStateAdmin } from "../../../../libs/data-access";
import { showToast } from "../../../../utils/utility";
import { IMediaType } from "../../../../libs/data-access/types";
import { AppContext } from "../../../../AppContext";
import {
  handleValidateGroupQuestionSpot,
  handleValidateQuestionParams,
  handleValidateQuestionSpot,
} from "../../../../utils/validators";
import { useLocation } from "react-router-dom";
import moment from "moment";

const useAddQuestionGroup = () => {
  const MEDIA_TYPE_QUESTION = 0;
  const MEDIA_TYPE_OPTION = 1;
  const location = useLocation();

  const { user, targetRef, scrollTriger, setScrollTriger } =
    useContext(AppContext);

  const { fetchStateAdmin, postStateAdmin, putStateAdmin } = useStateAdmin();
  const {
    assessmentTypes,
    getAssessmentTypes,
    subjectList,
    getSubject,
    classes,
    getClasses,
    competencyList,
    getCompetency,
    getCompetencyBasedOnClassAndSubject,
    competencyByClassSubject,
    questionType,
    GetQuestionType,
    spotAssessmentData,
    getWeeksData,

    spotCompetencyByClassSubject,
    getSpotCompetencyBasedOnClassAndSubject,
  } = useMasterData();

  const [filterValue, setFilterValue] = useState({
    selectedGrade: "",
    selectedSubject: "",
    competency_Id: "",
    WeekStartDate: "",
    WeekEndDate: "",
    questionType: 0,
    media_Type_Id: 0,
    option_Media_Type_Id: 0,
    question_Group_Instruction: "",
    min_Attempt_Question:0,
  });

  // console.log("filterValue", filterValue);
  const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true);
  const [allGroups, setAllGroups] = useState([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [mediaTypeForQuestion, setMediaTypeForQuestion] = useState<
    IMediaType[]
  >([]);
  const [mediaTypeForOptions, setMediaTypeForOptions] = useState<IMediaType[]>(
    []
  );

  const [questionDetails, setQuestionDetails] = useState<any>([
    {
      question_Text: "",
      base64QuestionImage: "",
      question_Media_Url: "",
      question_Marks: 0,
      typeOfURL: "",

      enlarge: false,
      enlargedText: "",

      question_Type_Id: filterValue?.questionType,
      media_Type_Id: filterValue?.media_Type_Id,
      option_Media_Type_Id: filterValue?.option_Media_Type_Id,

      spotQuestionOptions: [
        {
          base64OptionImage: "",
          option_Text: "",
          is_Correct: false,
        },
      ],
    },
  ]);

  const [addExecuted, setAddExecuted] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState<boolean>(false);

  // console.log("questionDetails", questionDetails);

  const handleSetCorrectOption = (index: number, childIndex: number) => {
    // Get the current question object
    const updatedQuestions = [...questionDetails];
    const question = updatedQuestions[index];

    // Update the is_Correct property of the selected option
    question.spotQuestionOptions = question.spotQuestionOptions.map(
      (option: any, index: number) => ({
        ...option,
        is_Correct: index === childIndex ? true : false, // Set to true for the selected option, false for others
      })
    );

    // Update the questionDetails state with the modified question
    setQuestionDetails(updatedQuestions);
  };

  const handleSetCorrectOptionMultiple = (
    index: number,
    childIndex: number
  ) => {
    // Get the current question object
    const updatedQuestions = [...questionDetails];
    const question = updatedQuestions[index];

    // Update the is_Correct property of the selected option
    question.spotQuestionOptions[childIndex].is_Correct = true;

    // Update the questionDetails state with the modified question
    setQuestionDetails(updatedQuestions);
  };

  const handleChangeQuestionDetails = (
    name: any,
    value: any,
    index: number
  ) => {
    // Update the state using the functional form of setState
    setQuestionDetails((prevQuestionDetails: any) => {
      // Copy the array and the object at the specified index
      let WholeArray = [...prevQuestionDetails];
      let indexObject = { ...WholeArray[index] };

      // Update the object with the new property
       if (name == "enlarge" && value == false) {
         indexObject[name] = value;
         indexObject.enlargedText = "";
       } else {
         indexObject[name] = value;
       }

      // Replace the object at the specified index in the copied array
      WholeArray.splice(index, 1, indexObject);

      // Log the updated array
      // console.log("Updated Array:", WholeArray);

      // Return the updated array
      return WholeArray;
    });
  };

  const addQuestion = () => {
    let WholeArray = [...questionDetails];
    let obj = {
      question_Text: "",
      base64QuestionImage: "",
      question_Media_Url: "",
      question_Marks: 0,

      question_Type_Id: filterValue?.questionType,
      media_Type_Id: filterValue?.media_Type_Id,
      option_Media_Type_Id: filterValue?.option_Media_Type_Id,

      enlarge: false,
      enlargedText: "",

      spotQuestionOptions: [
        {
          base64OptionImage: "",
          option_Text: "",
          is_Correct: false,
        },
      ],
    };

    WholeArray.push(obj);
    setQuestionDetails(WholeArray);
    // setAddExecuted(!addExecuted);

    // setFilterValue((prev)=>{
    //   return {
    //     ...prev,
    //     question_Type_Id: "",
    //     media_Type_Id: "",
    //     option_Media_Type_Id: "",
    //   };
    // })
  };

  const addOption = (index: number) => {
    let WholeArray = [...questionDetails];
    let indexObject = {
      ...questionDetails[index],
    };
    let childArray = [...indexObject.spotQuestionOptions];
    let obj = {
      base64OptionImage: "",
      option_Text: "",
      is_Correct: false,
    };
    childArray.push(obj);

    indexObject = {
      ...questionDetails[index],
      spotQuestionOptions: childArray,
    };
    WholeArray.splice(index, 1, indexObject);

    setQuestionDetails(WholeArray);
  };

  const deleteQuestion = (index: number) => {
    let WholeArray = [...questionDetails];
    if (WholeArray.length > 1) {
      WholeArray.splice(index, 1);
      setQuestionDetails(WholeArray);
    }
  };

  const deleteOption = (index: number, childIndex: number) => {
    let WholeArray = [...questionDetails];

    let indexObject = {
      ...questionDetails[index],
    };
    let childArray = [...indexObject.spotQuestionOptions];
    if (childArray.length > 1) {
      childArray.splice(childIndex, 1);

      indexObject = {
        ...questionDetails[index],
        spotQuestionOptions: childArray,
      };
      WholeArray.splice(index, 1, indexObject);
      setQuestionDetails(WholeArray);
    }
  };

  const handleChangeOptionDetails = (
    name: any,
    value: any,
    index: number,
    childIndex: number
  ) => {
    let WholeArray = [...questionDetails];
    let indexObject = {
      ...questionDetails[index],
    };
    let childArray = [...indexObject.spotQuestionOptions];
    let childObject = {
      ...childArray[childIndex],
      [name]: value,
    };
    childArray.splice(childIndex, 1, childObject);
    indexObject = {
      ...questionDetails[index],
      spotQuestionOptions: childArray,
    };
    WholeArray.splice(index, 1, indexObject);

    setQuestionDetails(WholeArray);
  };

  const GetSpotQuestionGroup = async (
    subject: String,
    grade: String,
    weekStart: any,
    weekEnd: any
  ) => {
    setIsLoading(true);
    const res = await fetchStateAdmin(
      `Question/GetSpotAssessmentQuestionGroups/${grade}/${subject}/${String(
        weekStart.toISOString()
      )}/${String(weekEnd.toISOString())}`
    );
    if (res.isSuccess) {
      setAllGroups(res?.data);
    } else {
      setAllGroups([]);
      showToast({
        type: "error",
        message: res?.message || "Fail to get question group",
      });
    }
    setIsLoading(false);
  };

  const handleChangeQuestionFilterParams = (
    key: any,
    value: string | undefined
  ) => {
    setFilterValue((prev: any) => ({ ...prev, [key]: value }));
  };

  /* API CALLS */
  const handleFetchMediaTypeForQuestion = async (mediaType: number) => {
    const response = await fetchStateAdmin(
      `Question/GetMediaTypeForQuestionOrOption/${mediaType}`
    );
    if (response.isSuccess) {
      // console.log("Response for media type: ", response.data);
      const data: IMediaType[] | undefined = response.data;
      if (data) {
         let optionData = data.filter((item: any) => item.media_Type_Id != 5);
        mediaType === 0
          ? setMediaTypeForQuestion(data)
          : setMediaTypeForOptions(optionData);
      }
    } else {
      showToast({
        type: "error",
        title: "Error Ocurred!",
        message: "Could not fetch media types",
      });
    }
  };

  /* API CALLS */
  const handleSaveQuestion = async () => {
    if (!user.user_Id) {
      showToast({
        type: "warning",
        title: "Please login again",
        message: "User could not be found",
      });
      return;
    }

    let newWeekStart: any = new Date(filterValue?.WeekStartDate);
    newWeekStart.setDate(newWeekStart.getDate() + 1);

    let newWeekEnd: any = new Date(filterValue?.WeekEndDate);
    newWeekEnd.setDate(newWeekEnd.getDate() + 1);

    setIsLoading(true);
    const payload: any = {
      question_Group_Instruction: filterValue?.question_Group_Instruction,
      min_Attempt_Question: Number(filterValue?.min_Attempt_Question),
      week_Start_Date: newWeekStart?.toISOString(),
      week_End_Date: newWeekEnd?.toISOString(),
      competency_Id: Number(filterValue?.competency_Id),
      class_Id: Number(filterValue?.selectedGrade),
      subject_Id: Number(filterValue?.selectedSubject),
      login_User_Id: Number(user.user_Id),
      spotQuestions: questionDetails,
      // other payload properties...
    };

    const isValidated = handleValidateGroupQuestionSpot(
      payload?.spotQuestions,
      payload
    );

    // console.log("isValidated: ", isValidated);

    if (isValidated) {

        const response = await postStateAdmin(
          `Question/CreateSpotAssessmentQuestionsSave`,
          payload
        );

        if (response.isSuccess) {
          // console.log(
          //   "Response for save question to question bank: ",
          //   response.data
          // );
          setIsConfirmed(false);
          setQuestionDetails([
            {
              question_Text: "",
              base64QuestionImage: "",
              question_Media_Url: "",
              question_Marks: 0,
              typeOfURL: "",

              enlarge: false,
              enlargedText: "",

              question_Type_Id: filterValue?.questionType,
              media_Type_Id: filterValue?.media_Type_Id,
              option_Media_Type_Id: filterValue?.option_Media_Type_Id,

              spotQuestionOptions: [
                {
                  base64OptionImage: "",
                  option_Text: "",
                  is_Correct: false,
                },
              ],
            },
          ]);
          setAddExecuted(!addExecuted);
          showToast({
            type: "success",
            message: "Question saved successfully",
          });
          setScrollTriger(!scrollTriger);
        } else {
          console.log(
            "Error for save question to question bank: ",
            response.error
          );
          showToast({
            type: "error",
            message: response.message || "Could not save question!",
          });
          setScrollTriger(!scrollTriger);
        }
      
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (isFirstLoad) {
      setIsFirstLoad(false);
    } else {
      getSubject();
      getClasses();
      GetQuestionType();
      getAssessmentTypes();
      handleFetchMediaTypeForQuestion(MEDIA_TYPE_QUESTION);
      handleFetchMediaTypeForQuestion(MEDIA_TYPE_OPTION);
    }
  }, [isFirstLoad]);

  // useEffect(() => {
  //   if (
  //     filterValue.selectedSubject &&
  //     filterValue?.selectedGrade &&
  //     filterValue?.WeekStartDate &&
  //     filterValue?.WeekEndDate
  //   ) {
  //     GetSpotQuestionGroup(
  //       filterValue.selectedSubject,
  //       filterValue?.selectedGrade,
  //       filterValue?.WeekStartDate,
  //       filterValue?.WeekEndDate
  //     );
  //   }
  // }, [
  //   filterValue.selectedSubject,
  //   filterValue?.selectedGrade,
  //   filterValue?.WeekStartDate,
  //   filterValue?.WeekEndDate,
  // ]);

useEffect(() => {
  if (filterValue.selectedSubject && filterValue?.selectedGrade) {
    // getCompetency(
    //     filterValue.selectedSubject,
    //     filterValue.selectedSubject
    // );
    getSpotCompetencyBasedOnClassAndSubject(
      Number(filterValue.selectedGrade),
      Number(filterValue.selectedSubject)
    );
    handleChangeQuestionFilterParams("selectedCompetencyLevel", "");
  }
}, [filterValue.selectedSubject, filterValue?.selectedGrade]);



  // useEffect(() => {
  //   // let wholeArray = [...questionDetails];
  //   // console.log("wholeArray", questionDetails);
  //   // let lastObject = {
  //   //   ...questionDetails[questionDetails.length - 1],
  //   //   question_Type_Id: filterValue?.questionType,
  //   //   media_Type_Id: filterValue?.media_Type_Id,
  //   //   option_Media_Type_Id: filterValue?.option_Media_Type_Id,
  //   // };

  //   // wholeArray.splice(
  //   //   questionDetails[questionDetails.length - 1],
  //   //   1,
  //   //   lastObject
  //   // );

  //   // setQuestionDetails(wholeArray);
  //   // Create a copy of questionDetails
  //   const updatedQuestionDetails = [...questionDetails];
  //   console.log("updatedQuestionDetails", updatedQuestionDetails);
  //   // Get the index of the last question
  //   const lastIndex = updatedQuestionDetails.length - 1;

  //   // Update the last question with values from filterValue
  //   if (lastIndex >= 0) {
  //     updatedQuestionDetails[lastIndex] = {
  //       ...updatedQuestionDetails[lastIndex],
  //       question_Type_Id: filterValue?.questionType,
  //       media_Type_Id: filterValue?.media_Type_Id,
  //       option_Media_Type_Id: filterValue?.option_Media_Type_Id,
  //     };
  //   }

  //   // Update the questionDetails state with the modified array
  //   setQuestionDetails(updatedQuestionDetails);
  // }, [
  //   filterValue?.questionType,
  //   filterValue?.media_Type_Id,
  //   filterValue?.option_Media_Type_Id,
  // ]);

    useEffect(() => {
      const updatedQuestionDetails = [...questionDetails];
      // console.log("updatedQuestionDetails", updatedQuestionDetails);
      // Get the index of the last question
      const lastIndex = updatedQuestionDetails.length - 1;

      // Update the last question with values from filterValue
      if (lastIndex >= 0) {
        updatedQuestionDetails[lastIndex] = {
          ...updatedQuestionDetails[lastIndex],
          question_Type_Id: filterValue?.questionType,
          media_Type_Id: filterValue?.media_Type_Id,
          option_Media_Type_Id: filterValue?.option_Media_Type_Id,
        };
      }

      // Update the questionDetails state with the modified array
      setQuestionDetails(updatedQuestionDetails);
    }, [filterValue?.questionType]);

    useEffect(() => {
      const updatedQuestionDetails = [...questionDetails];
      // console.log("updatedQuestionDetails", updatedQuestionDetails);
      // Get the index of the last question
      const lastIndex = updatedQuestionDetails.length - 1;

      // Update the last question with values from filterValue
      if (lastIndex >= 0) {
        updatedQuestionDetails[lastIndex] = {
          ...updatedQuestionDetails[lastIndex],
          question_Type_Id: filterValue?.questionType,
          media_Type_Id: filterValue?.media_Type_Id,
          option_Media_Type_Id: filterValue?.option_Media_Type_Id,
          typeOfURL: undefined,
          base64QuestionImage: "",
          question_Media_Url: "",
        };
      }

      // Update the questionDetails state with the modified array
      setQuestionDetails(updatedQuestionDetails);
    }, [filterValue?.media_Type_Id]);

    useEffect(() => {
      const updatedQuestionDetails = [...questionDetails];
      // console.log("updatedQuestionDetails", updatedQuestionDetails);
      // Get the index of the last question
      const lastIndex = updatedQuestionDetails.length - 1;

      // Update the last question with values from filterValue
      if (lastIndex >= 0) {
        updatedQuestionDetails[lastIndex] = {
          ...updatedQuestionDetails[lastIndex],
          question_Type_Id: filterValue?.questionType,
          media_Type_Id: filterValue?.media_Type_Id,
          option_Media_Type_Id: filterValue?.option_Media_Type_Id,
          spotQuestionOptions: updatedQuestionDetails[
            lastIndex
          ]?.spotQuestionOptions?.map((item: any) => {
            return {
              ...item,
              base64OptionImage: "",
              option_Text: "",
            };
          }),
        };
      }

      // Update the questionDetails state with the modified array
      setQuestionDetails(updatedQuestionDetails);
    }, [filterValue?.option_Media_Type_Id]);

  useEffect(() => {
    setFilterValue((prev: any) => {
      return {
        ...prev,
        questionType: 0,
        media_Type_Id: 0,
        option_Media_Type_Id: 0,
      };
    });
  }, [addExecuted]);

  // useEffect(() => {
  //   if (location?.state) {
  //     const data = location?.state;

  //     let NewFilter = {
  //       selectedGrade: data?.filters?.selectedGrade,
  //       selectedSubject: data?.filters?.selectedSubject,
  //       WeekStartDate: data?.filters?.WeekStartDate,
  //       WeekEndDate: data?.filters?.WeekEndDate,
  //       question_Group_Instruction_Id: Number(data?.filters?.group),
  //       questionType: data?.questionDetail?.question_Type_Id,
  //       media_Type_Id: data?.questionDetail?.media_Type_Id,
  //       option_Media_Type_Id:
  //         data?.questionDetail?.questionsOptions[0]?.option_Media_Type_Id,
  //     };

  //     setFilterValue(NewFilter);

  //     //  {
  //     //       question_Text: data.question_Text,
  //     //       base64QuestionImage: data.base64QuestionImage,
  //     //       question_Media_Url: data.question_Media_Url,
  //     //       question_Marks: data.question_Marks ? Number(data.question_Marks) : 0,
  //     //       typeOfURL: "",

  //     //       question_Type_Id: data.question_Type_Id,
  //     //       media_Type_Id: data.question_Type_Id ? data.question_Type_Id : data.question_Media_Type,
  //     //       option_Media_Type_Id: data?.option_Media_Type_Id ? data?.option_Media_Type_Id : data?.option_Media_Type_Id,

  //     //       spotQuestionOptions: [
  //     //         {
  //     //           base64OptionImage: "",
  //     //           option_Text: "",
  //     //           is_Correct: false,
  //     //         },
  //     //       ],
  //     //     },

  //     let newOption = data?.questionDetail?.questionsOptions.map(
  //       (item: any) => {
  //         let obj = {
  //           base64OptionImage: item?.base64OptionImage?.replace(
  //             "data:image/jpeg;base64,",
  //             ""
  //           ),
  //           option_Text: item?.option_Text,
  //           is_Correct: item?.is_Correct,
  //         };
  //         return obj;
  //       }
  //     );

  //     setQuestionDetails([
  //       {
  //         ...data?.questionDetail,
  //         question_Text: data?.questionDetail?.question_Text,
  //         base64QuestionImage:
  //           data?.questionDetail?.base64QuestionImage?.replace(
  //             "data:image/jpeg;base64,",
  //             ""
  //           ),
  //         question_Media_Url: data?.questionDetail?.question_Media_Url,
  //         question_Marks: data?.questionDetail?.question_Marks
  //           ? Number(data?.questionDetail?.question_Marks)
  //           : 0,
  //         typeOfURL:
  //           data?.questionDetail?.media_Type_Id == 5
  //             ? "Image"
  //             : data?.questionDetail?.media_Type_Id == 4
  //             ? "Video"
  //             : data?.questionDetail?.media_Type_Id == 6
  //             ? "Audio"
  //             : "",

  //         question_Type_Id: data?.questionDetail?.question_Type_Id,
  //         media_Type_Id: data?.questionDetail?.media_Type_Id,
  //         option_Media_Type_Id:
  //           data?.questionDetail?.questionsOptions[0]?.option_Media_Type_Id,

  //         spotQuestionOptions: newOption,
  //       },
  //     ]);

  //     setScrollTriger(!scrollTriger);
      
  //     console.log("test", data);
  //   }
  // }, [location]);

  return {
    classes,
    subjectList,
    allGroups,
    handleChangeQuestionFilterParams,
    filterValue,
    questionType,
    mediaTypeForQuestion,
    mediaTypeForOptions,
    questionDetails,
    handleChangeQuestionDetails,
    handleChangeOptionDetails,

    addQuestion,
    addOption,
    deleteQuestion,
    deleteOption,
    handleSetCorrectOption,
    handleSetCorrectOptionMultiple,
    setIsConfirmed,
    isConfirmed,
    isLoading,
    handleSaveQuestion,
    competencyByClassSubject,
    spotCompetencyByClassSubject,
  };
}

export default useAddQuestionGroup;
