import React, { useContext, useEffect, useState } from 'react'
import { ISlotTestQuestionList, ISlotTestQuestionListQuestion, ITrainingSlotList } from '../../../../libs/data-access/types';
import useMasterData from '../../../Hooks/useMasterData';
import { useTeacherTraining } from '../../../../libs/data-access';
import { showToast } from '../../../../utils/utility';
import { handleValidEditTestQuestionList } from '../../../../utils/validators';
import { AppContext } from '../../../../AppContext';

export default function useSlotQuestionList() {   

    const DefaultSLotQuestionOption =  {
        question_Id: 0,
        base64QuestionImage: "",
        question_Media_Url: "",
        question_Type: "",
        question_Text: "",
        question_Media_Type: "",
        questionsOptions: []
    }
    const {
        questionType,
        GetQuestionType,
    } = useMasterData();
    
    const {
        user
    } = useContext(AppContext);
 
    const {
        fetchTeacherTrainingData,
        DeleteTeacherTrainingData,
        PutTeacherTrainingSlotTest,
        PostTeacherTrainingSlotTest
    } = useTeacherTraining()
    
    const [isFirst, setIsFirst] = useState<boolean>(true);
    const [isLoadingSlotQuestionList, setIsLoadingSlotQuestionList] = useState<boolean>(false);
    const [isLoadingSlotList, setIsLoadingSlotList] = useState<boolean>(false);
    const [isLoadingBtn, setIsLoadingBtn] = useState<boolean>(false);
    const [isOpenModal, setIsOpenModal] = useState<boolean>(false); 
    const [isOpenDeleteModal, setIsOpenDeleteModal] = useState<boolean>(false); 
    const [slotList, setSlotList] = useState<ITrainingSlotList[]>([]);
    const [selectedSlotHeader, setSelectedSlotHeader] = useState<number>(0);
    const [selectedSlotTestId, setSelectedSlotTestId] = useState<number>(0);
    const [slotTestQuestionList ,setSlotTestQuestionList] =useState<ISlotTestQuestionList[]>([])
    const [selectedSlotQuestion ,setSelectedSlotQuestion] =useState<ISlotTestQuestionListQuestion>(DefaultSLotQuestionOption)

    useEffect(() => {
        if (isFirst) {
            setIsFirst(false);
        } else {
            GetQuestionType();
            getSlotList()
        }
    }, [isFirst]);

    const handleSearchQuestionList = () =>{
          getSlotTestQuestionList()
    }

    const handleUpdateQuestionOption =()=>{
       const  valid = handleValidEditTestQuestionList(selectedSlotQuestion)
        if(valid){ 
            const questionPayload ={
                question_Id: selectedSlotQuestion?.question_Id,
                question_Text: selectedSlotQuestion?.question_Text,
                question_Type_Id: selectedSlotQuestion?.question_Type,
                media_Type_Id: 1,
                state_Admin_User_Id: user?.user_Id,
                question_Marks: 1,
                is_Active: true
              }
            putSlotQuestionDetails(questionPayload)
    //    console.log("selected test question ",selectedSlotQuestion)
    }}

    const getSlotList = async () => {
        setIsLoadingSlotList(true)
        setSlotList([])
        const res = await fetchTeacherTrainingData(`/BlockAdmin/GetTrainingScheduleHeaderDetail?Is_Current_Header=false`)
        if (res.isSuccess) {
            setSlotList(res?.data)
        }
        else {
            showToast({
                type: "error",
                message: res?.message ?? "error while getting slot list"
            })
        }
        setIsLoadingSlotList(false)
    }

    const getSlotTestQuestionList = async()=>{
        setIsLoadingSlotQuestionList(true)
        setSlotTestQuestionList([])
        const res = await fetchTeacherTrainingData(`/StateAdmin/GetTeacherTrainingTestQuestionsByStateAdmin/${selectedSlotTestId}`)
         if(res?.isSuccess){
            setSlotTestQuestionList(res?.data)
         }
         else{
            showToast({
                type:"error",
                message: res?.message ?? "Error while getting slot question list"
            })
         }
        setIsLoadingSlotQuestionList(false)
    }
     
    const putSlotQuestionDetails =async (payload :any)=>{
        const res = await PutTeacherTrainingSlotTest(`UpdateTeacherTrainingQuestion`,payload)
        if(res.isSuccess){
            getSlotTestQuestionList()
            // putSlotQuestionOptionDetails()
        }
        else{
            showToast({
                type:"error",
                message: res?.message ?? "Error while updating test question details"
            })
        }
    }

    // const putSlotQuestionOptionDetails =async () =>{
    //     let payload  ={
    //         "question_Option_Id": selec
    //         "option_Text": "string",
    //         "state_Admin_User_Id": 0,
    //         "is_Correct": true,
    //         "is_Active": true
    //     }
    //    const res = await PutTeacherTrainingSlotTest(`UpdateTeacherTrainingQuestionOption`,payload) 
    // }

    const deleteTestQuestionById =async(question_Id: number)=>{
          setIsLoadingBtn(true)
          const res = await DeleteTeacherTrainingData(`/StateAdmin/DeleteTeacherTrainingQuestion/${question_Id}/${user?.user_Id}`)
          if(res.isSuccess){
            showToast({
                type:"success",
                message:"Question deleted successfully"
            })
            setIsOpenDeleteModal(false)
            getSlotTestQuestionList()
          }
          else{
            showToast({
                type:"error",
                title:"Try again to delete",
                message: res?.message || "fail to delete question"
            })
          }
          setIsLoadingBtn(false)
    }
  return {
    isLoadingBtn,
    isOpenModal,
    setIsOpenModal,
    isOpenDeleteModal,
    setIsOpenDeleteModal,
    slotList,
    selectedSlotHeader,
    setSelectedSlotHeader,
    questionType,
    selectedSlotTestId,
    setSelectedSlotTestId,
    handleSearchQuestionList,
    isLoadingSlotQuestionList,
    slotTestQuestionList,
    setSlotTestQuestionList,
    selectedSlotQuestion,
    setSelectedSlotQuestion,
    DefaultSLotQuestionOption,
    handleUpdateQuestionOption,
    deleteTestQuestionById
  }
}