import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  calculateCurrentMonthForDashboard,
  calculateDefaultSession,
  showToast,
} from "../../../utils/utility";
import {
  handleValidScoreCard,
  handleValidUserManagement,
  handleValidUserManagementUpdate,
} from "../../../utils/validators";
import useMasterData from "../../Hooks/useMasterData";
import { BASE_URL } from "../../../libs/data-access/constant";
import { useDashboardDate } from "../../../libs/data-access";
import { AppContext } from "../../../AppContext";
import moment from "moment";
import { IUserManagement } from "../../../types";
import { ROLES } from "../../../Routes/routes";
import { useUserManagementFetch } from "../../../libs/data-access/src/UserManagement/useUserManageFetch";
import debounce from "lodash.debounce";

const useUserManagement = () => {
  const {
    saveDashboardFormData,
    fetchDashboardDataStaging,
    deleteDashboardDataStaging,
  } = useDashboardDate();

  const { role, user } = useContext(AppContext);

  const {
    getDistrictByStateId,
    districts,
    getBlocksByDistrictId,
    blocks,
    clusterSchools,
    getClusterByBlockId,
  } = useMasterData();

  const {
    fetchUserManagementData,
    postUserManagementData,
    putUserManagementData,
    deleteUserManagementData,
  } = useUserManagementFetch();

  const [isFirst, setIsFirst] = useState<boolean>(true);

  const [selectedDistrict, setSelectedDistrict] = useState<string | undefined>(
    undefined
  );
  const [selectedBlock, setSelectedBlock] = useState<string | undefined>(
    undefined
  );
  const [selectedCluster, setSelectedCluster] = useState<string | undefined>(
    undefined
  );

  const [isEdit, setIsedit] = useState(false);

  const [addUserValue, setAddUserValue] = useState<IUserManagement>({
    role: "",
    designation: "",
    name: "",
    mobile: undefined,
    Username: "",
    dateOfBirth: null,
    password: "",
    emailId: "",
    address: "",
  });


  const [userManagementSearchValue, setUserManagementSearchValue] =
    useState<IUserManagement>({
      role: "",
      designation: "",
    });

  const [allUserManagement, setAllUserManagementData] = useState<any>([]);

  const [open, setOpen] = useState(false);

  const [deleteId, setDeleteId] = useState(null);
  const [openConfirm, setConfirm] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const [totalRecords, setTotalRecords] = useState(11);

  const [searchQuery, setSearchQuery] = useState("");
  const [currentItems, setCurrentItems] = useState([]); // Specify the type here
  const [initial, setInitial] = useState(true);

  const [loading, setLoading] = useState(false);
  const [userManageRoles, setuserManageRoles] = useState([]);
  const [userManageDesignation, setuserManageDesignation] = useState([]);

  const [userManageAddDesignation, setuserManageAddDesignation] = useState<any>(
    []
  );

  const [accessLevelDesignation, setAccessLevelDesignation] = useState<any>(null);


  const [prefix, setPrefix] = useState<any>("_");
  const [errorMsg, setErrorMsg] = useState<any>("");
  const [enableRemark, setEnableRemark] = useState<any>(false);
  const [remarkValue, setRemarkValue] = useState<any>("");

  const handleChangeUserManagement = ({
    name,
    value,
  }: {
    name: string;
    value: any;
  }) => {
    setAddUserValue((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const debouncedHandleChangeUserManagement = 
    debounce(
      (name: string, value: string) =>{
        checkUserManagementApi(value)},
        // console.log("i am created", value),
      300
    )

    const debouncedHandleChangeSearchQuery = 
      debounce(
        (value: string) => {
          getUserManagementApi(true,value);
        },
        300
      )

    


  const handleChangeUserManagementSearch = ({
    name,
    value,
  }: {
    name: string;
    value: any;
  }) => {
    setUserManagementSearchValue((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const saveUserManagement = async () => {
    const valid = handleValidUserManagement(
      addUserValue,
      selectedDistrict,
      selectedBlock,
      selectedCluster,
      errorMsg,
      accessLevelDesignation
    );
    if (valid) {
      let clusterUdiseCode = "";

      if (selectedCluster) {
        const filteredCluster = clusterSchools.filter(
          (item) => String(item?.cluster_School_Id) === String(selectedCluster)
        );
        clusterUdiseCode =
          filteredCluster.length > 0 ? filteredCluster[0]?.udise_Code : "";
      }

      let payload = {
        mentor_ID: 0,
        districtID: selectedDistrict ? +selectedDistrict : 0,
        blockId: selectedBlock ? +selectedBlock : 0,
        cluster_School_Id: selectedCluster ? +selectedCluster : 0,
        unique_Code: prefix + addUserValue?.Username,
        password: addUserValue?.password,
        mentor_Name: addUserValue?.name,
        mobileNo: String(addUserValue?.mobile),
        address: addUserValue?.address,
        roleId: +addUserValue?.role,
        designation_Id: +addUserValue?.designation,
        dob: moment(addUserValue?.dateOfBirth).format("DD/MM/YYYY"),
        emailID: addUserValue?.emailId,
        cluster_Udise_Code: clusterUdiseCode ? String(clusterUdiseCode) : "",
        remarks: "",
        ipAddress: "",
      };
      const res = await postUserManagementData(`CreateMentor`, payload);
      if (res.isSuccess) {
        showToast({
          type: "success",
          message: res.message,
        });
        setAddUserValue({
          role: "",
          designation: "",
          name: "",
          mobile: undefined,
          Username: "",
          dateOfBirth: null,
          password: "",
          emailId: "",
          address: "",
        });

        setSelectedDistrict(undefined);
        setSelectedBlock(undefined);
        setSelectedCluster(undefined);
        setIsedit(false);
        setPrefix("_")
        setSearchQuery("");

        getUserManagementApi()
      } else {
        showToast({
          type: "error",
          message: res.message,
        });
        setAddUserValue({
          role: "",
          designation: "",
          name: "",
          mobile: undefined,
          Username: "",
          dateOfBirth: null,
          password: "",
          emailId: "",
          address: "",
        });

        setSelectedDistrict(undefined);
        setSelectedBlock(undefined);
        setSelectedCluster(undefined);
        setIsedit(false);
        setSearchQuery("");
      }

      setOpen(false);
    }
  };

  const updateUserManagementApi = async () => {
    const valid = handleValidUserManagementUpdate(addUserValue, errorMsg);
    if (valid) {
      let payload = {
        mentor_ID: addUserValue?.mentorId ? +addUserValue?.mentorId : 0,
        mentorName: addUserValue?.name,
        mobileNo: String(addUserValue?.mobile),
        dob: moment(addUserValue?.dateOfBirth).format("DD/MM/YYYY"),
        password: addUserValue?.password,
        emailID: addUserValue?.emailId,
        remarks: "",
        ipAddress: "",
        address:addUserValue?.address
      };

      const res = await putUserManagementData(`UpdateMentor`, payload);
      if (res.isSuccess) {
        showToast({
          type: "success",
          message: res.message,
        });
        setAddUserValue({
          role: "",
          designation: "",
          name: "",
          mobile: undefined,
          Username: "",
          dateOfBirth: null,
          password: "",
          emailId: "",
          address: "",
        });

        setSelectedDistrict(undefined);
        setSelectedBlock(undefined);
        setSelectedCluster(undefined);
        setIsedit(false);
setSearchQuery("");

        getUserManagementApi()
      } else {
        showToast({
          type: "error",
          message: res.message,
        });
        setAddUserValue({
          role: "",
          designation: "",
          name: "",
          mobile: undefined,
          Username: "",
          dateOfBirth: null,
          password: "",
          emailId: "",
          address: "",
        });

        setSelectedDistrict(undefined);
        setSelectedBlock(undefined);
        setSelectedCluster(undefined);
        setIsedit(false);
        setSearchQuery("");
      }

      setOpen(false);
    }
  };

  const getUserManagementApi = async (current?: any,search?:any) => {
    // console.log("userManagementSearchValue", userManagementSearchValue.role);
    if(!search){
setLoading(true);
    }
    
    const res = await fetchUserManagementData(
      `GetMentorMasterByRoleAndDesignation?RoleId=${
        userManagementSearchValue?.role?.length > 0
          ? userManagementSearchValue?.role
          : 0
      }&DesingnationId=${
        userManagementSearchValue?.designation?.length > 0
          ? userManagementSearchValue?.designation
          : 0
      }&OFFSET=${
        current ? 0 : currentPage * itemsPerPage
      }&PAGESIZE=${itemsPerPage}&search=${search ? search : ""}`
    );
    if (res.isSuccess) {
      setAllUserManagementData(res?.data);
      setTotalRecords(res?.data[0]?.total);
    } else {
      setAllUserManagementData([]);
      showToast({
        type: "error",
        message: res.message || "Something went wrong",
      });
    }
if(!search){
    setLoading(false);
}
  };



  const checkUserManagementApi = async (username:any) => {
    const res = await fetchUserManagementData(`GetMentor?UserName=${username}`);
    if (res.isSuccess) {
      if (res?.message == "Mentor Found successfully."){
        setErrorMsg("User Name Already Exist")
      }
      else{
        setErrorMsg("")
      }
        
    } else {
      showToast({
        type: "error",
        message: res.message || "Something went wrong",
      });
    }
  };


  const deleteUserManagement = async (id: any) => {
    setLoading(true);

    const res = await deleteUserManagementData(
      `DeleteMentor?Mentor_Id=${id}&Remarks=${
        remarkValue?.length > 0 ? remarkValue : 0
      }&IPAddress=${""}`
    );
    if (res.isSuccess) {
      showToast({
        type: "success",
        message: res.message || "Deactivated successfully",
      });
      getUserManagementApi();
      setSearchQuery("");
    } else {
      showToast({
        type: "error",
        message: res.message || "Something went wrong",
      });
    }

    setLoading(false);
  };

  const manageGetRoles = async () => {
    setuserManageRoles([]);
    let response = await fetchUserManagementData(`GetRoleMaster`);
    if (response?.isSuccess) {
      const data = response.data.map((e: any) => ({
        id: e?.role_Id,
        name: e?.role_Name,
      }));
      data && setuserManageRoles(data);
    } else {
      console.log("Error while getting roles list");
    }
  };

  const manageGetDesignationByRoles = async (roleId: any) => {
    setuserManageDesignation([]);
    let response = await fetchUserManagementData(
      `GetDesignationByRole?RoleId=${roleId}`
    );
    if (response?.isSuccess) {
      const data = response.data.map((e: any) => ({
        id: e?.designation_Id,
        name: e?.designation,
      }));
      data && setuserManageDesignation(data);
    } else {
      console.log("Error while getting roles list");
    }
  };

  const manageGetAddDesignationByRoles = async (roleId: any) => {
    setuserManageAddDesignation([]);
    let response = await fetchUserManagementData(
      `GetDesignationByRole?RoleId=${roleId}`
    );
    if (response?.isSuccess) {
      setuserManageAddDesignation(response?.data);
    } else {
      console.log("Error while getting roles list");
    }
  };

  const deleteConfirmFunc = (id: any) => {
    setDeleteId(id);
    setConfirm(true);
  };

  const confirmAcceptFunc = () => {
    if (remarkValue?.length > 0) {
      deleteUserManagement(deleteId);
      setDeleteId(null);
      setConfirm(false);
      setEnableRemark(false);
      setRemarkValue("");
    } else {
      showToast({
        type: "warning",
        message: "Please provide Remark !",
      });
    }
  };

  const confirmRejectFunc = () => {
    setConfirm(false);
    setDeleteId(null);
    setEnableRemark(false);
    setRemarkValue("")
  };

  const nextPage = () => setCurrentPage(currentPage + 1);
  const prevPage = () => setCurrentPage(currentPage - 1);

  // useEffect(() => {
  //   if (searchQuery?.length > 0) {
  //     const filteredElements = allUserManagement?.filter(
  //       (item: any) =>
  //         item.mentor_Name
  //           ?.toLowerCase()
  //           ?.includes(searchQuery?.toLowerCase()) ||
  //         item.userId
  //           ?.toString()
  //           ?.toLowerCase()
  //           ?.includes(searchQuery?.toLowerCase())
  //     );
  //     setCurrentItems(filteredElements);
  //   } else {
  //     setCurrentItems(allUserManagement);
  //   }
  // }, [searchQuery, allUserManagement]);

  useEffect(() => {
    if (isFirst) {
      setIsFirst(false);
    } else {
      getDistrictByStateId(1);
      if (role?.includes(ROLES.BLOCK)) {
        setSelectedDistrict(
          String(user?.details?.blockUserDetails?.district_Id)
        );
        setSelectedBlock(String(user?.details?.blockUserDetails?.block_Id));
      } else if (role?.includes(ROLES.DISTRICT)) {
        setSelectedDistrict(
          String(user?.details?.districtUserDetails?.district_Id)
        );
      } else {
      }
    }
  }, [isFirst]);

  useEffect(() => {
    if (selectedDistrict) {
      if (role?.includes(ROLES.DISTRICT)) {
      }
      getBlocksByDistrictId(+selectedDistrict);
    } else {
    }
  }, [selectedDistrict]);

  useEffect(() => {
    if (selectedBlock) {
      if (role?.includes(ROLES.BLOCK)) {
      }
      getClusterByBlockId(+selectedBlock);
    }
  }, [selectedBlock]);

  useEffect(() => {
    manageGetRoles();
  }, []);

  useEffect(() => {
    if (userManagementSearchValue?.role?.length > 0) {
      manageGetDesignationByRoles(userManagementSearchValue?.role);
    }
  }, [userManagementSearchValue?.role]);

  useEffect(() => {
    if (addUserValue?.role?.length > 0) {
      manageGetAddDesignationByRoles(addUserValue?.role);
    }
  }, [addUserValue?.role]);

  useEffect(() => {
    if (allUserManagement?.length > 0) {
      getUserManagementApi();
    }
  }, [currentPage]);

  useEffect(() => {
    if (allUserManagement?.length > 0) {
      getUserManagementApi(true);
      setCurrentPage(0);
    }
  }, [itemsPerPage]);

  const onCloseClickModelFunc = () => {
    setOpen(false);
    setIsedit(false);
    setAddUserValue({
      role: "",
      designation: "",
      name: "",
      mobile: undefined,
      Username: "",
      dateOfBirth: null,
      password: "",
      emailId: "",
      address: "",
    });

    setSelectedDistrict(undefined);
    setSelectedBlock(undefined);
    setSelectedCluster(undefined);
    setPrefix("_");
    setuserManageAddDesignation([]);
    setErrorMsg("")
  };

function parseDMY(dateString: string): Date | null | any {
  if (/^\d{4}-\d{2}-\d{2}$/.test(dateString)) {
       const parts = dateString.split("-");
       const day = parseInt(parts[2], 10);
       const month = parseInt(parts[1], 10) - 1; // Zero-index the month
       const year = parseInt(parts[0], 10);
       return new Date(year, month, day);
  }

  if (/^\d{2}-\d{2}-\d{4}$/.test(dateString)) {
    const parts = dateString.split("-");
    const day = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10) - 1; // Zero-index the month
    const year = parseInt(parts[2], 10);
    return new Date(year, month, day);
  }

  // Check if the format is DD/MM/YYYY
  if (/^\d{2}\/\d{2}\/\d{4}$/.test(dateString)) {
    const parts = dateString.split("/");
    const day = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10) - 1; // Zero-index the month
    const year = parseInt(parts[2], 10);
    return new Date(year, month, day);
  }
  return null; // Return null if the format is incorrect
}


  const editUSer = (item: any) => {

    let data: any = {
      role: String(item?.role_Id),
      designation: String(item?.designation_Id),
      name: item?.mentor_Name,
      mobile: +item?.mobile_No,
      Username: String(item?.userId.split("_")[1]),
      dateOfBirth: parseDMY(item?.date_Of_Birth),
      password: item?.password,
      emailId: item?.email_Id,
      address: item?.address,
      mentorId: item?.mentor_Id,
    };

    let prefixUsername = item?.userId.split("_")[0];
    setPrefix(`${prefixUsername}_`);

    setAddUserValue(data);
    setSelectedDistrict(item?.district_Id);
    setSelectedBlock(item?.block_Id);
    setSelectedCluster(item?.cluster_School_Code);
    setIsedit(true);
  };
  

  useEffect(() => {
    if (isEdit == true) {
      setOpen(true);
    }
  }, [isEdit]);

  return {
    addUserValue,
    handleChangeUserManagement,
    saveUserManagement,
    handleChangeUserManagementSearch,
    userManagementSearchValue,
    open,
    setOpen,
    allUserManagement,
    getUserManagementApi,
    deleteUserManagement,
    deleteId,
    openConfirm,
    deleteConfirmFunc,
    confirmAcceptFunc,
    confirmRejectFunc,

    currentPage,
    setCurrentPage,
    itemsPerPage,
    setSearchQuery,
    nextPage,
    prevPage,
    searchQuery,
    currentItems,
    totalRecords,
    loading,

    districts,
    blocks,
    clusterSchools,
    selectedDistrict,
    setSelectedDistrict,
    selectedBlock,
    setSelectedBlock,
    selectedCluster,
    setSelectedCluster,
    userManageRoles,
    userManageDesignation,
    setItemsPerPage,
    userManageAddDesignation,
    setPrefix,
    prefix,
    debouncedHandleChangeUserManagement,
    errorMsg,
    editUSer,
    onCloseClickModelFunc,
    updateUserManagementApi,
    isEdit,
    enableRemark,
    setEnableRemark,
    remarkValue,
    setRemarkValue,
    setAccessLevelDesignation,
    debouncedHandleChangeSearchQuery,
    parseDMY,
  };
};

export default useUserManagement;
