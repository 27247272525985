import { Skeleton, Text } from '@mantine/core';
import React from 'react'
import { EPlaceholder } from '../../../../../libs/ui/src/Placeholder/EPlaceholder';
import { CSVLink } from 'react-csv';
import { EButton, EInfoPanel } from '../../../../../libs/ui';
import { BiDownload } from 'react-icons/bi';
import moment from 'moment';
import useSpotAssessmentCompetencyWiseData from '../Hooks/useSpotAssessmentCompetencyWiseData';

const SpotCompetencyWise = () => {
   const {
     isLoading,
     spotAssessmentCompetencyWiseData,
     grade,
     orfResultResultCSV,
   } = useSpotAssessmentCompetencyWiseData();
  return (
    <div className="flex flex-col gap-7">
      {/* Map  */}
      {isLoading ? (
        <Skeleton radius={"md"} height={450} />
      ) : spotAssessmentCompetencyWiseData?.length > 0 ? (
        //          <ECard
        //     title="Filter-wise % students"
        //     customClass="overflow-auto"
        //     header={
        //       <CSVLink
        //         style={{}}
        //         className="text-blue-600 text-sm hover:underline"
        //         // data={[]}
        //         data={downloadCSVData ?? []}
        //         // headers={[]}
        //         // headers={spotAssessmentResultCSV?.headers ?? []}
        //         filename={`Spot_Assessment_Result_${moment().format(
        //           "MMM"
        //         )}-${moment().format("YYYY")}.csv`}
        //       >
        //         <EButton className="btn-blue">
        //           Download Report <BiDownload className="ml-2" size={22} />
        //         </EButton>
        //       </CSVLink>
        //     }
        //   >
        //     {/* <ESectionDivider title="Filter-wise % students" /> */}
        //     <ETable>

        //       <thead>
        //         <tr>
        //           <th style={{ minWidth: "160px" }} rowSpan={2}>
        //             District
        //           </th>
        //           <th style={{ minWidth: "160px" }} rowSpan={2}>
        //             Overall Avg (%)
        //           </th>
        //           {spotAssessmentCompetencyWiseData[0]?.subjects?.map(
        //             (subject: any, sIndex: any) => (
        //               <th
        //                 style={{ minWidth: "260px" }}
        //                 // colSpan={subject?.data?.length * 3}
        //                 colSpan={subject?.data?.length}
        //               >
        //                 {subject.subject}
        //               </th>
        //             )
        //           )}
        //         </tr>
        //         <tr>
        //           {spotAssessmentCompetencyWiseData[0]?.subjects?.map(
        //             (subject: any, sIndex: any) =>
        //               subject?.data?.map((child: any, dIndex: any) => (
        //                 // <th style={{ minWidth: "160px" }} colSpan={3}>
        //                 <th style={{ minWidth: "160px" }}>
        //                   {child?.type == "compentancy"
        //                     ? child?.Text
        //                     : `${child?.Text}(ORF)`}
        //                 </th>
        //               ))
        //           )}
        //         </tr>
        //       </thead>
        //       <tbody>
        //         {spotAssessmentCompetencyWiseData?.map(
        //           (district: any, districtIndex: any) => (
        //             <tr>
        //               <td style={{ minWidth: "160px" }}>{district?.name}</td>
        //               <td style={{ minWidth: "160px" }}>
        //                 {`${district?.OverAllAvg}%`}
        //               </td>
        //               {district?.subjects?.map((subject: any, sIndex: any) =>
        //                 subject?.data?.map((detail: any, dIndex: any) => (
        //                   <>
        //                     <td style={{ minWidth: "260px" }}>
        //                       {/* <Text>{detail.Month_Name}</Text> */}
        //                       {detail?.AvgPercentage != null
        //                         ? detail?.AvgPercentage
        //                         : "--"}
        //                     </td>
        //                   </>
        //                 ))
        //               )}
        //             </tr>
        //           )
        //         )}
        //         <tr>
        //           <td style={{ minWidth: "160px" }}>{totalOfData?.name}</td>
        //           <td style={{ minWidth: "160px" }}>
        //             {`${totalOfData?.OverAllAvg}%`}
        //           </td>

        //         </tr>
        //       </tbody>
        //     </ETable>
        //   </ECard>
        <></>
      ) : (
        <EPlaceholder
          iconSrc={
            require("../../../../../assets/icons/icon-placeholder.svg").default
          }
          title="Create Report"
          description="Please select a month and other filters and press search to generate a report."
        />
      )}

      {/* ORF REPORT */}
      {isLoading ? (
        <Skeleton radius="md" height={150} />
      ) : spotAssessmentCompetencyWiseData.length > 0 ? (
        <EInfoPanel
          title="School Level Competency Wise Report"
          // text="This report is generate for all grades and does not rely on the selected grade"
          text=""
        >
          <CSVLink
            className="text-blue-600 text-sm hover:underline"
            data={orfResultResultCSV?.content ?? []}
            headers={orfResultResultCSV?.headers ?? []}
            filename={`School_level_competency_wise_report_${moment().format(
              "MMM"
            )}-${moment().format("YYYY")}.csv`}
          >
            {spotAssessmentCompetencyWiseData?.length ? (
              <EButton className="btn-blue">
                Download Report <BiDownload className="ml-2" size={22} />
              </EButton>
            ) : (
              <Text>
                Please select a month and press search to generate a report!
              </Text>
            )}
          </CSVLink>
        </EInfoPanel>
      ) : null}
    </div>
  );
}

export default SpotCompetencyWise