import { useContext, useEffect, useState } from "react";

import { AppContext } from "../../../AppContext";
import { showToast } from "../../../utils/utility";
import { ITicket } from "../../../libs/data-access/types";
import { useGrievanceData } from "../../../libs/data-access";
import { ISelectedTicketToBeResolved } from "../../../types";
import moment from "moment";

export default function useGrievance() {
  const { user } = useContext(AppContext);
  const { fetchGrievanceData, putGrievanceDataWithoutPayload } =
    useGrievanceData();

  const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true);
  const [activeTab, setActiveTab] = useState<"open" | "closed">("open");
  const [tickets, setTickets] = useState<ITicket[]>([]);

  // loading
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [isOpenDrawer, setIsOpenDrawer] = useState<boolean>(false);
  const [selectedIndex, setSelectedIndex] = useState<number>(-1);
  const [selectedTicket, setSelectedTicket] = useState<
    ISelectedTicketToBeResolved | undefined
  >(undefined);
  const [users, setUsers] = useState<any>([]);
  const [category, setCategory] = useState<any>([]);

  const [filterData, setFilterData] = useState<any>({
    user: "",
    category: "",
    grivanceStatus: "-1",
    fromDate: null,
    toDate: null,
  });

  // console.log("selectedTicket", selectedTicket);

  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage] = useState(10);

  const [totalRecords, setTotalRecords] = useState(11);

  useEffect(() => {
    if (isFirstLoad) {
      setIsFirstLoad(false);
    } else {
      handleFetchTickets();
      getUsersofticket();
    }
  }, [isFirstLoad, activeTab]);

  // HANDLER FUNCTIONS
  const handleFetchTickets = (current?:any) => {
    setTickets([]);
    fetchTicketsBasedOnTicketStatus(current);
  };

  const getUsersofticket = async () => {
    const response = await fetchGrievanceData(
      `/GetGrievanceGrievanceUserDetails`
    );
    if (response.isSuccess) {
      setUsers(response.data);
    } else {
      console.error("Error while fetching tickets: ", response.error);
      showToast({
        type: "error",
        title: "Something Went Wrong!",
        message: response.message || "Could not fetch tickets.",
      });
    }
  };

  const getCategories = async () => {
    const selectedUser = users.find(
      (user: any) => String(user?.role_Id) === filterData?.user
    );
     if (selectedUser) {
    const response = await fetchGrievanceData(
      `/GetGrievanceCategoriesDetails/${selectedUser?.userId}`
    );
    if (response.isSuccess) {
      setCategory(response.data);
    } else {
      console.error("Error while fetching tickets: ", response.error);
      showToast({
        type: "error",
        title: "Something Went Wrong!",
        message: response.message || "Could not fetch tickets.",
      });
    }
  }
  };

  const handleReplyInput = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value;
    const ticket: ISelectedTicketToBeResolved = JSON.parse(
      JSON.stringify(selectedTicket)
    );
    ticket.reply = value;
    setSelectedTicket(ticket);
  };

  const handleCloseDateInput = (e:any) => {
    setSelectedTicket((prev:any)=>{
      return {
        ...prev,
        closedTicketDate:e
      }
    }) 
  }

  /* Sorting the appointments array based on the date. */
  const handleSortByOrderDate = (
    appointments: ITicket[],
    isAsced?: boolean
  ) => {
    return appointments?.sort((a, b) => {
      const date1 = new Date(a.date_Of_Issue);
      const date2 = new Date(b.date_Of_Issue);
      if (isAsced) {
        return date1 > date2 ? 1 : -1;
      } else {
        return date1 < date2 ? 1 : -1;
      }
    });
  };

  // filter data handle change

  const filterHandleChange = ({
    name,
    value,
  }: {
    name: string;
    value: any;
  }) => {
    setFilterData((prev: any) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  useEffect(() => {
   setFilterData((prev: any) => {
     return {
       ...prev,
       category: "",
     };
   });

   setCategory([])
  }, [filterData?.user]);
  

  // API CALLS
  const fetchTicketsBasedOnTicketStatus = async (current:any) => {
    setIsLoading(true);
    // const response = await fetchGrievanceData(
    //   `/GetGrievanceTicketsRaisedByUsers/${activeTab === "open" ? false : true}`
    // );
    // if (response.isSuccess) {
    //   // console.table(response.data);
    //   const sortedTickets = handleSortByOrderDate(response.data);
    //   setTickets(sortedTickets);
    // } else {
    //   console.error("Error while fetching tickets: ", response.error);
    //   showToast({
    //     type: "error",
    //     title: "Something Went Wrong!",
    //     message: response.message || "Could not fetch tickets.",
    //   });
    // }

    // let newFromDate: any = new Date(filterData?.fromDate);
    // newFromDate.setDate(newFromDate.getDate() + 1);

    // let newToDate: any = new Date(filterData?.toDate);
    // newToDate.setDate(newToDate.getDate() + 1);

    // const response = await fetchGrievanceData(
    //   `/GetGrievanceCategoriesDetailsByFilter/?${
    //     filterData?.fromDate
    //       ? `FromDate=${newFromDate?.toISOString()}`
    //       : ""
    //   }${
    //     filterData?.toDate
    //       ? `&ToDate=${newToDate?.toISOString()}`
    //       : ""
    //   }${filterData?.user?.length > 0 ? `&RoleId=${filterData?.user}` : '-1'}${
    //     filterData?.category?.length > 0 ? `&CatId=${filterData?.category}` : '-1'
    //   }${
    //     filterData?.grivanceStatus?.length > 0
    //       ? `&IsTicketClosed=${filterData?.grivanceStatus}`
    //       : ""
    //   }&OFFSET=${currentPage}&PAGESIZE=${itemsPerPage}`
    // );

    const response = await fetchGrievanceData(
      `/GetGrievanceCategoriesDetailsByFilter?FromDate=${
        filterData?.fromDate
          ? moment(filterData?.fromDate)?.format("YYYY-MM-DD")
          : ""
      }&ToDate=${
        filterData?.toDate
          ? moment(filterData?.toDate)?.format("YYYY-MM-DD")
          : ""
      }&RoleId=${
        filterData?.user?.length > 0 ? filterData?.user : "-1"
      }&CatId=${
        filterData?.category?.length > 0 ? filterData?.category : "-1"
      }&IsTicketClosed=${filterData?.grivanceStatus}&OFFSET=${ current ? 0 :
        currentPage * itemsPerPage
      }&PAGESIZE=${itemsPerPage}`
    );

    if (response.isSuccess) {
      // console.table(response.data);
      const sortedTickets = handleSortByOrderDate(response.data);
      setTotalRecords(response?.data[0]?.total);
      setTickets(sortedTickets);
    } else {
      console.error("Error while fetching tickets: ", response.error);
      showToast({
        type: "error",
        title: "Something Went Wrong!",
        message: response.message || "Could not fetch tickets.",
      });
    }
    setIsLoading(false);
  };

  const closeSpecificTicket = async () => {
    if (!selectedTicket?.ticket_Id) {
      showToast({
        type: "error",
        message: "Please select an open ticket",
      });
      return;
    }
    if (!selectedTicket?.reply) {
      showToast({
        type: "error",
        message: "Please provide a reply to close this ticket",
      });
      return;
    }
        if (!selectedTicket?.closedTicketDate) {
          showToast({
            type: "error",
            message: "Please provide a Close Date to close this ticket",
          });
          return;
        }

    setIsLoading(true);


    const response = await putGrievanceDataWithoutPayload(
      `/ResolveGrievanceTicket/${selectedTicket?.ticket_Id}/${user?.user_Id}/${
        selectedTicket?.reply
      }/${moment(selectedTicket?.closedTicketDate).format("YYYY-MM-DD")}`
    );
    if (response.isSuccess) {
      // console.log("Response for close ticket: ", response.data);
      handleFetchTickets();
      setIsOpenDrawer(false);
    } else {
      console.error("Error while closing ticket: ", response.error);
      showToast({
        type: "error",
        title: "Something Went Wrong!",
        message: response.message || "Could not close tickets.",
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (filterData?.user?.length > 0) {
      getCategories();
    }
  }, [filterData?.user]);

  useEffect(() => {
    if (tickets?.length > 0) {
      handleFetchTickets();
    }
  }, [currentPage]);

  const nextPage = () => setCurrentPage(currentPage + 1);
  const prevPage = () => setCurrentPage(currentPage - 1);

  const onSearchFunc = () => {
    handleFetchTickets(true);
    setCurrentPage(0)
  };

  // console.log("user", users);
  // console.log("category", category);

  return {
    activeTab,
    setActiveTab,
    tickets,
    isLoading,
    handleFetchTickets,
    handleReplyInput,
    closeSpecificTicket,

    isOpenDrawer,
    selectedIndex,
    setSelectedIndex,
    selectedTicket,
    setSelectedTicket,
    setIsOpenDrawer,

    filterData,
    filterHandleChange,
    users,
    category,
    currentPage,
    itemsPerPage,
    totalRecords,
    nextPage,
    prevPage,
    setCurrentPage,
    onSearchFunc,
    handleCloseDateInput,
  };
}
