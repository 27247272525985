import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { AppContext } from './../../../../AppContext';

import { showToast } from '../../../../utils/utility';
import useMasterData from '../../../Hooks/useMasterData';
import { handleValidateParams } from '../../../../utils/validators';
import { usePeriodicAssessment } from '../../../../libs/data-access';
import { editPeriodicScheduleParams, periodicScheduleParams } from '../../../../initialStates';
import { IPeriodicAssessmentScheduleList, IPeriodicAssessmentSchedules, IScheduleORFQuestion } from '../../../../libs/data-access/types';
import { IPeriodicAssessmentScheduleNewPayload, IPeriodicAssessmentSchedulePayload, IPeriodicScheduleEditPayload } from '../../../../types';

export default function useSchedulePeriodicAssessment() {

    const navigate = useNavigate();

    const {
        user
    } = useContext(AppContext);
    
    const {
        fetchPeriodicAssessment,
        postPeriodicAssessment,
        putPeriodicAssessment,
        deletePeriodicAssessment
    } = usePeriodicAssessment();

    const {
        assessmentTypes,
        getAssessmentTypes,
        classes,
        getClasses,
        subjectList,
        getSubject,
        competencyByClassSubject,
        getCompetencyBasedOnClassAndSubject,
    } = useMasterData();

    const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true);
    const [newScheduleParams, setNewScheduleParams] = useState<IPeriodicAssessmentSchedules>(periodicScheduleParams);
    const [newOrfQuestion, setNewOrfQuestion] = useState<IScheduleORFQuestion>({ orF_Question_Text: "", min_Word_Read_Per_Minute: "", max_Seconds_To_Read: "" })
    const [availableScheduleList, setAvailableScheduleList] = useState<IPeriodicAssessmentScheduleList[]>([]);
    const [selectedScheduleToEdit, setSelectedScheduleToEdit] = useState<IPeriodicScheduleEditPayload>(editPeriodicScheduleParams);
    const [selectedScheduleToDelete, setSelectedScheduleToDelete] = useState<IPeriodicAssessmentScheduleList | undefined>(undefined);

    // loading states
    const [confirmSaveSchedule, setConfirmSaveSchedule] = useState<boolean>(false);
    const [isCreatingSchedule, setIsCreatingSchedule] = useState<boolean>(false);
    const [isEditingSchedule, setIsEditingSchedule] = useState<boolean>(false);
    const [isLoadingSchedules, setIsLoadingSchedules] = useState<boolean>(false);
    const [isSavingSchedule, setIsSavingSchedule] = useState<boolean>(false);

    useEffect(() => {
        if (isFirstLoad) {
            setIsFirstLoad(false);
        } else {
            handleFetchSchedulesForPeriodicAssessment();
        }
    }, [isFirstLoad]);

    // WHEN CREATING NEW SCHEDULE
    useEffect(() => {
        if (isCreatingSchedule) {
            getClasses();
            getSubject();
            getAssessmentTypes();
        }
    }, [isCreatingSchedule]);

    useEffect(() => {
        if (newScheduleParams.class_Id
            &&
            newScheduleParams.subject_Id) {
            handleNewScheduleParamsChange("scheduleCompetancy", []);
            getCompetencyBasedOnClassAndSubject(
                newScheduleParams.class_Id,
                newScheduleParams.subject_Id
            );
        }
    }, [
        newScheduleParams.class_Id,
        newScheduleParams.subject_Id
    ]);

    // WHEN EDITING EXISTING SCHEDULE

    useEffect(() => {
        if (isEditingSchedule) {
            getClasses();
            getAssessmentTypes();
        }
    }, [isEditingSchedule]);

    useEffect(() => {
        if (selectedScheduleToEdit.class_Id
            &&
            selectedScheduleToEdit.subject_Id) {
            // handleNewScheduleParamsChangeForEdit("scheduleCompetancy", []);
            getCompetencyBasedOnClassAndSubject(
                selectedScheduleToEdit.class_Id,
                selectedScheduleToEdit.subject_Id
            );
        }
    }, [
        selectedScheduleToEdit.class_Id,
        selectedScheduleToEdit.subject_Id
    ]);
    useEffect(() => {
        let totalQuestions = 0;
        newScheduleParams?.scheduleCompetancy?.map((e) => { totalQuestions = (totalQuestions + +e?.number_Of_Question) })
        setNewScheduleParams(prev => ({ ...prev, number_Of_Questions: totalQuestions }))
    }, [newScheduleParams?.scheduleCompetancy])

    /* HANDLER FUNCTIONS */
    const handleNewScheduleParamsChange = (
        key: keyof IPeriodicAssessmentSchedules | "updateCompetencyQuestionNumber",
        value: string | number | string[]
    ) => {
        if (key === "scheduleCompetancy" && Array.isArray(value)) {
            let competency = value?.map(each => ({
                competancy_Id: Number(each),
                number_Of_Question: "1",
                competency_name: competencyByClassSubject?.find(com => com.competancy_Id == Number(each))?.competancy
            }));
            setNewScheduleParams(prev => ({ ...prev, [key]: competency }));
        }
        else if (key === "updateCompetencyQuestionNumber") {
            let id = String(value).split(",")[0]
            const noOfQuestion = String(value).split(",")[1]
            setNewScheduleParams(prev =>
            ({
                ...prev,
                scheduleCompetancy:
                    prev?.scheduleCompetancy.map((item) =>
                        item?.competancy_Id === +id
                            ?
                            { ...item, number_Of_Question: isNaN(+noOfQuestion) ? noOfQuestion : noOfQuestion }
                            : item
                    ),
            }))
        }
        else {
            setNewScheduleParams(prev => ({ ...prev, [key]: value }));
        }
    };

    const handleUpdateDatesForScheduleParams = (
        key: "startDate" | "endDate",
        value: string | null
    ) => {
        value
            ?
            handleNewScheduleParamsChange(key, value)
            :
            handleNewScheduleParamsChange(key, "");
    };

    const handleResetNewScheduleParams = () => {
        setNewScheduleParams(periodicScheduleParams);
    };

    // const handleSetParametersForEditSchedule = (schedule: IPeriodicAssessmentScheduleList | undefined) => {
    //     if (schedule) {
    //         const existingCompetences = schedule.scheduleCompetency?.map((each) => ({
    //             periodic_Assessment_Schedule_Competancy_Id: schedule.periodic_Assessment_Schedule_Id,
    //             competancy_Id: each?.competency_Id
    //         }));
    //         const scheduleToBeEdited: IPeriodicScheduleEditPayload = {
    //             periodic_Assessment_Id: schedule.periodic_Assessment_Id,
    //             class_Id: schedule.class_Id,
    //             subject_Id: schedule.subject_Id,
    //             number_Of_Questions: schedule.total_Number_Of_Questions,
    //             start_Date: schedule.startDate,
    //             end_Date: schedule.endDate,
    //             orfQuestions:schedule.orfQuestions,
    //             modified_By: user.user_Id ? Number(user.user_Id) : 1,
    //             scheduleCompetancy: existingCompetences,
    //             id: schedule.periodic_Assessment_Schedule_Id
    //         };
    //         setSelectedScheduleToEdit(scheduleToBeEdited);
    //         setIsEditingSchedule(true);
    //     } else {
    //         setSelectedScheduleToEdit(editPeriodicScheduleParams);
    //     }
    // };

    const handleNewScheduleParamsChangeForEdit = (
        key: keyof IPeriodicScheduleEditPayload,
        value: string | number | string[]
    ) => {
        // setSelectedScheduleToEdit(prev => ({ ...prev, [key]: value }));
        if (key === "scheduleCompetancy" && Array.isArray(value)) {
            let competency = value?.map(each => ({
                competancy_Id: Number(each),
                periodic_Assessment_Schedule_Competancy_Id: Number(each)
                // competency_name: competencyByClassSubject?.find(each => each.competancy_Id === +each)?.competancy
            }));
            setSelectedScheduleToEdit(prev => ({ ...prev, [key]: competency }));
        } else {
            setSelectedScheduleToEdit(prev => ({ ...prev, [key]: value }));
        }
    };

    const handleUpdateDatesForScheduleParamsForEdit = (
        key: "start_Date" | "end_Date",
        value: string | null
    ) => {
        value
            ?
            handleNewScheduleParamsChangeForEdit(key, value)
            :
            handleNewScheduleParamsChangeForEdit(key, "");
    };

    const handleResetNewScheduleParamsForEdit = () => {
        setSelectedScheduleToEdit(editPeriodicScheduleParams);
    };

    /* Sorting the appointments array based on the date. */
    const handleSortByOrderDate = (
        appointments: IPeriodicAssessmentScheduleList[],
        isAscend?: boolean
    ) => {
        return appointments?.sort((a, b) => {
            const date1 = new Date(a.startDate);
            const date2 = new Date(b.startDate);
            if (isAscend) {
                return date1 > date2 ? 1 : -1;
            } else {
                return date1 < date2 ? 1 : -1;
            }
        });
    };

    


    /* API CALLS */
    const handleFetchSchedulesForPeriodicAssessment = async () => {
        setIsLoadingSchedules(true);
        const response = await fetchPeriodicAssessment(`/GetLastPeriodicAssessment`);

        if (response.isSuccess) {
            // console.log("Response for schedule list: ", response.data);
            const data: IPeriodicAssessmentScheduleList[] | undefined = response.data;
            if (data) {
                const schedules = handleSortByOrderDate(data, true);
                setAvailableScheduleList(schedules);
            };
        } else {
            // console.log("Error for schedule list: ", response.error);
            showToast({
                type: "error",
                title: "Oops!",
                message: response.message || "Could not fetch schedule!"
            });
        };
        setIsLoadingSchedules(false);
    };

    const handleSaveSchedulesForPeriodicAssessment = async () => {

        if (!user.user_Id) {
            showToast({
                type: "warning",
                title: "Please Login",
                message: "User could not be found."
            });
            return;
        };

        setIsSavingSchedule(true);
        const payload: IPeriodicAssessmentScheduleNewPayload = {
            created_By: user?.user_Id,
            periodic_Assessment_Id: newScheduleParams.periodic_Assessment_Id,
            start_Date: newScheduleParams.startDate,
            end_Date: newScheduleParams.endDate,
            class_Id: newScheduleParams.class_Id,
            subject_Id: newScheduleParams.subject_Id,
            total_Number_Of_Questions: newScheduleParams.number_Of_Questions ? +newScheduleParams.number_Of_Questions : 0,
            scheduleCompetancy: newScheduleParams.scheduleCompetancy.map(each => ({
                competancy_Id: each.competancy_Id,
                number_Of_Question: +each?.number_Of_Question
            })),
            scheduleORFQuestion: newScheduleParams?.scheduleORFQuestion.map(e => ({
                orF_Question_Text: e?.orF_Question_Text,
                min_Word_Read_Per_Minute: +e?.min_Word_Read_Per_Minute,
                max_Seconds_To_Read: e?.max_Seconds_To_Read
            }))
        };

        const isValid = handleValidateParams(payload);
        // console.log(
        //     "newScheduleParams: ", newScheduleParams,
        //     " Payload: ", payload,
        //     " IsValid: ", isValid
        // );
        if (isValid) {

            const response = await postPeriodicAssessment(`/CreatePeriodicAssessmentSchedule`, payload);

            if (response.isSuccess) {
                // console.log("Response for create new schedule: ", response.data);
                handleResetNewScheduleParams();
                setIsCreatingSchedule(false);
                handleFetchSchedulesForPeriodicAssessment();
                navigate('/schedule-periodic-assessment')
            } else {
                // console.log("Error for create schedule: ", response.error);
                showToast({
                    type: "error",
                    title: "Oops!",
                    message: response.message || "Could not create new schedule!"
                });
            }
        }
        setIsSavingSchedule(false);

    };

    const handleEditScheduleForPeriodicAssessment = async () => {

        if (!user.user_Id) {
            showToast({
                type: "warning",
                title: "Please Login",
                message: "User could not be found."
            });
            return;
        };

        setIsSavingSchedule(true);
        const payload: IPeriodicScheduleEditPayload = {
            start_Date: selectedScheduleToEdit?.start_Date,
            end_Date: selectedScheduleToEdit?.end_Date,
            modified_By: user.user_Id,
            periodic_Assessment_Id: selectedScheduleToEdit.periodic_Assessment_Id,
            class_Id: selectedScheduleToEdit.class_Id,
            subject_Id: selectedScheduleToEdit.subject_Id,
            number_Of_Questions: selectedScheduleToEdit.number_Of_Questions,
            orfQuestions: selectedScheduleToEdit.orfQuestions,
            scheduleCompetancy: selectedScheduleToEdit.scheduleCompetancy.map(each => ({
                periodic_Assessment_Schedule_Competancy_Id: each.periodic_Assessment_Schedule_Competancy_Id,
                competancy_Id: each.competancy_Id
            }))
        };
        // console.log(
        //     "selectedScheduleToEdit: ", selectedScheduleToEdit,
        //     "Payload: ", payload,
        //     "ID: ", selectedScheduleToEdit?.id
        // );

        const response = await putPeriodicAssessment(`/UpdatePeriodicAssessmentSchedule/${selectedScheduleToEdit?.id}`, payload);
        if (response.isSuccess) {
            // console.log("Response for schedule list: ", response.data);
            setSelectedScheduleToDelete(undefined);
            setIsEditingSchedule(false)
            handleResetNewScheduleParamsForEdit();
            handleFetchSchedulesForPeriodicAssessment();
        } else {
            // console.log("Error for delete schedule: ", response.error);
            showToast({
                type: "error",
                title: "Oops!",
                message: response.message || "Could not edit schedule!"
            });
        };
        setIsSavingSchedule(false);

    };

    const handleDeleteScheduleForPeriodicAssessment = async () => {
        setIsLoadingSchedules(true);
        const response = await deletePeriodicAssessment(`/DeletePeriodicAssessmentSchedule/${selectedScheduleToDelete?.periodic_Assessment_Schedule_Id}`);

        if (response.isSuccess) {
            setSelectedScheduleToDelete(undefined);
            handleFetchSchedulesForPeriodicAssessment();
        } else {
            // console.log("Error for delete schedule: ", response.error);
            showToast({
                type: "error",
                title: "Oops!",
                message: response.message || "Could not fetch schedule!"
            });
        };
        setIsLoadingSchedules(false);
    };

    return {
        classes,
        subjectList,
        competencyByClassSubject,
        getCompetencyBasedOnClassAndSubject,
        assessmentTypes,
        availableScheduleList,
        newScheduleParams,
        setNewScheduleParams,
        handleNewScheduleParamsChange,
        handleResetNewScheduleParams,
        handleUpdateDatesForScheduleParams,
        isLoadingSchedules,
        isCreatingSchedule,
        setIsCreatingSchedule,
        confirmSaveSchedule,
        setConfirmSaveSchedule,
        isSavingSchedule,
        handleSaveSchedulesForPeriodicAssessment,
        isEditingSchedule,
        setIsEditingSchedule,
        selectedScheduleToEdit,
        handleEditScheduleForPeriodicAssessment,
        selectedScheduleToDelete,
        setSelectedScheduleToDelete,
        handleDeleteScheduleForPeriodicAssessment,
        handleNewScheduleParamsChangeForEdit,
        handleUpdateDatesForScheduleParamsForEdit,
        handleResetNewScheduleParamsForEdit,
        newOrfQuestion,
        setNewOrfQuestion
    }
}
