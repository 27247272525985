import React from 'react'
import useLineGraphOverall from './hook/useLineGraphOverall'
import { ECard } from '../../../../libs/ui/src/Card/ECard'
import ReactApexChart from 'react-apexcharts'
import { Button, Grid, Skeleton } from '@mantine/core'
import { BsInfoCircle } from 'react-icons/bs';
import InformationToolTip from '../../../../libs/ui/src/Stat/InformationToolTip'
import ReactDOMServer from 'react-dom/server';
import './LineGraphOverall.scss'
import { groupByKey } from '../../../../utils/utility'
import { SeriesData } from '../../../../libs/data-access/types'

export default function LineGraphOverall() {

    const {
        isLoadingClassroomAndSchoolCompliance,
        activeButtonValue,
        setActiveButtonValue,
        classroomAndSchoolCompliance,

        isLoadingObservationCompliance,
        observationCompliance
    } = useLineGraphOverall();



      const seriesData: SeriesData[] = [
        {
          name: "Classroom Observation Compliance",
          data: classroomAndSchoolCompliance?.map(e => e?.Classroom_Observation_Percentage),
          customText: "% of targeted classroom observations completed by the mentors"
        },
        {
          name: "Unique School Visit Compliance",
          data: classroomAndSchoolCompliance?.map(e => e?.Unique_School_Percentage),
          customText: "% of unique schools visited at least once by the mentors"
        }
      ];
  

      let data = groupByKey(observationCompliance, "Question");
      // console.log("data",data)
      let monthName = new Set( observationCompliance?.map(e => e?.Month_Name) );
      const seriesDataClassroom: SeriesData[] = 
         Object.keys(data)?.map(each=>({
            name: each?.split("(")[0],
            data: data[each].map((e :any) => e?.Value),
            customText: data[each][0]?.ColName.substr(0, data[each][0]?.ColName?.length-1)
         }))
    //   [
    //     {
    //         name: "Shikshak Sandarshika ka Upyog",
    //         data: [23,23,2,7],
    //         customText: "% of classrooms where TGs are being used as per Lesson Plan with complete preparation"
    //     },
    //     {
    //         name: "Print samagri ka pradarshan",
    //         data: [20,18,15,27],
    //           customText: "% of classrooms where workbooks are being used as per the lesson plans in the TGs"
    //     },
    //     {
    //         name: "Pichad rahe bachcho k saath alag sey karya",
    //         data: [28,22,19,3], 
    //         customText: "% of classrooms where focused work is being done with struggling students through efficient usage of TGs and Workbooks"
    //     },
    //     {
    //         name: "Print samagri ka upyog",
    //         data: [23,27,9,10],
    //         customText: "% of classrooms where assessments are being done and skill passbook is filled completely"
    //     }
    //   ];
    return (
      <ECard title="" header={<></>}>
        <Grid>
          <Grid.Col span={6} className="border-r"></Grid.Col>
          <Grid.Col span={6}>
            <div className="flex justify-end gap-2">
              <Button
                disabled={
                  isLoadingClassroomAndSchoolCompliance ||
                  isLoadingObservationCompliance
                }
                variant={activeButtonValue === "hindi" ? "outline" : "default"}
                onClick={() => setActiveButtonValue("hindi")}
              >
                Hindi
              </Button>
              <Button
                disabled={
                  isLoadingClassroomAndSchoolCompliance ||
                  isLoadingObservationCompliance
                }
                variant={activeButtonValue === "math" ? "outline" : "default"}
                onClick={() => setActiveButtonValue("math")}
              >
                Math
              </Button>
            </div>
          </Grid.Col>
        </Grid>
        <Grid>
          <Grid.Col span={6} className="border-r">
            {isLoadingClassroomAndSchoolCompliance ? (
              <Skeleton radius={"md"} height={300} />
            ) : (
              <>
                <div className="card--head">
                  <div className="title">Mentoring Compliance</div>
                </div>
                <ReactApexChart
                  options={{
                    labels: [
                      "% of targeted classroom observations completed by the mentors",
                      " % of unique schools visited at least once by the mentors",
                    ],
                    chart: {
                      height: 350,
                      type: "line",
                      zoom: {
                        enabled: false,
                      },
                    },
                    dataLabels: {
                      enabled: false,
                    },
                    stroke: {
                      curve: "straight",
                      width: 2,
                    },
                    xaxis: {
                      categories: classroomAndSchoolCompliance?.map(
                        (e) => e?.Month_Name + " " + e?.Year_Name
                      ),
                    },
                    legend: {
                      show: true,
                      position: "top",
                      // formatter: function(seriesName, opts) {
                      //     // Custom tooltip content
                      //     return ['Classroom Observation Compliance', 'Unique School Visit Compliance'].includes(seriesName) ? seriesName + `<span class="tooltip-icon">ℹ️<span class="tooltip-content">test</span></span>` : seriesName;
                      //   }

                      formatter: function (seriesName, opts) {
                        // Check if the seriesName is one of the series you want to apply the tooltip to
                        if (
                          [
                            "Classroom Observation Compliance",
                            "Unique School Visit Compliance",
                          ].includes(seriesName)
                        ) {
                          // Render your custom tooltip component to HTML string
                          const customText =
                            opts.w.config.series[opts.seriesIndex].customText;
                          const tooltipComponentHtml =
                            ReactDOMServer.renderToStaticMarkup(
                              <div className="testing ml-3">
                                <p>
                                  <BsInfoCircle />
                                </p>
                                <div className="testingInside">
                                  {customText}
                                </div>
                              </div>
                            );
                          // Return seriesName along with tooltipComponentHtml
                          return seriesName + tooltipComponentHtml;
                        }
                        // If seriesName is not one of the specified series, return it as is
                        return seriesName;
                      },
                    },
                  }}
                  series={seriesData}
                  type="line"
                  height={350}
                />
              </>
            )}
          </Grid.Col>

          <Grid.Col span={6}>
            {isLoadingObservationCompliance ? (
              <Skeleton radius={"md"} height={300} />
            ) : (
              <>
                <div className="card--head">
                  <div className="title">
                    Classroom Teaching Learning Indicators
                  </div>
                </div>

                <ReactApexChart
                  options={{
                    chart: {
                      height: 350,
                      type: "line",
                      zoom: {
                        enabled: false,
                      },
                    },
                    dataLabels: {
                      enabled: false,
                    },
                    stroke: {
                      curve: "straight",
                      width: 2,
                    },
                    xaxis: {
                      categories: observationCompliance
                        ?.filter(
                          (e, index) =>
                            e?.Month_Name !==
                            observationCompliance[index - 1]?.Month_Name
                        )
                        .map(
                          (e) => e?.Month_Name + " " + e?.Year_Name
                        ),
                    },
                    legend: {
                      show: true,
                      position: "top",
                      formatter: function (seriesName, opts) {
                        // Check if the seriesName is one of the series you want to apply the tooltip to
                        // if ( Object.keys(data)?.includes(seriesName)) {
                        // Render your custom tooltip component to HTML string
                        const customText =
                          opts.w.config.series[opts.seriesIndex].customText;
                        const tooltipComponentHtml =
                          ReactDOMServer.renderToStaticMarkup(
                            <div className="testing ml-3">
                              <p>
                                <BsInfoCircle />
                              </p>
                              <div className="testingInside">{customText}</div>
                            </div>
                          );
                        // Return seriesName along with tooltipComponentHtml
                        return seriesName + tooltipComponentHtml;
                        // }
                        // If seriesName is not one of the specified series, return it as is
                        return seriesName;
                      },
                      // formatter: function(seriesName, opts) {
                      //     console.log("option",opts)
                      //     // Check if the seriesName is one of the series you want to apply the tooltip to
                      //     if (['Shikshak Sandarshika ka Upyog', 'Print samagri ka pradarshan',"Pichad rahe bachcho k saath alag sey karya","Print samagri ka upyog"].includes(seriesName)) {
                      //       // Render your custom tooltip component to HTML string
                      //       const tooltipComponentHtml = ReactDOMServer.renderToStaticMarkup(<InformationToolTip customClassName="testing ml-2" customToolTip={true} toolTipLabelInside={seriesName}/>);
                      //       // Return seriesName along with tooltipComponentHtml
                      //       return seriesName + tooltipComponentHtml;
                      //     }
                      //     // If seriesName is not one of the specified series, return it as is
                      //     return seriesName;
                      //   }
                    },
                  }}
                  series={seriesDataClassroom}
                  type="line"
                  height={350}
                />
              </>
            )}
          </Grid.Col>
        </Grid>
      </ECard>
    );
}