import React, { useEffect, useState } from 'react'
import { useMentorReports } from '../../../../../libs/data-access';
import { calculateCurrentMonth, calculateYearForDashboard, groupByKey, showToast } from '../../../../../utils/utility';
import { IMentorWiseVisitComplianceReportByMonth } from '../../../../../libs/data-access/types';
import moment from 'moment';
import useDashboardVisitComplianceReports from './useDashboardVisitComplianceReports';
import { useUserManagementFetch } from '../../../../../libs/data-access/src/UserManagement/useUserManageFetch';

export default function useMentorWiseVisitCompliance() {

    const {
      fetchMentorReport,
      fetchMentorFastApiReport,
      fetchMentorVisitReport,
    } = useMentorReports();

      const {
        fetchUserManagementData,
      } = useUserManagementFetch();

    const {
      selectedBlock,
      selectedDistrict,
      districts,
      blocks,
      setSelectedBlock,
      setSelectedDistrict,
      selectedMonth,
      setSelectedMonth,
      handelClearFilter,
      selectedSession,
      setSelectedSession,
    } = useDashboardVisitComplianceReports();

    // const [selectedMonth, setSelectedMonth] = useState<string>(calculateCurrentMonth());
    const [mentorWiseVisitComplianceReport, setMentorWiseVisitComplianceReport] = useState<IMentorWiseVisitComplianceReportByMonth[]>([]);
    // data for CSV download
    const [districtWiseComplianceCSV, setDistrictWiseComplianceCSV] = useState<{ headers: any[], content: any[] }>();

    // loading
    const [isLoading, setIsLoading] = useState<boolean>(false);

      const [userManageRoles, setuserManageRoles] = useState([]);
      const [userManageDesignation, setuserManageDesignation] = useState([]);

        const [userMonitorValue, setUserMonitorValue] =
          useState<any>({
            role: null,
            designation: null,
          });


    /* HANDLER FUNCTIONS */
    const handleSelectMonth = (value: string) => {
        setSelectedMonth(value);
        setMentorWiseVisitComplianceReport([]);
    };

    const formatVisitComplianceByDistrictReport = (visitCompliance: IMentorWiseVisitComplianceReportByMonth[]) => {

        const groupedData: {
            [mentor: string]: IMentorWiseVisitComplianceReportByMonth[]
        } = groupByKey(visitCompliance, "Unique_Code");
        // console.log("groupedData: ", groupedData);
        
        const formattedGroupedData = Object.keys(groupedData).map(each => {

            let stats = {
                Total_Classroom_Observations: 0,
                Total_Unique_School_Visit: 0,
                Total_School_Visit: 0,
                Total_Student_Spot_Tested: 0
            };
            // calculate stats
            groupedData[each].forEach(e => {

                stats.Total_Classroom_Observations += e.Total_Classroom_Observations || 0;
                stats.Total_Unique_School_Visit += e.Total_Unique_School_Visit || 0;
                stats.Total_School_Visit += e.Total_School_Visit || 0;
                stats.Total_Student_Spot_Tested += e.Total_Student_Spot_Tested || 0;

            });

            let formattedData = Object.assign({
                District_Name: groupedData[each]?.[0]?.District_Name || "",
                Block_Name: groupedData[each]?.[0]?.Block_Name || "",
                Cluster_School_Name: groupedData[each]?.[0]?.Cluster_School_Name || "",
                Unique_Code: groupedData[each]?.[0]?.Unique_Code || "",
                Visit_Month: groupedData[each]?.[0].Visit_Month || "",
                Total_Classroom_Observations: stats.Total_Classroom_Observations,
                Total_Unique_School_Visit: stats.Total_Unique_School_Visit,
                Total_School_Visit: stats.Total_School_Visit,
                Total_Student_Spot_Tested: stats.Total_Student_Spot_Tested
            });

            return formattedData;
            
        });

        // console.log("formattedGroupedData: ", formattedGroupedData);

        const headers = [
            { label: "District", key: "district" },
            { label: "Block", key: "block" },
            { label: "Cluster ( if applicable)", key: "cluster" },
            { label: userMonitorValue?.role=='3' ?"Mentor ID" : "Monitor ID", key: "mentor_id" },
            { label: "Month of visit", key: "month_of_visit" },
            { label: "Total Classroom Observations", key: "total_classroom_observations" },
            { label: "Total Unique School Visits", key: "total_unique_school_visits" },
            // { label: "Total school visits", key: "total_school_visits" },
            { label: "Total Number of Students Spot Tested", key: "total_number_of_students_spot_tested" },
        ];

        const data = formattedGroupedData?.map(each => Object.assign({
            district: each.District_Name || "",
            block: each?.Block_Name || "",
            cluster: each?.Cluster_School_Name || "",
            mentor_id: each.Unique_Code ?? "",
            // month_of_visit: each?.visit_Month ?  each?.visit_Month == "Never Visited!" ? "Never Visited!" : moment(each?.visit_Month).format("DD-MM-YYYY") : "",
            month_of_visit: each?.Visit_Month ? each?.Visit_Month : "",
            total_classroom_observations: each.Total_Classroom_Observations ?? "",
            total_unique_school_visits: each.Total_Unique_School_Visit ?? "",
            // total_school_visits: each.Total_School_Visit ?? "",
            total_number_of_students_spot_tested: each.Total_Student_Spot_Tested ?? ""
        }));

        setDistrictWiseComplianceCSV({
            headers: headers,
            content: data
        });

    };

    const capitalizeKeys = (data:any) => {
      return data.map((item:any) => {
        const capitalizedItem:any = {};
        for (const key in item) {
          const capitalizedKey = key.charAt(0).toUpperCase() + key.slice(1);
          capitalizedItem[capitalizedKey] = item[key];
        }
        return capitalizedItem;
      });
    }

    /* API CALLS */
    const fetchDistrictWiseMentorVisitCompliance = async () => {
        if(userMonitorValue?.role?.length>0){
        setDistrictWiseComplianceCSV({ headers: [], content: [] })
        setMentorWiseVisitComplianceReport([])
        setIsLoading(true);
        const response = await fetchMentorVisitReport(
          `GetMentorWiseSchoolVisitSummaryByMonthWithFilter/${`${
            selectedMonth
              ? calculateYearForDashboard(
                  +selectedMonth,
                  Number(selectedSession)
                )
              : moment().format("YYYY")
          }-${selectedMonth}-01`}/${selectedDistrict ?? 0}/${
            selectedBlock ?? 0
          }/${userMonitorValue?.role ?? 0}/${
            userMonitorValue?.designation ?? 0
          }`
        );

        if (response.isSuccess) {

            // console.log("Response for report: ", response.data);
            let capitalizeData = capitalizeKeys(response.data);
            // console.log("capitalizeData", capitalizeData);
            setMentorWiseVisitComplianceReport(capitalizeData);
            formatVisitComplianceByDistrictReport(capitalizeData);

        } else {

            console.log("Error while fetching report: ", response.error);
            showToast({
                type: "error",
                title: "Error while fetching report",
                message: response.message || "Something went wrong!"
            });

        };
        setIsLoading(false);
    }
    else{
         showToast({
           type: "warning",
           message: "Please provide role",
         });
    }
    };

      const handleChangeUserMonitor = ({
        name,
        value,
      }: {
        name: string;
        value: any;
      }) => {
        setUserMonitorValue((prev:any) => {
          return {
            ...prev,
            [name]: value,
          };
        });
      };

      const manageGetRoles = async () => {
        setuserManageRoles([]);
        let response = await fetchUserManagementData(`GetRoleMaster`);
        if (response?.isSuccess) {
          const data = response.data.map((e: any) => ({
            id: e?.role_Id,
            name: e?.role_Name,
          }));
          data && setuserManageRoles(data);
        } else {
          console.log("Error while getting roles list");
        }
      };

        const manageGetDesignationByRoles = async (roleId: any) => {
          setuserManageDesignation([]);
          let response = await fetchUserManagementData(
            `GetDesignationByRole?RoleId=${roleId}`
          );
          if (response?.isSuccess) {
            const data = response.data.map((e: any) => ({
              id: e?.designation_Id,
              name: e?.designation,
            }));
            data && setuserManageDesignation(data);
          } else {
            console.log("Error while getting roles list");
          }
        };

          useEffect(() => {
            manageGetRoles();
          }, []);

            useEffect(() => {
              if (userMonitorValue?.role?.length > 0) {
                manageGetDesignationByRoles(userMonitorValue?.role);
              }
            }, [userMonitorValue?.role]);

    return {
      isLoading,
      handleSelectMonth,
      mentorWiseVisitComplianceReport,
      districtWiseComplianceCSV,
      fetchDistrictWiseMentorVisitCompliance,
      selectedBlock,
      selectedDistrict,
      districts,
      blocks,
      setSelectedBlock,
      setSelectedDistrict,
      selectedMonth,
      setSelectedMonth,
      handelClearFilter,
      selectedSession,
      setSelectedSession,
      handleChangeUserMonitor,
      userMonitorValue,
      userManageRoles,
      userManageDesignation,
    };
}
