import React, { useEffect, useState } from 'react'
import { useNotificationData, useSurveyData } from '../../../libs/data-access'
import { IDistrictBlock, INewNotification, IRole } from '../../../types'
import moment from 'moment'
import { showToast } from '../../../utils/utility'
import { handleValidNewNotification } from '../../../utils/validators'
import { useNavigate } from 'react-router-dom'

const DefaultNewNotification = {
    heading_Text: "",
    description: "",
    notification_Date: "",
    media_URL: "",
    details: []
}

export default function useNotification() {

    const {postNotificationData}=useNotificationData()  
    const navigate =useNavigate()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isFirst, setIsFirst] = useState<boolean>(true)
    const [isSearching, setIsSearching] = useState<boolean>(false)
    const [isConfirm, setIsConfirm] = useState<boolean>(false)
    const [selectedDistrict, setSelectedDistrict] = useState<IDistrictBlock[]>([])
    const [selectedRole, setSelectedRole] = useState<IRole[]>([])
    const [selectedNotification, setSelectedNotification] = useState<number | undefined>(undefined)
    const [newNotification, setNewNotification] = useState<INewNotification>(DefaultNewNotification)
    useEffect(() => {
        if (isFirst) {
            setIsFirst(false)
        }
        else {
            // console.log("search")
        }
    }, [isFirst, isSearching])

    // HANDLER FUNCTION

    const handleSave = () => {
        let payload: INewNotification = {
            ...newNotification,
            notification_Date: moment().format(),
            details: [
                {
                    role_Id: selectedRole?.map(e => e?.name),
                    designation_Id: selectedRole?.map(e => e?.designation).flat(),
                    district_Id: selectedDistrict?.map(e => e?.name),
                    block_Id: selectedDistrict?.map(e => e?.blocks).flat()?.map(e=>String(e))
                }
            ]
        }
        const valid = handleValidNewNotification(payload)
        if (valid) {
            PostNotification(payload)
        }
    }

    // API function   

    const PostNotification = async (payload:INewNotification) => {
        setIsLoading(true)
        const res = await postNotificationData(`sendNotification`,payload)
        if(res.isSuccess){
          showToast({
            type:"success",
            message:"Notification sent to the user"
          })
          navigate('/notification')
        }
        else{
            showToast({
                type:"error",
                message: res?.message || "error while sending notification"
              })
        }
        setIsLoading(false)
    }

    return {
        isLoading,
        isSearching,
        isConfirm,
        setIsConfirm,
        setIsSearching,
        selectedDistrict,
        setSelectedDistrict,
        selectedRole,
        setSelectedRole,
        selectedNotification,
        setSelectedNotification,
        newNotification,
        setNewNotification,
        handleSave
    }
}
