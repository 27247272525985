import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { IPeriodicAssessmentScheduleList, IPeriodicAssessmentSchedules, IScheduleORFQuestion } from '../../../../libs/data-access/types';
import { usePeriodicAssessment } from '../../../../libs/data-access';
import { showToast } from '../../../../utils/utility';
import useMasterData from '../../../Hooks/useMasterData';
import { IPeriodicScheduleEditPayload } from '../../../../types';
import { AppContext } from '../../../../AppContext';

export default function useEditSchedulePeriodic() {
    
    const param =useParams();
    const navigate =useNavigate();
    const {
        user
    } = useContext(AppContext);

    const {
        assessmentTypes,
        getAssessmentTypes,
        classes,
        getClasses,
        subjectList,
        getSubject,
        competencyByClassSubject,
        getCompetencyBasedOnClassAndSubject,
    } = useMasterData();
    
    const {
        fetchPeriodicAssessment,
        postPeriodicAssessment,
        putPeriodicAssessment,
        deletePeriodicAssessment
    } = usePeriodicAssessment();

    const [isFirst,setIsFirst] =useState<boolean>(true);
    const [isLoading,setIsLoading] =useState<boolean>(false)
    const [selectedPeriodicSchedule,setSelectedPeriodicSchedule] =useState<IPeriodicAssessmentScheduleList>()
    const [newOrfQuestion, setNewOrfQuestion] = useState<IScheduleORFQuestion>({ orF_Question_Text: "", min_Word_Read_Per_Minute: "", max_Seconds_To_Read: "" })
    
    const [isSavingSchedule, setIsSavingSchedule] = useState<boolean>(false);
    const [confirmSaveSchedule, setConfirmSaveSchedule] = useState<boolean>(false);

    useEffect(()=>{
      if(isFirst){
        setIsFirst(false)
      }
      else{
        getPeriodicAssessmentScheduleById()
        getAssessmentTypes()
        getClasses()
        getSubject()
      }
    },[isFirst])

    useEffect(() => {
        if (selectedPeriodicSchedule?.class_Id
            &&
            selectedPeriodicSchedule?.subject_Id) {
            getCompetencyBasedOnClassAndSubject(
                selectedPeriodicSchedule.class_Id,
                selectedPeriodicSchedule.subject_Id
            );
        }
    }, [
        selectedPeriodicSchedule?.class_Id,
        selectedPeriodicSchedule?.subject_Id
    ]);

    useEffect(() => {
        let totalQuestions = 0;
        selectedPeriodicSchedule?.scheduleCompetency?.map((e) => { totalQuestions = (totalQuestions + +e?.number_Of_Question) })
        selectedPeriodicSchedule &&
        setSelectedPeriodicSchedule({ ...selectedPeriodicSchedule, total_Number_Of_Questions: totalQuestions })
    }, [selectedPeriodicSchedule?.scheduleCompetency])



    const handleEditScheduleParamsChange = (
        key: keyof IPeriodicAssessmentSchedules | "updateCompetencyQuestionNumber",
        value: string | number | string[]
    ) => {
        if (key === "scheduleCompetancy" && Array.isArray(value)) {
            let competency = value?.map(each => ({
                competency_Id: Number(each),
                number_Of_Question: 1,
                competency: competencyByClassSubject?.find(com => com.competancy_Id == Number(each))?.competancy ?? ""
            }));
            selectedPeriodicSchedule &&
            setSelectedPeriodicSchedule({ ...selectedPeriodicSchedule, scheduleCompetency: competency });
        }
        else if (key === "updateCompetencyQuestionNumber") {
            let id = String(value).split(",")[0]
            const noOfQuestion = String(value).split(",")[1]
            selectedPeriodicSchedule &&
            setSelectedPeriodicSchedule(
            {
                ...selectedPeriodicSchedule,
                scheduleCompetency:
                    selectedPeriodicSchedule?.scheduleCompetency?.map((item) =>
                        item?.competency_Id === +id
                            ?
                            { ...item, number_Of_Question: isNaN(+noOfQuestion)  ? 1 : +noOfQuestion }
                            : item
                    ),
            })
        }
        else {
            selectedPeriodicSchedule &&
                    setSelectedPeriodicSchedule({
                        ...selectedPeriodicSchedule ,[key]: value
                    })
        }
    };


    const handleEditSchedulesForPeriodicAssessment =()=>{
         setIsSavingSchedule(true)
         if(selectedPeriodicSchedule){
         let payload : IPeriodicScheduleEditPayload ={
             periodic_Assessment_Id: selectedPeriodicSchedule.periodic_Assessment_Id,
             class_Id: selectedPeriodicSchedule.class_Id,
             subject_Id: selectedPeriodicSchedule.subject_Id,
             number_Of_Questions: selectedPeriodicSchedule.total_Number_Of_Questions,
             start_Date: selectedPeriodicSchedule?.startDate,
             end_Date: selectedPeriodicSchedule?.endDate,
             modified_By: user?.user_Id,
            //  TODO: schedule periodic Competency is taking periodic_Assessment_Schedule_Competancy_Id as payload which not a correct eay 

             scheduleCompetancy: selectedPeriodicSchedule?.scheduleCompetency?.map(e=>({
                periodic_Assessment_Schedule_Competancy_Id: e?.periodic_Assessment_Schedule_Competency_Id ?? 0,
                competancy_Id: e?.competency_Id
             })),
             orfQuestions: selectedPeriodicSchedule?.orfQuestions?.map((each)=>({
                periodic_ORF_Question_Id: each?.periodic_ORF_Question_Id ?? 0,
                orF_Question_Text: each?.orF_Question_Text ?? "",
                min_Word_Read_Per_Minute: each?.min_Word_Read_Per_Minute,
                max_Seconds_To_Read: each?.max_Seconds_To_Read
             }))
         }

        //  console.log("PAyload for edit periodic assessment schedule ",payload)
    }
    setIsSavingSchedule(false)
}

    const getPeriodicAssessmentScheduleById =async()=>{
        setIsLoading(true)
         const res= await fetchPeriodicAssessment(`/GetLastPeriodicAssessment`)
         if(res.isSuccess){
            setSelectedPeriodicSchedule(res?.data[0])
         }
         else{
            showToast({
                type:"error",
                message:res.message ?? "Fail to fetch schedule periodic details"
            })
            navigate(`/schedule-periodic-assessment`)
         }
        setIsLoading(false)
    }
  return {
    isLoading,
    assessmentTypes,
    selectedPeriodicSchedule,
    setSelectedPeriodicSchedule,
    classes,
    subjectList,
    competencyByClassSubject,
    newOrfQuestion,
    setNewOrfQuestion,
    isSavingSchedule,
    confirmSaveSchedule,
    setConfirmSaveSchedule,
    handleEditScheduleParamsChange,
    handleEditSchedulesForPeriodicAssessment
  }
}
