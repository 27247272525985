import React, { useContext, useEffect, useState } from 'react'
import { useStudentLearning } from '../../../../../libs/data-access';
import { AssessmentFilterContext } from '../../Components/Hooks/useAssessmentFilter';
import { ISpotAssessmentUniqueStudentExcelReport, ISpotAssessmentDownloadReport, ISpotAssessmentUniqueStudentData,  } from '../../../../../libs/data-access/types';
import moment from 'moment';
import { calculateYearForDashboard, showToast } from '../../../../../utils/utility';

export default function useUniqueStudentsSpotTested()  {

    const {
      fetchSpotAssessment
    } =useStudentLearning()
    
    const {
      handleChangeFilters,
      handleIsSearching,
      isSearching,
      district,
      block,
      cluster,
      subject,
      grade,
      month,
      activeTab,
      selectedSession
    } = useContext(AssessmentFilterContext);
    const [isFirst,setIsFirst]=useState<boolean>(true)
    const [isLoading,setIsLoading]=useState<boolean>(false)
    const [isLoadingDownloadReport,setIsLoadingDownloadReport]=useState<boolean>(false)
    const [isMapLoading,setIsMapLoading]=useState<boolean>(false)
    const [keysForTableHeader,setKeysForTableHeader] =useState<any[]>([])
    const [keysForCompetency,setKeysForCompetency] =useState<any[]>([])
    const [spotAssessmentUniqueStudentData,setSpotAssessmentUniqueStudentData]=useState<ISpotAssessmentUniqueStudentData[]>([])
    const [spotAssessmentMapData,setSpotAssessmentMapData]=useState<ISpotAssessmentUniqueStudentData[]>([])
    // data for CSV download
    const [spotAssessmentUniqueStudentCSV, setSpotAssessmentUniqueStudentCSV] = useState<{ headers: any[], content: any[] }>();

    const [totalForData, setTotalForData] = useState<any>({});

  useEffect(()=>{
    if(isSearching && activeTab == "3"){
      getSpotAssessmentUniqueStudentData()
      getSpotAssessmentMapDate()
      getSpotAssessmentDownloadResult()
  }
    else{
    // console.log(isSearching)
}},[isSearching])

    useEffect(()=>{
      setSpotAssessmentUniqueStudentData([])
        setKeysForTableHeader([])
        setSpotAssessmentMapData([])
        setSpotAssessmentUniqueStudentCSV(undefined)
       },[activeTab])

    const formatSpotAssessmentCompletedReport = (visitCompliance: ISpotAssessmentUniqueStudentExcelReport[]) => {

      const headers = [
          { label: "District", key: "District_Name" },
          { label: "Block", key: "Block_Name" },
          { label: "Cluster School Name", key: "Cluster_School_Name" },
          { label: "School Name", key: "School_Name" },
          { label: "Total Students", key: "Total_Student" },
          { label: "Total Assessment", key: "Total_Assessment" },
          { label: "Assessment Student Percentage", key: "Assessment_Student_Percentage" },
      ];

      const data = visitCompliance?.map(each => Object.assign({  
        District_Name: each?.District_Name || "",
        Block_Name: each?.Block_Name || "",
        Cluster_School_Name: each?.Cluster_School_Name || "",
        School_Name: each?.School_Name || "",
        Total_Student: each?.Total_Student || "",
        Total_Assessment: each?.Total_Assessment || "",
        Assessment_Student_Percentage: each?.Assessment_Student_Percentage || ""
      }));

      setSpotAssessmentUniqueStudentCSV({
          headers: headers,
          content: data
      });

  };
 
    //  API Functions

    const getSpotAssessmentUniqueStudentData = async()=>{
        setIsLoading(true)
        setSpotAssessmentUniqueStudentData([])
        setKeysForTableHeader([])
        const res = await fetchSpotAssessment(
          `Compliance/NumberOfUniqueStudentSpotTested?SubjectId=${
            subject ?? 0
          }&DistrictId=${district ?? 0}&BlockId=${block ?? 0}&ClusterId=${
            cluster ?? 0
          }&Date=${`${
            month
              ? calculateYearForDashboard(+month, Number(selectedSession))
              : moment().format("YYYY")
          }/${month}/01`}&ClassId=${grade ?? 0}`
        );
          if(res?.isSuccess){
            setIsLoading(false)
            handleIsSearching(false)
            setSpotAssessmentUniqueStudentData(res?.data)
               let KEYS = Object.keys(res?.data?.[0]) 
               setKeysForTableHeader(KEYS)

                 let total = {
                   name: "total",
                   Total_Student: res?.data?.reduce(
                     (sum: any, item: any) => sum + item?.Total_Student,
                     0
                   ),
                   Total_Assessment: res?.data?.reduce(
                     (sum: any, item: any) => sum + item?.Total_Assessment,
                     0
                   ),
                   Assessment_Student_Percentage: (
                     res?.data?.reduce(
                       (sum: any, item: any) =>
                         sum + item?.Assessment_Student_Percentage,
                       0
                     ) / res?.data?.length
                   ).toFixed(2),
                 };

                 setTotalForData(total);
               
          }
          else{
            showToast({
              type:"error",
              message: res?.message ?? "Error while generating spot assessment unique Student"
            })
          }
          setIsLoading(false)
          handleIsSearching(false)
    }

    // MAP DATA

    const getSpotAssessmentMapDate = async()=>{
      setSpotAssessmentMapData([])
        setIsMapLoading(true)
        const res = await fetchSpotAssessment(
          `Compliance/NumberOfUniqueStudentSpotTested?SubjectId=${
            subject ?? 0
          }&DistrictId=${0}&BlockId=${0}&ClusterId=${0}&Date=${`${
            month
              ? calculateYearForDashboard(+month, Number(selectedSession))
              : moment().format("YYYY")
          }/${month}/01`}&ClassId=${grade ?? 0}`
        );
          if(res?.isSuccess){
            setSpotAssessmentMapData(res?.data)
          }
          else{
            showToast({
              type:"error",
              message: res?.message ?? "Error while generating spot assessment unique Student"
            })
          }
          setIsMapLoading(false)
          // handleIsSearching(false)
    }
    const getSpotAssessmentDownloadResult = async()=>{
        setIsLoadingDownloadReport(true)
        setSpotAssessmentUniqueStudentCSV(undefined)
        const res = await fetchSpotAssessment(
          `Compliance/NumberOfUniqueStudentSpotTestedSchoolLevel?SubjectId=${
            subject ?? 0
          }&Date=${`${
            month
              ? calculateYearForDashboard(+month, Number(selectedSession))
              : moment().format("YYYY")
          }/${month}/01`}&ClassId=${grade ?? 0}`
        );
          if(res?.isSuccess){
            formatSpotAssessmentCompletedReport(res?.data)
          }
          else{
            showToast({
              type:"error",
              message:res?.message ?? "Error while generating spot assessment Excel"
            })
          }
          setIsLoadingDownloadReport(false)
    }
  return {
    isLoading,
    spotAssessmentUniqueStudentData,
    spotAssessmentUniqueStudentCSV,
    keysForTableHeader,
    keysForCompetency,
    spotAssessmentMapData,
    isMapLoading,
    isLoadingDownloadReport,
    totalForData,
  };
}

