import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { useTeacherTraining } from '../../../../../../libs/data-access'
import { AppContext } from '../../../../../../AppContext';
import { showToast } from '../../../../../../utils/utility';
import { IEditTeacherAttendance, ILastAttendanceDetails, IPostTeacherAttendance, ITeacherList, ITeacherMarkAttendanceList } from '../../../../../../libs/data-access/types';
import * as XLSX from "xlsx";

export default function useMarkAttendance() {

  const {
    user
  } = useContext(AppContext);
  const paramId = useParams()
  const {
    fetchTeacherTrainingData,
    PostTeacherTrainingData,
    PutTeacherTrainingData,
    DeleteTeacherTrainingData
  } = useTeacherTraining()


const location = useLocation();

  const [isFirst, setIsFirst] = useState<boolean>(true)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isLoadingAttendanceList, setIsLoadingAttendanceList] = useState<boolean>(false)
  const [isLoadingLastAttendanceDetails, setIsLoadingLastAttendanceDetails] = useState<boolean>(false)
  const [isBtnLoading, setIsBtnLoading] = useState<boolean>(false)
  const [isLoadingLastAttendanceUploading, setIsLoadingLastAttendanceUploading] = useState<boolean>(false)

  const [activeTab, setActiveTab] = useState<string | null>('attendanceList');
  const [isNewAttendanceMarking, setIsNewAttendanceMarking] = useState<boolean>(false)
  const [teacherList, setTeacherList] = useState<ITeacherList[]>()
  const [teacherAttendanceList, setTeacherAttendanceList] = useState<ITeacherList[]>()
  const [teacherMarkAttendanceList, setTeacherMarkAttendanceList] = useState<ITeacherMarkAttendanceList[]>()
  const [lastAttendanceDetails, setLastAttendanceDetails] = useState<ITeacherList[]>()
  const [isLoadingAttendanceStatus, setIsLoadingAttendanceStatus] =
    useState<Boolean>(false);

  const [index,setIndex] = useState<any>([])

  const [disableTab, setDisableTab] = useState<any>(false)

  useEffect(() => {
    // console.log("location i satate",location.state)
    if(location?.state){
setDisableTab(true)
    }else{
      setDisableTab(false);
    }
    
  }, [location])
  

  useEffect(() => {
    if (isFirst) {
      setIsFirst(false)
    }
    else {
      getMarkAttendanceList()
    }
  }, [isFirst])

  useEffect(() => {
    if (activeTab === "editLastAttendance") {
      if (teacherMarkAttendanceList?.length) {
        getLastAttendanceDetails(teacherMarkAttendanceList[0]?.training_Schedule_Attendance_Id)
      }
    }
    if (activeTab == "markNewAttendance") {
      getTeacherListByScheduleID()
    }
  }, [activeTab])

  useEffect(() => {
    setTeacherAttendanceList(teacherList?.map(e => ({ ...e, is_Present: true })))
  }, [teacherList])

  const handleSubmitAttendance = () => {
    postTeacherAttendance()
  }

  const handleSubmitLastAttendance = () => {
    if (teacherMarkAttendanceList?.length && lastAttendanceDetails?.length) {
      const payload: IEditTeacherAttendance =
      {
        training_Schedule_Attendance_Id: teacherMarkAttendanceList[0]?.training_Schedule_Attendance_Id,
        block_Admin_User_Id: user?.user_Id,
        teacherSelection: lastAttendanceDetails?.map((e) => (
          {
          training_Schedule_Teacher_Id: e?.training_Schedule_Teacher_Id,
          is_Present: e?.is_Present ? true : false
        }))
      }
       
      putLastTeacherAttendance(payload)
    }
  }

  // API function

  const getMarkAttendanceList = async () => {
    setIsLoadingAttendanceList(true)
    setTeacherMarkAttendanceList([])
    const res = await fetchTeacherTrainingData(`/BlockAdmin/GetTrainingScheduleAttendanceList/${paramId?.id}/${user?.user_Id}`)
    if (res.isSuccess) {
      setTeacherMarkAttendanceList(res?.data)
    }
    else {
      showToast({
        type: "error",
        message: res?.message ?? "Error while getting attendance list"
      })
    }
    setIsLoadingAttendanceList(false)
  }

  // const getTeacherTrainingAttendanceStatus = async (date:any) => {
  //    setIsLoadingAttendanceList(true);
  //    setTeacherTrainingAttendanceStatus([]);
  //    const res = await fetchTeacherTrainingData(
  //      `/GetTeacherTrainingAttendanceStatus?TeacherTrainingScheduleId=${paramId?.id}&AttendanceDatetime=${date}`
  //    );
  //    if (res.isSuccess) {
  //      setTeacherTrainingAttendanceStatus(res?.data);
  //    } else {
  //      showToast({
  //        type: "error",
  //        message: res?.message ?? "Error while getting attendance list",
  //      });
  //    }
  //    setIsLoadingAttendanceList(false);
  // }

  const getTeacherTrainingAttendanceStatus = async (date: any,index:number) => {
    setIndex(index)
    setIsLoadingAttendanceStatus(true);

    const response = await fetchTeacherTrainingData(
      `/GetTeacherTrainingAttendanceStatus?TeacherTrainingScheduleId=${paramId?.id}&AttendanceDatetime=${date}`
    );
    if (response.isSuccess) {
      const resData = response?.data;
let filterKey = ["teacher_Id", "created_On", "training_Schedule_Teacher_Id"];
      const data = resData?.map((item: any) => {
        const filteredItem = {} as any;
        for (const key in item) {
          if (!filterKey.includes(key)) {
            filteredItem[key] = item[key];
          }
        }
        return filteredItem;
      });

      setIsLoadingAttendanceStatus(false);
      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.json_to_sheet(data);
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const blob = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "training_Status");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      setIsLoadingAttendanceStatus(false);
      showToast({
        type: "error",
        message: "Something went wrong!",
      });
    }
  };

 

  const getLastAttendanceDetails = async (trainingScheduleAttendanceId: number) => {
    setIsLoadingLastAttendanceDetails(true)
    setLastAttendanceDetails([])

    const res = await fetchTeacherTrainingData(`/BlockAdmin/GetTeachersSelectedForTeacherTraining/${user?.user_Id}/${paramId?.id}`)
    if (res.isSuccess) {
      setLastAttendanceDetails(res?.data)
    }
    else {
      showToast({
        type: "error",
        message: res?.message ?? "Error while getting last attendance detail"
      })
    }

    setIsLoadingLastAttendanceDetails(false)

  }

  const getTeacherListByScheduleID = async () => {
    setIsLoading(true)
    setTeacherList([])
    const res = await fetchTeacherTrainingData(`/BlockAdmin/GetTeachersSelectedForTeacherTraining/${user?.user_Id}/${paramId?.id}`)
    if (res.isSuccess) {
      setTeacherList(res?.data)
    }
    else {
      showToast({
        type: "error",
        message: res?.message ?? "Error while getting schedule"
      });
    }
    setIsLoading(false)
  }

  const postTeacherAttendance = async () => {

    setIsBtnLoading(true)
    if (teacherAttendanceList && paramId?.id) {
      let Payload: IPostTeacherAttendance = {
        teacher_Training_Schedule_Id: +paramId?.id,
        block_Admin_User_Id: user?.user_Id,
        teacherSelection:
          teacherAttendanceList?.map((e) => (
            {
              training_Schedule_Teacher_Id: e?.training_Schedule_Teacher_Id,
              is_Present: e?.is_Present
            }))
      }

      const res = await PostTeacherTrainingData(`/BlockAdmin/MarkTeacherAttendanceForScheduledTraining`, Payload)
      if (res.isSuccess) {
        showToast({
          type: "success",
          message: "Successfully updated attendance"
        })
        getMarkAttendanceList();
        setActiveTab("attendanceList")
      }
      else {
        showToast({
          type: "error",
          message: res?.message ?? "Error while marking attendance"
        })
      }
      setIsBtnLoading(false)
    }
  }

  const putLastTeacherAttendance =async (payload :IEditTeacherAttendance) =>{
    setIsLoadingLastAttendanceUploading(true)
    const res = await PutTeacherTrainingData(`/BlockAdmin/UpdateLatestTeacherAttendance` ,payload)
    if(res.isSuccess){
      showToast({
        type:"success",
        message:"Last attendance updated successfully"
      })
      getMarkAttendanceList();
      setActiveTab("attendanceList")
    }
    else{
      showToast({
        type:"error",
        message :res?.message ?? "Error while updating last attendance !"
      })
    }
    setIsLoadingLastAttendanceUploading(false)
  }

  return {
    teacherList,
    setTeacherList,
    isLoading,
    isNewAttendanceMarking,
    setIsNewAttendanceMarking,
    teacherAttendanceList,
    setTeacherAttendanceList,
    handleSubmitAttendance,
    handleSubmitLastAttendance,
    activeTab,
    setActiveTab,
    teacherMarkAttendanceList,
    isLoadingAttendanceList,
    lastAttendanceDetails,
    setLastAttendanceDetails,
    isLoadingLastAttendanceDetails,
    isLoadingLastAttendanceUploading,

    index,
    getTeacherTrainingAttendanceStatus,
    isLoadingAttendanceStatus,
    disableTab,
  };
}
