import React, { useContext, useEffect, useState } from 'react'
import { useStudentLearning } from '../../../../../libs/data-access';
import { calculateYearForDashboard, groupByKey, showToast } from '../../../../../utils/utility';
import { IORFResult, IReportLevel, ISpotAssessmentDownloadReport, ISpotAssessmentResultData } from '../../../../../libs/data-access/types';
import { AssessmentFilterContext } from '../../Components/Hooks/useAssessmentFilter';
import moment from 'moment';
import useMasterData from '../../../../Hooks/useMasterData';

let ResultData = [{}];

export default function useSpotAssessmentResult() {
  const { fetchSpotAssessment } = useStudentLearning();

  const {
    handleChangeFilters,
    handleIsSearching,
    isSearching,
    district,
    block,
    cluster,
    school,
    grade,
    subject,
    competency /* In the Competency some are negative value which is specified to orf such as -21 in this 2 is 2nd class and 1 is subject id in orf  */,
    month,
    activeTab,
    session,
    selectedSession,
  } = useContext(AssessmentFilterContext);

  const { blocks, getBlocksByDistrictId, getDistrictByStateId, districts } =
    useMasterData();

  const [isFirst, setIsFrist] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingDownloadReport, setIsLoadingDownloadReport] =
    useState<boolean>(false);
  const [keysForTableHeader, setKeysForTableHeader] = useState<any[]>([]);
  const [keysForCompetency, setKeysForCompetency] = useState<
    { header: any[]; subject: { label: string; count: number }[] } | undefined
  >(undefined);
  const [keysForOrf, setKeysForOrf] = useState<
    { header: any[]; subject: { label: string; count: number }[] } | undefined
  >(undefined);
  // const [spotAssessmentResultData, setSpotAssessmentResultData] = useState<ISpotAssessmentResultData>()     /* Spot assessment result ids for competency*/
  const [spotAssessmentResultData2, setSpotAssessmentResultData2] =
    useState<ISpotAssessmentResultData>(); /* Spot assessment result ids for ORF (for now its work only for 3rd class hindi orf) */
  const [reportLevel, setReportLevel] = useState<IReportLevel>({
    levelNames: "state",
    districtName: "haryana",
    districtId: 0,
    blockName: "",
  });
  // data for CSV download
  const [spotAssessmentResultCSV, setSpotAssessmentResultCSV] = useState<{
    headers: any[];
    content: any[];
  }>();
  // ORF report
  const [ORFReportData, setORFReportData] = useState<IORFResult[]>([]);
  const [ORFResultResultCSV, setORFResultResultCSV] = useState<{
    headers: any[];
    content: any[];
  }>();
  const [isORFDataLoading, setIsORFDataLoading] = useState<boolean>(false);

  const [spotAssessmentResultData, setSpotAssessmentResultData] = useState<any>(
    []
  );
  const [downloadCSVData, setDownloadCSVData] = useState<any>([]);

  const [totalOfData, setTotalOfData] = useState<any>({});

  // console.log(spotAssessmentResultData, "setSpotAssessmentResultData")
  // console.log(spotAssessmentResultData2, "setSpotAssessmentResultData2")

  useEffect(() => {
    if (isSearching && activeTab == "1") {
      getSpotAssessmentResultData();
      fetchORFReport();
      // getSpotAssessmentDownloadResult()
    } else {
      // console.log(isSearching);
    }
  }, [isSearching]);

  useEffect(() => {
    // console.log("active tab: ", activeTab);
    setSpotAssessmentResultData(undefined);
    setKeysForTableHeader([]);
    setKeysForCompetency(undefined);
    setSpotAssessmentResultCSV(undefined);
    setORFReportData([]);
    setORFResultResultCSV(undefined);
  }, [activeTab]);

  // HANDLER FUNCTIONS
  const formatSpotAssessmentResultReport = (
    resultData: ISpotAssessmentResultData,
    tableKeys: any[]
  ) => {
    const headers = [
      {
        label: tableKeys
          ? tableKeys.includes("District_Name")
            ? "District Name"
            : tableKeys.includes("Block_Name")
            ? "Block Name"
            : tableKeys.includes("Cluster_Name")
            ? "Cluster Name"
            : tableKeys.includes("School_Name")
            ? "School Name"
            : "Name"
          : "Name",
        key: "name",
      },
      { label: "Subject Name", key: "Subject_Name" },
      { label: "Month", key: "Month_Name" },
      { label: "Competency", key: "Competency" },
      { label: "Student Spot Tested", key: "Students_Spot_Tested" },
      { label: "Master Student", key: "Master_Students" },
      { label: "Master Status Percentage", key: "Mastery_Status_Percentage" },
    ];

    const data = Object.keys(resultData)
      .map((eachDistrict) => {
        return resultData[eachDistrict]
          ?.filter((e) => e?.type !== "orf")
          .map((each) =>
            Object.assign({
              name: tableKeys.includes("District_Name")
                ? each?.District_Name
                : tableKeys.includes("Block_Name")
                ? each?.Block_Name
                : tableKeys.includes("Cluster_Name")
                ? each?.Cluster_Name
                : tableKeys.includes("School_Name")
                ? each?.School_Name
                : "-",
              Subject_Name: each?.Subject_Name || "",
              Month_Name: each?.Month_Name || "",
              Competency: each?.Competency || "",
              Students_Spot_Tested: each?.Students_Spot_Tested || "",
              Master_Students: each?.Master_Students || "",
              Mastery_Status_Percentage: each?.Mastery_Status_Percentage || "",
            })
          );
      })
      .flat();

    setSpotAssessmentResultCSV({
      headers: headers,
      content: data,
    });
  };
  const formatAddHindiOrfSpotAssessmentResultReport = (
    resultData: ISpotAssessmentResultData,
    tableKeys: any[]
  ) => {
    const headers = [
      {
        label: tableKeys
          ? tableKeys.includes("District_Name")
            ? "District Name"
            : tableKeys.includes("Block_Name")
            ? "Block Name"
            : tableKeys.includes("Cluster_Name")
            ? "Cluster Name"
            : tableKeys.includes("School_Name")
            ? "School Name"
            : "Name"
          : "Name",
        key: "name",
      },
      { label: "Subject Name", key: "Subject_Name" },
      { label: "Month", key: "Month_Name" },
      { label: "Competency", key: "Competency" },
      { label: "Student Spot Tested", key: "Students_Spot_Tested" },
      { label: "Master Student", key: "Master_Students" },
      { label: "Master Status Percentage", key: "Mastery_Status_Percentage" },
    ];

    const data = Object.keys(resultData)
      .map((eachDistrict) => {
        return resultData[eachDistrict]
          ?.filter((e) => e?.type !== "orf")
          .map((each) =>
            Object.assign({
              name: tableKeys.includes("District_Name")
                ? each?.District_Name
                : tableKeys.includes("Block_Name")
                ? each?.Block_Name
                : tableKeys.includes("Cluster_Name")
                ? each?.Cluster_Name
                : tableKeys.includes("School_Name")
                ? each?.School_Name
                : "-",
              Subject_Name: each?.Subject_Name || "",
              Month_Name: each?.Month_Name || "",
              Competency: each?.Competency || "",
              Students_Spot_Tested: each?.Students_Spot_Tested || "",
              Master_Students: each?.Master_Students || "",
              Mastery_Status_Percentage: each?.Mastery_Status_Percentage || "",
            })
          );
      })
      .flat();
    setSpotAssessmentResultCSV({
      headers: [...(spotAssessmentResultCSV?.headers || []), ...headers],
      content: [...(spotAssessmentResultCSV?.content || []), ...data],
    });
  };

  const formatORFResultReportData = (data: IORFResult[]) => {
    let headers = Object.keys(data[0]).map((eachKey) => ({
      label: eachKey.replaceAll("_", " "),
      key: eachKey,
    }));

    let values = data;

    setORFResultResultCSV({
      headers: headers,
      content: values,
    });
  };

  //  API FUNCTIONS
  const getSpotAssessmentResultData = async () => {
    setIsLoading(true);

    ///for session////

    // const res = await fetchSpotAssessment(
    //   `Result?SubjectId=${[subject]}&CompetencyId=${competency?.filter(
    //     (e) => e > 0
    //   )}&DistrictId=${district ?? 0}&BlockId=${block ?? 0}&ClusterId=${
    //     cluster ?? 0
    //   }&SchoolId=${school ?? 0}&ClassId=${grade ?? 0}&Date=${`${
    //     month ? calculateYearForDashboard(+month, Number(selectedSession)) : moment().format('YYYY')
    //   }-${month}-01`}&Session_Id=${session ?? 0}`
    // );

    ///for session////

    // const res = await fetchSpotAssessment(
    //   `Result?SubjectId=${[subject]}&CompetencyId=${competency?.filter(
    //     (e) => e > 0
    //   )}&DistrictId=${district ?? 0}&BlockId=${block ?? 0}&ClusterId=${
    //     cluster ?? 0
    //   }&SchoolId=${school ?? 0}&ClassId=${grade ?? 0}&Date=${`${
    //     month ? calculateYearForDashboard(+month, Number(selectedSession)) : moment().format('YYYY')
    //   }-${month}-01`}`
    // );

    let hasNegative = false;
    let newCompetency = competency.filter((item) => {
      if (item < 0) {
        hasNegative = true;
        return false; // Remove negative values
      }
      return true; // Keep positive values
    });

    if (hasNegative) {
      newCompetency.push(-1); // Add -1 if there were no negative values
    }
    const res = await fetchSpotAssessment(
      `ResultNew?SubjectId=${[
        subject,
      ]}&CompetencyId=${newCompetency}&DistrictId=${district ?? 0}&BlockId=${
        block ?? 0
      }&ClusterId=${cluster ?? 0}&SchoolId=${school ?? 0}&ClassId=${
        grade ?? 0
      }&GetMonthYearFromDate=${`${
        month
          ? calculateYearForDashboard(+month, Number(selectedSession))
          : moment().format("YYYY")
      }-${month}-01`}`
    );

    // console.log("testt", res);

    if (res.isSuccess) {
      handleIsSearching(false);
      getDistrictByStateId(1).then((result) => {
        setReportLevel({
          levelNames: cluster
            ? "cluster"
            : block
            ? "block"
            : district
            ? "district"
            : "state",
          districtName:
            districts && district
              ? districts.filter((e) => e?.id == +district)[0]?.name
              : "Haryana",
          districtId: district ? +district : 0,
          blockName:
            blocks && block
              ? blocks.filter((e) => e?.id == +block)[0]?.name
              : "",
        });
        district &&
          getBlocksByDistrictId(district).then((res) => {
            setReportLevel((prev) => ({
              ...prev,
              blockName:
                blocks && block
                  ? blocks.filter((e) => e?.id == +block)[0]?.name
                  : "",
            }));
          });

        let resp = res?.data;
        // for (const districtKey in resp) {
        //   if (resp.hasOwnProperty(districtKey)) {
        //     resp[districtKey].sort((a: any, b: any) =>
        //       a.Subject_Name.localeCompare(b.Subject_Name)
        //     );
        //   }
        // }

        /////////structured and aligned data ////////////////

        // Find the subject with the maximum data length for each unique subject
        function findMaxDataForSubject(data: any, subjectName: any) {
          let maxSubject = null;
          let maxDataLength = 0;

          data.forEach((district: any) => {
            district.subjects.forEach((subject: any) => {
              if (
                subject.subject === subjectName &&
                subject.data.length > maxDataLength
              ) {
                maxDataLength = subject.data.length;
                maxSubject = subject;
              }
            });
          });

          return maxSubject;
        }

        // Create a template for a given subject based on its maximum data
        function createSubjectTemplate(subjectName: any, maxData: any) {
          return maxData.data.map((entry: any) => ({
            Text: entry.Text,
            type: entry.type || null,
            AvgPercentage: null,
            Master_Student: null,
            Tested: null,
          }));
        }

        // Align a specific subject's data based on the template from the max data object
        function alignSubjectData(subject: any, maxData: any) {
          const subjectName = subject.subject;

          const existingDataMap = subject.data.reduce((acc: any, item: any) => {
            acc[item.Text] = item;
            return acc;
          }, {});

          const subjectTemplate = createSubjectTemplate(subjectName, maxData);

          return {
            subject: subjectName,
            data: subjectTemplate.map((templateItem: any) => {
              return existingDataMap[templateItem.Text] || templateItem;
            }),
          };
        }

        // Align data across all subjects based on each unique subject's template
        function alignAllData(data: any) {
          const subjectsSet = new Set();
          data.forEach((district: any) => {
            district.subjects.forEach((subject: any) => {
              subjectsSet.add(subject.subject);
            });
          });

          const subjectNames = Array.from(subjectsSet);

          return data.map((district: any) => {
            const alignedSubjects = subjectNames.map((subjectName) => {
              const existingSubject = district.subjects.find(
                (s: any) => s.subject === subjectName
              );
              const maxSubject = findMaxDataForSubject(data, subjectName);

              return existingSubject
                ? alignSubjectData(existingSubject, maxSubject)
                : {
                    subject: subjectName,
                    data: createSubjectTemplate(subjectName, maxSubject),
                  };
            });

            return {
              name: district.name,
              OverAllAvg: district.OverAllAvg,
              subjects: alignedSubjects,
            };
          });
        }

        // Align the data for each unique subject
        const alignedData = alignAllData(resp); // Assume 'resp' is the original data

        /////////structured and aligned data ////////////////

        let rowDataArray: any[] = [];

        // Map over alignedData to extract desired fields
        let downloadData = alignedData.map((district: any) => {
          district.subjects?.forEach((subject: any) => {
            subject.data?.forEach((nestedItem: any) => {
              // Reset rowData for each new item to avoid carrying over old data
              const rowData = {
                District: district?.name,
                OverAllAvg: district?.OverAllAvg,
                Subject: subject?.subject,
                "competency/ORF":
                  nestedItem?.type === "compentancy"
                    ? nestedItem?.Text
                    : `${nestedItem?.Text} (ORF)`,
                "Average Percentage": nestedItem?.AvgPercentage,
                "Master Student": nestedItem?.Master_Student,
                Tested: nestedItem?.Tested,
              };

              // Add the constructed row data to the array
              rowDataArray.push(rowData);
            });
          });
        });

        // Log the final data to ensure accuracy
        // console.log("rowDataArray", rowDataArray);
        setDownloadCSVData(rowDataArray);

        // console.log("Aligned Data:", alignedData);

        // let total = {
        //   name: "Total",
        //   OverAllAvg: alignedData.reduce(
        //     (sum: any, item: any) => sum + item.AvgPercentage,
        //     0
        //   ),
        //   subjects: alignedData[0].subjects.map((item:any)=>{
        //      return {
        //        ...item,
        //        data: alignedData.map((dataset:any) => {
        //          dataset.subjects.forEach((subject: any) => {
        //            if (subject.subject === item.subject) {
        //              subject.data.forEach((child: any) => {
        //                return alignedData.reduce(
        //                  (total: any, insideChild: any) => {
        //                    insideChild.subjects.map((insideData: any) => {
        //                      if (insideData.subject === item.subject) {
        //                        insideData.data.map((check: any) => {
        //                          if (check.text == child.text) {
        //                            total += child.AvgPercentage || 0;
        //                          }
        //                        });
        //                      }
        //                    });
        //                    return total;
        //                  },
        //                  0
        //                );
        //              });
        //              // subject.data.forEach((child: any) => {
        //              //   total += child.AvgPercentage || 0;
        //              // });
        //            }
        //          });

        //        }),
        //      };
        //   }),
        // };
        let total = {
          name: "Total",
          OverAllAvg: (
            alignedData?.reduce(
              (sum: any, item: any) => sum + item?.OverAllAvg,
              0
            ) / alignedData?.length
          ).toFixed(2),
          subjects: alignedData[0]?.subjects.map((subject: any) => {
            return {
              ...subject,
              data: subject?.data.map((item: any) => {
                let totalAvg = alignedData?.reduce(
                  (total: any, dataset: any) => {
                    let matchingSubject = dataset?.subjects?.find(
                      (subj: any) => subj?.subject === subject?.subject
                    );
                    if (matchingSubject) {
                      let matchingItem = matchingSubject?.data.find(
                        (dataItem: any) => dataItem?.Text === item?.Text
                      );
                      if (matchingItem) {
                        total += matchingItem?.AvgPercentage || 0;
                      }
                    }
                    return total;
                  },
                  0
                );
                return totalAvg;
              }),
            };
          }),
        };

        // alignedData.push(total)
        setTotalOfData(total);
        // console.log("total", total);
        setSpotAssessmentResultData(alignedData);

        // const KEYS = Object.keys(res?.data?.[Object.keys(res?.data)[0]][0]);
        // setKeysForTableHeader(KEYS);

        // formatSpotAssessmentResultReport(resp, KEYS);

        //   const CompetencyKEYS = resp?.[Object.keys(resp)[0]]?.map(
        //     (each: any, index: number) =>
        //       each?.type === "orf"
        //         ? `ORF - ${each?.Subject_Name}`
        //         : each?.Competency
        //   );
        //   const SubjectKEYS = resp?.[Object.keys(resp)[0]]
        //     ?.filter(
        //       (each: any, index: number) =>
        //         each?.Subject_Name !==
        //         resp?.[Object.keys(resp)[0]][index - 1]?.Subject_Name
        //     )
        //     .map((e: any) => ({
        //       label: e?.Subject_Name,
        //       count: resp?.[Object.keys(resp)[0]]?.filter(
        //         (eSub: any) => eSub?.Subject_Name === e?.Subject_Name
        //       ).length,
        //     }));
        //   setKeysForCompetency({
        //     header: CompetencyKEYS,
        //     subject: SubjectKEYS,
        //   });
      });
    } else {
      if (!competency.find((e) => e < 0)) {
        showToast({
          type: "error",
          message: "Competency result not found",
        });
      }
      setSpotAssessmentResultData(undefined);
      setKeysForTableHeader([]);
      setKeysForCompetency(undefined);
    }
    setIsLoading(false);
    handleIsSearching(false);
    // if (competency.find((e) => e < 0)) {
    //   getHindiOrfForSpotAssessment();
    // } else {
    //   setSpotAssessmentResultData2(undefined);
    //   setKeysForOrf({
    //     header: [],
    //     subject: [],
    //   });
    // }
  };

  const getHindiOrfForSpotAssessment = async () => {
    setIsLoading(true);
    const orfRes = await fetchSpotAssessment(
      `Result?SubjectId=${[subject]}&CompetencyId=${competency}&DistrictId=${
        district ?? 0
      }&BlockId=${block ?? 0}&ClusterId=${cluster ?? 0}&SchoolId=${
        school ?? 0
      }&ClassId=${grade ?? 0}&Date=${`${
        month
          ? calculateYearForDashboard(+month, Number(selectedSession))
          : moment().format("YYYY")
      }-${month}-01`}&d=1`
    );
    if (orfRes.isSuccess) {
      if (orfRes?.data) {
        handleIsSearching(false);
        getDistrictByStateId(1).then((result) => {
          let resp = orfRes?.data;
          for (const districtKey in resp) {
            if (resp.hasOwnProperty(districtKey)) {
              resp[districtKey].sort((a: any, b: any) =>
                a.Subject_Name.localeCompare(b.Subject_Name)
              );
            }
          }
          setSpotAssessmentResultData2(resp);

          const KEYS = Object.keys(
            orfRes?.data?.[Object.keys(orfRes?.data)[0]][0]
          );
          setKeysForTableHeader((prev) => [...prev, ...KEYS]);

          formatAddHindiOrfSpotAssessmentResultReport(resp, KEYS);

          const CompetencyKEYS = resp?.[Object.keys(resp)[0]]?.map(
            (each: any, index: number) =>
              each?.type === "orf"
                ? `ORF - ${each?.Subject_Name}`
                : each?.Competency
          );
          const SubjectKEYS = resp?.[Object.keys(resp)[0]]
            ?.filter(
              (each: any, index: number) =>
                each?.Subject_Name !==
                resp?.[Object.keys(resp)[0]][index - 1]?.Subject_Name
            )
            .map((e: any) => ({
              label: e?.Subject_Name,
              count: resp?.[Object.keys(resp)[0]]?.filter(
                (eSub: any) => eSub?.Subject_Name === e?.Subject_Name
              ).length,
            }));
          setKeysForOrf({
            header: CompetencyKEYS,
            subject: SubjectKEYS,
          });
        });
      }
    } else {
      showToast({
        type: "error",
        message: "Orf result not found",
      });
    }
    setIsLoading(false);
    handleIsSearching(false);
    setKeysForOrf({
      header: [],
      subject: [],
    });
    setSpotAssessmentResultData2(undefined);
  };

  const fetchORFReport = async () => {
    setIsORFDataLoading(true);
    const response = await fetchSpotAssessment(
      `GetSpotOrfReportForDashBoard?&DistrictId=${district ?? 0}&BlockId=${
        block ?? 0
      }&ClusterId=${cluster ?? 0}&SchoolId=${
        school ?? 0
      }&GetMonthYearFromDate=${`${
        month
          ? calculateYearForDashboard(+month, Number(selectedSession))
          : moment().format("YYYY")
      }/${month}/01`}`
    );
    if (response.isSuccess) {
      // console.log("Response for ORF result: ", response.data);
      setORFReportData(response.data);
      formatORFResultReportData(response.data);
    } else {
      console.log("Error while fetching ORF report: ", response.error);
      showToast({
        type: "error",
        message: response?.message || "Error while getting ORF result",
      });
    }
    setIsORFDataLoading(false);
  };

  // const getSpotAssessmentDownloadResult = async () => {
  //   setIsLoadingDownloadReport(true)
  //   setSpotAssessmentResultCSV(undefined)
  //   const res = await fetchSpotAssessment(
  //     `Result/Download?SubjectId=${subject}&CompetencyId=${competency}&Date=${`${month ? calculateYearForDashboard(+month, Number(selectedSession)):moment().format('YYYY')}/${month}/01`}`
  //   )
  //   if (res?.isSuccess) {
  //     formatSpotAssessmentResultReport(res?.data)
  //   }
  //   else {
  //     showToast({
  //       type: "error",
  //       message: res?.message || "Error while getting spot assessment Excel"
  //     })
  //   }
  //   setIsLoadingDownloadReport(false)
  // }

  // console.log("downloadCSVData", downloadCSVData);
  return {
    isLoading,
    reportLevel,
    spotAssessmentResultData,
    spotAssessmentResultData2,
    spotAssessmentResultCSV,
    keysForTableHeader,
    keysForCompetency,
    keysForOrf,
    isLoadingDownloadReport,
    isORFDataLoading,
    ORFReportData,
    ORFResultResultCSV,
    grade,

    downloadCSVData,
    totalOfData,
  };
}
