import Chart from "react-apexcharts";
import { Button, Card, Skeleton } from '@mantine/core';

import { ECard } from '../../../../libs/ui/src/Card/ECard';

import useClassroomLearningIndicator from './hook/useClassroomLearningIndicator';
import "./CustomApex.scss"

export default function ClassroomLearningIndicator() {

  const {
    isLoadingLearningIndicator,
    activeButtonValue,
    setActiveButtonValue,
    learningIndicator,
    noDataOption
  } = useClassroomLearningIndicator();

  // console.log("learning Indicator",learningIndicator)

  const generateColorPalette = (length:any) => {
    // const baseColors = ["#FF0000", "#FFAC27", "#FFFF1B", "#7CFF77", "#00EE33"];
    const baseColors = [
      "#FF0000", // Red
      "#FF6A00", // Orange
      "#FFAC27", // Light orange
      "#FFFF1B", // Yellow
      "#7CFF77", // Light green
      "#00FF00", // Green
      "#00AC27", // Darker green
    ];

    // If there's only one data point, it should be red
    if (length === 1) {
      return ["#FF0000"];
    }

    // Start with red and end with green
    const startColor = baseColors[0];
    const endColor = baseColors[baseColors.length - 1];

    // Create a color palette by interpolating between given colors
    const palette = baseColors.splice(1, length - 2);

    // Extend palette to desired length and ensure the first and last colors
    const colors = [startColor, ...palette, endColor]

   
    return colors
  };

  // Generate a color palette based on the number of items in your dataset
  // const dataLength = learningIndicator[eachQuestion]?.length;
  // const colors = generateColorPalette(dataLength);

  return (
    <ECard
      title="Classroom Teaching Learning Indicators"
      header={
        <div className="flex gap-2">
          <Button
            variant={activeButtonValue === "hindi" ? "outline" : "default"}
            onClick={() => setActiveButtonValue("hindi")}
            disabled={isLoadingLearningIndicator}
          >
            Hindi
          </Button>
          <Button
            variant={activeButtonValue === "math" ? "outline" : "default"}
            onClick={() => setActiveButtonValue("math")}
            disabled={isLoadingLearningIndicator}
          >
            Math
          </Button>
        </div>
      }
    >
      <div className="flex gap-5 w-full mt-4">
        {isLoadingLearningIndicator ? (
          <div className="flex justify-around gap-2 w-full">
            <Skeleton height={210} />
            <Skeleton height={210} />
            <Skeleton height={210} />
            <Skeleton height={210} />
            <Skeleton height={210} />
          </div>
        ) : !learningIndicator ? (
          <div className="flex justify-around gap-2 w-full">
            {[1, 2, 3, 4].map((each, index) => (
              <div className={`w-1/4 ${index !== 3 ? `border-r` : ``}`}>
                <Chart options={noDataOption} series={[1]} type="donut" />
              </div>
            ))}
          </div>
        ) : (
          Object.keys(learningIndicator)?.map((eachQuestion, index) => (
            <div
              className={` flex flex-col justify-between w-1/4 pr-3 ${
                index !== Object.keys(learningIndicator)?.length - 1
                  ? `border-r`
                  : ``
              } `}
            >
              <Chart
                className="pt-10 "
                options={{
                  labels: learningIndicator[eachQuestion]?.map(
                    (e) => e?.ColName
                  ),
                  // colors: ['#FF0000', '#FF0000', '#FF0000', '#FF0000', '#FFAC27', '#FFFF1B', '#7CFF77', '#00EE33'].splice(8 - learningIndicator[eachQuestion]?.length, 7),
                  colors: generateColorPalette(
                    learningIndicator[eachQuestion]?.length
                  ),
                  dataLabels: {
                    enabled: false,
                  },
                  tooltip: {
                    enabled: true,
                    cssClass: "p-3",
                    custom: function ({
                      series,
                      seriesIndex,
                      dataPointIndex,
                      w,
                    }) {
                      return (
                        "<div>" +
                        '<h4 class="text-center">' +
                        series[seriesIndex] +
                        "%" +
                        "</h4>" +
                        "<p>" +
                        w.config.labels[seriesIndex].slice(3) +
                        "</p>" +
                        // '<p>' + w.config.labels[seriesIndex].slice(50, w.config.labels[seriesIndex]?.length) +
                        // '<span>' + '</p>' +
                        "</div>"
                      );
                    },
                    style: {
                      fontSize: "22",
                    },
                  },
                  legend: {
                    show: false,
                  },
                  responsive: [
                    {
                      breakpoint: 480,
                      options: {
                        chart: {
                          width: 200,
                        },
                        legend: {
                          show: true,
                          position: "bottom",
                        },
                      },
                    },
                  ],
                  plotOptions: {
                    pie: {
                      startAngle: -90,
                      endAngle: 90,
                    },
                  },
                }}
                series={learningIndicator[eachQuestion]?.map((e) => e?.Value)}
                type="donut"
              />
              <Card className="bg-slate-100 w-full text-center">
                <span>{eachQuestion.split("(")[0]}</span>
              </Card>
            </div>
          ))
        )}
      </div>
    </ECard>
  );
}
