import { useEffect, useState } from "react";
import { useDisclosure } from "@mantine/hooks";

import { showToast } from "../../../../utils/utility";
import { IPayloadForSessionWorkingDays } from "../../../../types";
import { useWorkingDaysData } from "../../../../libs/data-access";
import { ISessionWiseWorkingDays } from "../../../../libs/data-access/types";
import { sessionWorkingDaysPayloadInitialValue } from "../../../../initialStates";

export default function useWorkingDays() {

    const {
        fetchWorkingDaysData,
        postWorkingDaysData,
        putWorkingDaysData
    } = useWorkingDaysData();

    const [opened, { open, close }] = useDisclosure(false);

    const [availableSessions, setAvailableSessions] = useState<ISessionWiseWorkingDays[]>([]);
    const [sessionWorkingDaysPayload, setSessionWorkingDaysPayload] = useState<IPayloadForSessionWorkingDays>(sessionWorkingDaysPayloadInitialValue);
    const [hasConfirmed, setHasConfirmed] = useState<boolean>(false);
    const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true);
    const [isLoading, setIsLoading] = useState<boolean>(false);


    useEffect(() => {

        if (isFirstLoad) {
            setIsFirstLoad(false);
        } else {
            fetchSessionWorkingDays();
        };

    }, [isFirstLoad]);


    // HANDLER FUNCTIONS
    const handleUpdateSessionWorkingDaysPayload = (
        key: keyof IPayloadForSessionWorkingDays,
        value: number | string
    ) => {

        setSessionWorkingDaysPayload(prev => ({ ...prev, [key]: value }));

    };

    const handleSubmit = () => {

        if (!sessionWorkingDaysPayload.Session_Id) {

            showToast({
                type: "warning",
                title: "Session Missing",
                message: "Session could not be found!"
            });
            return;

        };

        if (!sessionWorkingDaysPayload.NumberOfWorkingDay) {

            showToast({
                type: "warning",
                title: "No. of Working Days Missing",
                message: "No. of working days could not be found!"
            });
            return;

        };

        updateSessionWorkingDays();

    };

    // API REQUESTS
    const fetchSessionWorkingDays = async () => {

        setIsLoading(true);
        const response = await fetchWorkingDaysData(`/GetSession`);

        if (response.isSuccess) {

            // console.log("Response for available working days: ", response.data);
            setAvailableSessions(response.data);

        } else {

            // console.log("Error while fetching available working days: ", response.error);
            showToast({
                type: "error",
                message: response.message ?? "Failed to fetch session working days!"
            });

        };
        setIsLoading(false);

    };

    const updateSessionWorkingDays = async () => {

        const payload = {
            Session_Id: sessionWorkingDaysPayload.Session_Id,
            NumberOfWorkingDay: sessionWorkingDaysPayload.NumberOfWorkingDay
        };

        setIsLoading(true);
        const response = await putWorkingDaysData(`/UpdateSession`, payload);
        if (response.isSuccess) {

            // console.log("Response for updating session working days: ", response.data);
            fetchSessionWorkingDays();
            close();
            setHasConfirmed(false);

        } else {

            // console.log("Error while updating available working days: ", response.error);
            showToast({
                type: "error",
                message: response.message ?? "Failed to update session working days!"
            });

        };
        setIsLoading(false);

    };

    return {
        opened,
        open,
        close,
        isLoading,
        hasConfirmed,
        setHasConfirmed,
        availableSessions,
        sessionWorkingDaysPayload,
        handleUpdateSessionWorkingDaysPayload,
        handleSubmit
    };

};
