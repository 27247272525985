import React, { useEffect } from 'react'

import { createContext, useState, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { calculateDefaultSession, showToast } from '../../../../../utils/utility';
import { IClassroomObservationByMentorReport, IMasterData, IVisitCompliance } from '../../../../../libs/data-access/types';
import moment from 'moment';
import useMasterData from '../../../../Hooks/useMasterData';

interface IFilter {
  district: number | undefined;
  block: number | undefined;
  cluster: number | undefined;
  school: number | undefined;
  month: string | undefined;
  subject: any[];
  competency: any[];
  grade: number | undefined;
  session: number | null;
  selectedSession: number | null | string
};

const initialFilterValues = {
  district: undefined,
  block: undefined,
  cluster: undefined,
  school: undefined,
  grade: 1,
  subject: [2],
  competency: [],
  month: moment().format("MM"),
  session: 1,
  selectedSession: moment().add(1, "year").format("YYYY"),
};

interface IFilterContext extends IFilter {
  isSpotResultLoading: boolean;
  isSearching: boolean;
  activeTab: string | null;
  handleIsSearching: (e: boolean) => void;
  handleChangeFilters: (
    key: keyof IFilterContext,
    value: number | string | boolean | any[] | null | undefined
  ) => void;
};

const initialFilterContextValues: IFilterContext = {
  district: undefined,
  block: undefined,
  cluster: undefined,
  school: undefined,
  grade: 1,
  subject: [2],
  competency: [],
  month: moment().format("MM"),

  handleIsSearching: () => {},
  handleChangeFilters: () => {},
  isSpotResultLoading: false,
  isSearching: false,
  activeTab: "1",
  session:1,
  selectedSession: moment().format("YYYY")
};

  

export const AssessmentFilterContext = createContext<IFilterContext>(initialFilterContextValues);

export default function useAssessmentFilter() {

const { getSession, sessions } = useMasterData();

    const [filters, setFilters] = useState<IFilter>(initialFilterValues);
    const [activeTab, setActiveTab] = useState<string | null>('1');
    const [isSearching, setIsSearching] = useState<boolean>(false)
    const [isSpotResultLoading, setIsSpotResultLoading] = useState<boolean>(false);

    // HANDLER FUNCTIONS'

    // console.log("filter",filters)

    const handleChangeFilters = (
        key: keyof IFilterContext,
        value: number | string | boolean | any[] | null | undefined
    ) => {
        setFilters(prev => ({ ...prev, [key]: value }));
    };

    const handleSearch = () => {
        // console.log("handle search clicked")
    }
    const handleIsSearching = (e: boolean) => {
        setIsSearching(e)
    }
    const handleResetFilters = () => {
        setFilters(initialFilterValues);
        setIsSearching(false);

    };


    useEffect(() => {
      if (sessions?.length > 0) {
        setFilters((prev: any) => ({
          ...prev,
          session: String(
            sessions?.find(
              (option) =>
                option?.session_Name ==
                calculateDefaultSession(parseInt(moment().format("M")))
            )?.session_Id
          ),
        }));
      }
    }, [sessions]);

    useEffect(() => {
     getSession(false);
    }, [])
    

    return {
      filters,
      handleChangeFilters,
      handleSearch,
      handleIsSearching,
      isSearching,
      setIsSearching,
      isSpotResultLoading,
      handleResetFilters,
      activeTab,
      setActiveTab,
    };
};

