import React, { useState } from "react";
import PageLayout from "../../../../PageLayout";
import { EPageTitle } from "../../../../libs/ui";
import { Button, Tabs } from "@mantine/core";
import SpotAssessmentResult from "./Section/SpotAssessmentResult";
import useAssessmentFilter, {
  AssessmentFilterContext,
} from "../Components/Hooks/useAssessmentFilter";
import AssessmentFilter from "../Components/AssessmentFilter";
import SpotAssessmentsCompleted from "./Section/SpotAssessmentsCompleted";
import UniqueStudentSpotTested from "./Section/UniqueStudentSpotTested";
import { EPlaceholder } from "../../../../libs/ui/src/Placeholder/EPlaceholder";
import SpotCompetencyWise from "./Section/SpotCompetencyWise";

export default function SpotAssessment() {
  const {
    filters,
    handleChangeFilters,
    handleIsSearching,
    isSearching,
    handleResetFilters,
    isSpotResultLoading,
    activeTab,
    setActiveTab,
  } = useAssessmentFilter();

  return (
    <div>
      <PageLayout>
        <EPageTitle title="Spot Assessment" />
        <AssessmentFilterContext.Provider
          value={{
            ...filters,
            handleChangeFilters,
            handleIsSearching,
            isSearching,
            isSpotResultLoading,
            activeTab,
          }}
        >
          <AssessmentFilter
            handleFetchReports={() => console.log("HandleFetchReports")}
            handleResetFilters={handleResetFilters}
            activeTab={activeTab ? +activeTab : 1}
          />
          <Tabs
            value={activeTab}
            onTabChange={setActiveTab}
            defaultValue={"1"}
            className="mt-3"
          >
            <Tabs.List>
              <Tabs.Tab value="1">Results </Tabs.Tab>
              <Tabs.Tab value="2">Spot assessments completed</Tabs.Tab>
              <Tabs.Tab value="3">Unique students spot tested</Tabs.Tab>
              <Tabs.Tab value="4">School Level Competency Wise Report</Tabs.Tab>
            </Tabs.List>
            <Tabs.Panel value="1" p="xs">
              <SpotAssessmentResult />
            </Tabs.Panel>
            <Tabs.Panel value="2" p="xs">
              <SpotAssessmentsCompleted />
            </Tabs.Panel>
            <Tabs.Panel value="3" p="xs">
              <UniqueStudentSpotTested />
            </Tabs.Panel>
            <Tabs.Panel value="4" p="xs">
              <SpotCompetencyWise />
            </Tabs.Panel>
          </Tabs>
        </AssessmentFilterContext.Provider>

        {/* <EPlaceholder
          iconSrc={require("../../../../assets/icons/icon-placeholder.svg").default}
          title="Create Report"
          description="Please select a month and other filters and press search to generate a report."
        /> */}
      </PageLayout>
    </div>
  );
}
