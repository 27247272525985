import { useState, useEffect, useContext } from "react";
import {
  IEditQuestionDetails,
  IQuestionFilters,
  ISelectedQuestionDetails,
  ISpotQuestionBankQuestionList,
  TQuestionFilters,
} from "../../../../types";
import { useDashboardDate, useStateAdmin } from "../../../../libs/data-access";
import useMasterData from "../../../Hooks/useMasterData";
import { IMediaType } from "../../../../libs/data-access/types";
import { questionFiltersParams } from "../../../../initialStates";
import { showToast } from "../../../../utils/utility";
import { AppContext } from "../../../../AppContext";
import { handleValidateEditQuestionParams, handleValidateGroup } from "../../../../utils/validators";
import { BASE_URL } from "../../../../libs/data-access/constant";
import moment from "moment";

const useQuestionGroup = () => {
  const MEDIA_TYPE_QUESTION = 0;
  const MEDIA_TYPE_OPTION = 1;

  const { fetchStateAdmin } = useStateAdmin();
  const {
    assessmentTypes,
    getAssessmentTypes,
    subjectList,
    getSubject,
    classes,
    getClasses,
    competencyList,
    getCompetency,
    getCompetencyBasedOnClassAndSubject,
    competencyByClassSubject,
    questionType,
    GetQuestionType,
    spotAssessmentData,
    getWeeksData,
  } = useMasterData();

  const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true);
  
  const [questionFilters, setQuestionFilters] = useState<any>({
    selectedGrade: "",
    selectedSubject: "",
    WeekStartDate: undefined,
    WeekEndDate: undefined,
  });



  useEffect(() => {
    if (isFirstLoad) {
      setIsFirstLoad(false);
    } else {
      getSubject();
      getClasses();
      GetQuestionType();
      getAssessmentTypes();
    }
  }, [isFirstLoad]);

 

  //    useEffect(() => {
  //      if (questionType.length) {
  //        const singleOptionID = questionType.find(
  //          (each) => String(each.id).trim() === "2"
  //        )?.id;
  //        handleChangeGroupFilterParams(
  //          "questionType",
  //          singleOptionID ? String(singleOptionID) : undefined
  //        );
  //      }
  //    }, [questionType]);




//   useEffect(() => {
//     if (
//       questionFilters.selectedSubject &&
//       questionFilters?.selectedGrade &&
//       questionFilters?.WeekStartDate &&
//       questionFilters?.WeekEndDate
//     ) {
//       GetSpotQuestionGroup(
//         questionFilters.selectedSubject,
//         questionFilters?.selectedGrade,
//         questionFilters?.WeekStartDate,
//         questionFilters?.WeekEndDate
//       );
//     }
//   }, [
//     questionFilters.selectedSubject,
//     questionFilters?.selectedGrade,
//     questionFilters?.WeekStartDate,
//     questionFilters?.WeekEndDate,
//   ]);




  //  useEffect(() => {
  //    // console.log("Question FIlters: ", questionFilters);
  //    handleGetSelectedParameters(questionFilters);
  //  }, [questionFilters]);

  // for question filter management
  const handleChangeGroupFilterParams = (
    key: any,
    value: string | undefined
  ) => {
    setQuestionFilters((prev: any) => ({ ...prev, [key]: value }));
  };



  /* API CALLS */


  ///////index//////////////

  const { deleteStateAdmin, putStateAdmin } = useStateAdmin();

  const { user } = useContext(AppContext);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isBtnLoading, setIsBtnLoading] = useState<boolean>(false);
  const [isOpenEditModal, setIsOpenEditModal] = useState<boolean>(false);
  const [selectedQuestion, setSelectedQuestion] = useState<any>(undefined);

// console.log("selected Question",selectedQuestion)

  const [allGroups, setAllGroups] = useState([]);



  const handleSearch = () => {
    GetSpotQuestionGroup();
  };

  const handleEditQuestion = () => {
    if (!user.user_Id) {
      showToast({
        type: "warning",
        title: "Please login again",
        message: "User could not be found",
      });
      return;
    }
    if (selectedQuestion) {
      
let newWeekStart: any = new Date(
  selectedQuestion?.week_Start_Date
);
newWeekStart.setDate(newWeekStart.getDate() + 1);

 let newWeekEnd: any = new Date(selectedQuestion?.week_End_Date);
 newWeekEnd.setDate(newWeekEnd.getDate() + 1);

//  console.log("newWeekStart", newWeekStart);

      let ModifiedselectedQuestion = {
        ...selectedQuestion,
        min_Attempt_Question: Number(selectedQuestion?.min_Attempt_Question),
        week_Start_Date: newWeekStart?.toISOString(),
        week_End_Date: newWeekEnd?.toISOString(),
      };
      const isValidated = handleValidateGroup(ModifiedselectedQuestion);
      if (isValidated) {
        // console.log("check2");
        updateQuestionDetails(ModifiedselectedQuestion);
      }
    }
  };



//   const GetSpotQuestionsByQuestionGroupId = async () => {
//     setIsLoading(true);
//     const res = await fetchStateAdmin(
//       `Question/GetSpotAssessmentQuestionsByGroupId/${selectedGroup}`
//     );
//     if (res.isSuccess) {
//       setQuestionList(res?.data);
//     } else {
//       setQuestionList([]);
//       showToast({
//         type: "error",
//         message: res?.message || "Fail to get question list",
//       });
//     }
//     setIsLoading(false);
//   };

  const GetSpotQuestionGroup = async () => {
      // let newWeekStart: any = new Date(questionFilters?.WeekStartDate);
      // newWeekStart.setDate(newWeekStart.getDate() + 1);

      //  let newWeekEnd: any = new Date(questionFilters?.WeekEndDate);
      //  newWeekEnd.setDate(newWeekEnd.getDate() + 1);

    setIsLoading(true);
    const res = await fetchStateAdmin(
      `Question/GetSpotAssessmentQuestionGroups/${
        questionFilters?.selectedGrade
      }/${questionFilters.selectedSubject}/${String(
        moment(questionFilters?.WeekStartDate).format("YYYY-MM-DD")
      )}/${String(moment(questionFilters?.WeekEndDate).format("YYYY-MM-DD"))}`
    );
    if (res.isSuccess) {
      setAllGroups(res?.data);
    } else {
      setAllGroups([]);
      showToast({
        type: "error",
        message: res?.message || "Fail to get question group",
      });
    }
    setIsLoading(false);
  };



  const updateQuestionDetails = async (payload: any) => {
    setIsBtnLoading(true);
    const res = await putStateAdmin(
      `Question/UpdateSpotAssessmentQuestionGroup`,
      payload
    );
    if (res.isSuccess) {
      showToast({
        type: "success",
        title: "Updated successfully",
        message: "Group updated",
      });
      setIsOpenEditModal(false);
      setSelectedQuestion(undefined);
      GetSpotQuestionGroup();
    } else {
      showToast({
        type: "error",
        message: res?.message || "Fail to update question",
      });
    }
    setIsBtnLoading(false);
  };

  ////////index////////////

  return {
    assessmentTypes,
    subjectList,
    classes,

    questionType,
    spotAssessmentData,

    questionFilters,
    handleChangeGroupFilterParams,
   

    isLoading,
    isBtnLoading,

    selectedQuestion,
    setSelectedQuestion,
    handleSearch,
    handleEditQuestion,
 
    isOpenEditModal,
    setIsOpenEditModal,


    allGroups,
  };
};

export default useQuestionGroup;
