import axios from "axios";

import { BASE_URL, FastAPI } from "../../../constant";
import { handleFormatResponse, handleFormatResponseForError } from "../../../utility";

export function useMentorReports() {

    const fetchMentorReport = async (endpoint: string) => {
        try {

            let response = await axios.get(`${BASE_URL}Reports/Dashboard/${endpoint}`);
            // console.log("Response: ", response?.data);
            const formattedResponse = handleFormatResponse(response);
            return formattedResponse;

        } catch (error: any) {

            // console.log("Error: ", error);
            const formattedError = handleFormatResponseForError(error);
            return formattedError;

        };
    };


 const fetchMentorVisitReport = async (endpoint: string) => {
   try {
     let response = await axios.get(`${BASE_URL}Mentor/${endpoint}`);
     // console.log("Response: ", response?.data);
     const formattedResponse = handleFormatResponse(response);
     return formattedResponse;
   } catch (error: any) {
     // console.log("Error: ", error);
     const formattedError = handleFormatResponseForError(error);
     return formattedError;
   }
 };


    const fetchMentorFastApiReport = async (endpoint: string) => {
        try {

            let response = await axios.get(`${FastAPI}dashboard/${endpoint}`);
            // console.log("Response: ", response?.data);
            const formattedResponse = handleFormatResponse(response);
            return formattedResponse;

        } catch (error: any) {

            // console.log("Error: ", error);
            const formattedError = handleFormatResponseForError(error);
            return formattedError;

        };
    };

    const fetchClassroomWiseObservationFast = async (endpoint: string) => {
        try {

            let response = await axios.get(`${FastAPI}report/dashboard/observation/${endpoint}`);
            // console.log("Response: ", response?.data);
            const formattedResponse = handleFormatResponse(response);
            return formattedResponse;

        } catch (error: any) {

            // console.log("Error: ", error);
            const formattedError = handleFormatResponseForError(error);
            return formattedError;

        };
    };

    return {
      fetchMentorReport,
      fetchMentorFastApiReport,
      fetchClassroomWiseObservationFast,
      fetchMentorVisitReport,
    };
};
