import React, { useContext, useEffect, useState } from 'react'
import useMasterData from '../../Hooks/useMasterData';
import { useStateAdmin } from '../../../libs/data-access';
import { showToast } from '../../../utils/utility';
import { AppContext } from '../../../AppContext';
import moment from 'moment';
import { handleValidateOrf } from '../../../utils/validators';

const UseOrfQuestion = () => {

const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true);

const [isLoading,setIsLoading] = useState<boolean>(false);

   const { user, targetRef, scrollTriger, setScrollTriger } =
     useContext(AppContext);

  const { postStateAdmin } = useStateAdmin();

    const {
      subjectList,
      getSubject,
      classes,
      getClasses,
    } = useMasterData();


    const [orfData, setOrfData] = useState<any>({
      class_Id: null,
      subject_Id: null,
      orF_Question_Text: "",
      min_Word_Read_Per_Minute: "",
      max_Seconds_To_Read: "",
      week_Start_Date: null,
      week_End_Date: null,
      assessment_Week: "100",
      created_By: 1,
      created_On: `${moment().format("YYYY-MM-DD")}`,
    });

    // console.log("orfData", orfData);

    const [confirmSave,setConfirmSave] = useState<any>(false)
    const [isSaving, setIsSaving] = useState<any>(false)

    const handleChangeORF = (name:any,value:any) => {
        setOrfData((prev:any)=>{
            return{
                ...prev,
                [name]:value
            }
        })
    }


    const SaveOrf = async() => {
      setIsLoading(true);
     let payload = {
       ...orfData,
       class_Id: Number(orfData?.class_Id),
       subject_Id: Number(orfData?.subject_Id),
       min_Word_Read_Per_Minute: Number(orfData?.min_Word_Read_Per_Minute),
       week_Start_Date: moment(orfData?.week_Start_Date).format("YYYY-MM-DD"),
       week_End_Date: moment(orfData?.week_End_Date).format("YYYY-MM-DD"),
     };

    //  console.log("payload",payload)

    const isValidated = handleValidateOrf(payload);

    if (isValidated) {
const response = await postStateAdmin(`Question/AddORFQuestionMaster`, payload);

if (response.isSuccess) {
  setOrfData({
    class_Id: null,
    subject_Id: null,
    orF_Question_Text: "",
    min_Word_Read_Per_Minute: "",
    max_Seconds_To_Read: "",
    week_Start_Date: null,
    week_End_Date: null,
    assessment_Week: "100",
    created_By: 1,
    created_On: `${moment().format("YYYY-MM-DD")}`,
  });

  showToast({
    type: "success",
    message: "Orf Question saved successfully",
  });
  setScrollTriger(!scrollTriger);
} else {
  showToast({
    type: "error",
    message: response.message || "Could not save question!",
  });
  setScrollTriger(!scrollTriger);
}
    }
      
      
      setIsLoading(false);
    }

    useEffect(() => {
      if (isFirstLoad) {
        setIsFirstLoad(false);
      } else {
        getSubject();
        getClasses();
      }
    }, [isFirstLoad]);
  return {
    classes,
    subjectList,
    orfData,
    handleChangeORF,
    confirmSave,
    setConfirmSave,
    isSaving,
    SaveOrf,
    isLoading
  };
}

export default UseOrfQuestion