import React, { useEffect, useState } from 'react'
import useMarkAttendance from '../hooks/useMarkAttendance';
import { Grid, Skeleton, Switch } from '@mantine/core';
import { FaUser } from 'react-icons/fa';
import { BsCheckCircle, BsXCircle } from 'react-icons/bs';
import { EButton } from '../../../../../../libs/ui';

type IProps = {
  isLoading: boolean;
  teacherListForAttendance: any[];
  teacherMarkAttendanceList: any[];
  handleAttendanceChange: (index: number, event: any) => void;
  handleSubmitAttendance: () => void;
};
export default function MarkNewAttendance({
  isLoading,
  teacherListForAttendance,
  handleAttendanceChange,
  handleSubmitAttendance,
  teacherMarkAttendanceList,
}: IProps) {
  //   console.log("teacherMarkAttendanceList", teacherMarkAttendanceList);
  const [eligibleSlot, setEligibleSlot] = useState(false);
  const [slotTime, setSlotTime] = useState(
    "The next attendance slot is: 8:30 am to 9:30 am"
  );

  // // Define the check function
  // function checkAttendance(attendanceData: any) {
  //   const targetDate = new Date(); // Today's date

  //   // Define time slots with start and end times (in 24-hour format)
  //   const timeSlots = [
  //     { start: 8.5, end: 9.5 }, // 8:30 am - 9:30 am
  //     { start: 13.5, end: 14.5 }, // 1:30 pm - 2:30 pm
  //     { start: 16, end: 17 }, // 4:00 pm - 5:00 pm
  //   ];

  //   // Check if there's attendance within any time slot for today
  //   const attendanceInAnySlot = timeSlots.some((slot) =>
  //     attendanceData.some((record: any) => {
  //       const attendanceDate = new Date(record.attendance_Date);

  //       // Check if the attendance date is today
  //       if (
  //         attendanceDate.getDate() === targetDate.getDate() &&
  //         attendanceDate.getMonth() === targetDate.getMonth() &&
  //         attendanceDate.getFullYear() === targetDate.getFullYear()
  //       ) {
  //         // Convert attendance hour and minute into decimal form
  //         const attendanceHour = attendanceDate.getHours();
  //         const attendanceMinute = attendanceDate.getMinutes();
  //         const attendanceTime = attendanceHour + attendanceMinute / 60;

  //         // Check if the attendance time falls within the current slot
  //         return attendanceTime >= slot.start && attendanceTime < slot.end;
  //       }
  //       return false;
  //     })
  //   );

  //   console.log("attendanceInAnySlot", attendanceInAnySlot);

  //   if (attendanceInAnySlot) {
  //     setEligibleSlot(true);
  //     //   console.log(
  //     //     "Attendance has been marked in at least one of the time slots."
  //     //   );
  //   } else {
  //     setEligibleSlot(false);
  //     //   console.log("No attendance marked in any of the specified time slots.");
  //   }
  // }

  // Define the check function
  function checkAttendance(attendanceData: any) {
    const targetDate = new Date(); // Today's date
    const currentHour = targetDate.getHours();
    const currentMinute = targetDate.getMinutes();
    const currentTime = currentHour + currentMinute / 60; // Current time in decimal format

    // Define time slots with start and end times (in 24-hour format)
    const timeSlots = [
      { start: 8.5, end: 9.5 }, // 8:30 am - 9:30 am
      { start: 13.5, end: 14.5 }, // 1:30 pm - 2:30 pm
      { start: 16, end: 17 }, // 4:00 pm - 5:00 pm
    ];

    // Find the current time slot if it exists
    const currentSlot = timeSlots.find(
      (slot) => currentTime >= slot.start && currentTime < slot.end
    );

    if (!currentSlot) {
      // No slot matches the current time
      setEligibleSlot(true);
      // console.log("No active time slot for the current time.");
      return;
    }

    // Check if there's attendance marked for today in the current time slot
    const attendanceMarked = attendanceData.some((record: any) => {
      const attendanceDate = new Date(record.attendance_Date);

      // Check if the attendance date is today
      if (
        attendanceDate.getDate() === targetDate.getDate() &&
        attendanceDate.getMonth() === targetDate.getMonth() &&
        attendanceDate.getFullYear() === targetDate.getFullYear()
      ) {
        // Convert attendance hour and minute into decimal form
        const attendanceHour = attendanceDate.getHours();
        const attendanceMinute = attendanceDate.getMinutes();
        const attendanceTime = attendanceHour + attendanceMinute / 60;

        // Check if the attendance time falls within the current slot
        return (
          attendanceTime >= currentSlot.start &&
          attendanceTime < currentSlot.end
        );
      }
      return false;
    });

    if (!attendanceMarked) {
      setEligibleSlot(false);
      // console.log(
      //   `No attendance marked in the current time slot: ${
      //     currentSlot.start * 60
      //   } - ${currentSlot.end * 60}`
      // );
    } else {
      setEligibleSlot(true);
      // console.log(
      //   "Attendance has already been marked in the current time slot."
      // );
    }
  }

  // Define the time slots in 24-hour format
  const timeSlots = [
    { start: 8.5, end: 9.5, label: "8:30 am to 9:30 am" },
    { start: 13.5, end: 14.5, label: "1:30 pm to 2:30 pm" },
    { start: 16, end: 17, label: "4:00 pm to 5:00 pm" },
  ];

  // Function to find the next available time slot
  function getNextAttendanceSlot() {
    const currentDate = new Date();
    const currentHour = currentDate.getHours();
    const currentMinute = currentDate.getMinutes();
    const currentTime = currentHour + currentMinute / 60;

    // const nextSlot = timeSlots.find((slot) => currentTime < slot.end);

    // if (nextSlot) {
    //     setSlotTime(`The next attendance slot is: ${nextSlot.label}`);
    // } else {
    //     setSlotTime("No more attendance slots available for today.");
    // }

    // Check if the current time is within any active slot
    const activeSlot = timeSlots.find(
      (slot) => currentTime >= slot.start && currentTime < slot.end
    );

    if (activeSlot) {
      setSlotTime(`The current attendance slot is active: ${activeSlot.label}`);
    } else {
      // Find the first slot where the end time is after the current time
      const nextSlot = timeSlots.find((slot) => currentTime < slot.start);

      if (nextSlot) {
        setSlotTime(`The next attendance slot is: ${nextSlot.label}`);
      } else {
        setSlotTime("No more attendance slots available for today.");
      }
    }
  }

  useEffect(() => {
    getNextAttendanceSlot();
    checkAttendance(teacherMarkAttendanceList);
  }, [teacherMarkAttendanceList]);

  return (
    <>
      {isLoading ? (
        <Skeleton height={190} radius="md" />
      ) : (
        <>
          <Grid className="flex">
            <Grid.Col>
              <div>
                <p className="mt-2 font-semibold">Available Slot</p>
                {slotTime}
              </div>
            </Grid.Col>
          </Grid>{" "}
          {teacherListForAttendance?.map((each, index) => (
            <Grid className="flex">
              <Grid.Col span={3} className="flex gap-3 mt-3 ">
                <div className="bg-slate-300 rounded-xl p-3 w-12 h-12 items-center justify-center">
                  <FaUser size={25} />
                </div>
                <div>
                  <p className="font-semibold text-md">{each?.employee_Name}</p>
                  <p className="text-sm">{each?.employee_Code}</p>
                </div>
              </Grid.Col>
              <Grid.Col span={2}>
                <div>
                  <p className="mt-2 font-semibold">Designation</p>
                  {each?.designation}
                </div>
              </Grid.Col>
              <Grid.Col span={2}>
                <Switch
                  checked={each?.is_Present}
                  onChange={(event) => handleAttendanceChange(index, event)}
                  color="teal"
                  size="md"
                  disabled={eligibleSlot}
                  label={each?.is_Present ? "Presence" : "Absence"}
                  thumbIcon={
                    each?.is_Present ? (
                      <BsCheckCircle color="dark" size={"0.8"} />
                    ) : (
                      <BsXCircle size="0.8rem" />
                    )
                  }
                />
              </Grid.Col>
            </Grid>
          ))}
          <Grid>
            <Grid.Col span={4}></Grid.Col>
            <Grid.Col span={1}>
              <div className="flex gap-2">
                <EButton onClick={() => {}} className="btn-light">
                  Cancel
                </EButton>
                <EButton
                  onClick={() => handleSubmitAttendance()}
                  className="btn-blue"
                  disabled={eligibleSlot}
                >
                  Submit Attendance
                </EButton>
              </div>
            </Grid.Col>
          </Grid>
        </>
      )}
    </>
  );
}

