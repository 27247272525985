import { Grid, MultiSelect, Select } from '@mantine/core';
import React, { useContext, useEffect, useState } from 'react'
import { IMasterData } from '../../../libs/data-access/types';
import useMasterData from '../../Hooks/useMasterData';
import { useMasterFetch } from '../../../libs/data-access';
import { IDesignationByRoleId, IDistrictBlock, IDistrictsBlock, IRole } from '../../../types';
import { SurveyFilterContext } from '../SurveyFilterContext';
import { EButton } from '../../../libs/ui';
import { FaSearch } from 'react-icons/fa';
import { showToast } from '../../../utils/utility';

type IProps = {
  withBlocks?: boolean
  studentWithClass?: boolean
}

export default function SurveyFilter({
  withBlocks = false,
  studentWithClass = false
}: IProps) {

  const {
    allRole,
    getAllRoles,
    districts,
    getDistrictByStateId,
    blocks,
    classes,
    getClasses,
    getBlocksByDistrictId,
  } = useMasterData();

  const {
    selectedDistrict,
    setSelectedDistrict,
    selectedRole,
    setSelectedRole,
    searchButton,
    isSearching,
    setIsSearching,
    classForStudent,
    setClassForStudent
  } = useContext(SurveyFilterContext)

  const { fetchMasterData } = useMasterFetch();

  const [designationByRoleId, setDesignationByRoleID] = useState<IMasterData[]>([]);
  const [isFirst, setIsFirst] = useState<boolean>(true)
  const [allDesignationByRoleId, setAllDesignationByRoleId] = useState<IDesignationByRoleId[]>([])
  const [allDistrictsBlockId, setAllDistrictsBlockId] = useState<IDistrictsBlock[]>([])

  useEffect(() => {
    if (isFirst) {
      setIsFirst(false)
    }
    else {
      getClasses();
      getAllRoles()
      getDistrictByStateId(1)
      // getDistrictList(1)   

    }
  }, [isFirst])

  useEffect(() => {
    if (selectedDistrict?.length) {
      getDistrictsBlockList(+selectedDistrict[selectedDistrict?.length - 1]?.name)
    }
    // if(selectedDistrict?.length == districts?.length){
    //   const fetchData = async () => {
    //     const promises = districts?.map(async (district) => {
    //       const resp = await fetchMasterData(`Blocks/BlocksByDistrictId/${district?.id}`);
    //       const blocks = resp?.data;
    //       return {
    //         district: district?.id,
    //         blocks: blocks,
    //       };
    //     });

    //     const data = await Promise.all(promises);
    //     console.log("Data is  :", data);
    //     data && setAllDistrictsBlockId(data);
    //   };

    //   fetchData();
    // }
  }, [selectedDistrict])

  useEffect(() => {
    if (selectedRole?.length) {
      GetDesignationByRoleId(+selectedRole[selectedRole?.length - 1]?.name)
    }
  }, [selectedRole])

  //  API Function 

  const GetDesignationByRoleId = async (roleId: number) => {
    setDesignationByRoleID([])
    let response = await fetchMasterData(`GetDesignationByRole/${roleId}`);
    if (response?.isSuccess) {
      const data: IMasterData[] = response.data.map((e: any) => ({ id: e?.designation_Id, name: e?.designation }));;
      data &&
        setAllDesignationByRoleId(
          prev => [
            ...prev, {
              roleId: roleId,
              designations: data?.map(item => ({
                id: item?.id,
                name: item?.name
              }))
            }])
    } else {
      // console.log("Error while getting roles list")
    }
  }

  const getDistrictsBlockList = async (districtID: number) => {
    let response = await fetchMasterData(`Blocks/BlocksByDistrictId/${districtID}`);
    if (response.isSuccess) {
      const data: IMasterData[] = response.data;
      data && setAllDistrictsBlockId(
        prev => [...prev, {
          district: districtID,
          blocks: data
        }]
      );
    } else {
      console.log("Error for blocks by district id: ", response.error);
    }
  }

  //  console.log("selected district ",selectedDistrict)
  //  console.log("all district ",allDistrictsBlockId)
  return (
    <div className="filter-container">
      <Grid className="w-full">
        <Grid.Col span={6}>
          <MultiSelect
            label="Role"
            size="md"
            clearable={true}
            placeholder="Select Role"
            value={selectedRole?.map((e) => e?.name)}
            data={allRole.map((e) => ({
              label: e?.name,
              value: String(e?.id),
            }))}
            onChange={(e) => {
              // console.log("Selected Role", e);
              setSelectedRole(
                e?.map((e) => ({
                  name: e,
                  designation: [
                    ...(selectedRole?.filter((item) => item?.name == e)[0]
                      ?.designation ?? []),
                  ],
                }))
              );
            }}
          />
          {selectedRole?.map((e) =>
            studentWithClass && e?.name == "10" ? (
              <MultiSelect
                label={`Class for ${
                  allRole.filter((item) => item?.id == +e?.name)[0]?.name
                }`}
                placeholder="Select a class for students"
                className="mt-3"
                size="md"
                data={classes?.map((e) => {
                  return {
                    value: String(e?.id),
                    label: e?.name,
                  };
                })}
                // data={[
                //   { label: "Class 1", value: "1" },
                //   { label: "Class 2", value: "2" },
                //   { label: "Class 3", value: "3" },
                // ]
                // }
                nothingFound="No any designation for this role"
                value={classForStudent}
                onChange={(e) => {
                  setClassForStudent
                    ? setClassForStudent(e)
                    : showToast({
                        type: "warning",
                        message: "Something went wrong",
                      });
                }}
              />
            ) : e?.name == "2" ? (
              <></>
            ) : (
              <MultiSelect
                label={`Designation for ${
                  allRole.filter((item) => item?.id == +e?.name)[0]?.name
                }`}
                placeholder="Select designation"
                className="mt-3"
                size="md"
                data={
                  allDesignationByRoleId?.length
                    ? allDesignationByRoleId?.filter(
                        (item) => item?.roleId == +e?.name
                      )?.length
                      ? allDesignationByRoleId
                          ?.filter((item) => item?.roleId == +e?.name)?.[0]
                          ?.designations?.map((each) => ({
                            label: each?.name,
                            value: String(each?.id),
                          }))
                      : []
                    : []
                }
                nothingFound="No any designation for this role"
                value={e?.designation ? e?.designation : []}
                onChange={(value) => {
                  let roleIndex = selectedRole?.findIndex(
                    (item) => item?.name == e?.name
                  );
                  const newRoles = [...selectedRole];
                  newRoles[roleIndex].designation = value;
                  setSelectedRole(newRoles);
                }}
              />
            )
          )}
        </Grid.Col>
        <Grid.Col span={6}>
          <MultiSelect
            size="md"
            label="District"
            placeholder="Select District"
            value={selectedDistrict?.map((e) => e?.name)}
            data={[
              {
                label: "All",
                value: "-1",
                disabled: selectedDistrict?.length ? true : false,
              },
            ].concat(
              districts.map((e) => ({
                label: e?.name,
                value: String(e?.id),
                disabled: false,
              }))
            )}
            onChange={(e) => {
              if (e[0] === "-1") {
                const fetchData = async () => {
                  const promises = districts?.map(async (district) => {
                    const resp = await fetchMasterData(
                      `Blocks/BlocksByDistrictId/${district?.id}`
                    );
                    return {
                      name: String(district?.id),
                      blocks: resp?.data,
                    };
                  });
                  const data = await Promise.all(promises);
                  const selectedData: IDistrictBlock[] = data?.map((e) => ({
                    name: e?.name,
                    blocks: e?.blocks.map((item: any) => item?.id),
                  }));
                  const allDistrictData: IDistrictsBlock[] = data?.map((e) => ({
                    district: +e?.name,
                    blocks: e?.blocks,
                  }));
                  selectedData && setSelectedDistrict(selectedData);
                  allDistrictData && setAllDistrictsBlockId(allDistrictData);
                };

                fetchData();
              } else {
                setSelectedDistrict(
                  e?.map((e) => ({
                    name: e,
                    blocks: [
                      ...(selectedDistrict?.filter((item) => item?.name == e)[0]
                        ?.blocks ?? []),
                    ],
                  }))
                );
              }
            }}
            // onChange={e =>  {
            //   if(e[0] === "-1"){
            //     districts &&
            //     setSelectedDistrict(
            //         districts.map(e =>
            //           ({
            //             name: String(e?.id),
            //             blocks: []
            //           })))
            //   }
            //   else{
            //   setSelectedDistrict(
            //     e?.map(e =>
            //     ({
            //       name: e,
            //       blocks: [...selectedDistrict?.filter(item => item?.name == e)[0]?.blocks ?? []]
            //     })))
            // }}}
          />
          {withBlocks ? (
            selectedDistrict?.map((e) => (
              <MultiSelect
                label={`Blocks for ${
                  districts?.filter((each) => each?.id == +e?.name)[0]?.name
                }`}
                placeholder="Select"
                className="mt-3"
                size="md"
                data={
                  allDistrictsBlockId?.length
                    ? allDistrictsBlockId?.filter(
                        (item) => item?.district == +e?.name
                      )?.length
                      ? allDistrictsBlockId
                          ?.filter((item) => item?.district == +e?.name)?.[0]
                          ?.blocks?.map((each) => ({
                            label: each?.name,
                            value: String(each?.id),
                          }))
                      : []
                    : []
                }
                nothingFound="No any designation for this role"
                value={e?.blocks ? e?.blocks?.map((e) => String(e)) : []}
                onChange={(value) => {
                  let roleIndex = selectedDistrict?.findIndex(
                    (item) => item?.name == e?.name
                  );
                  const newRoles = [...selectedDistrict];
                  newRoles[roleIndex].blocks = value;
                  setSelectedDistrict(newRoles);
                }}
              />
            ))
          ) : (
            <></>
          )}
        </Grid.Col>
      </Grid>
      {searchButton && setIsSearching && (
        <div className="flex justify-end ml-4 items-start pt-7">
          <EButton
            className="btn-blue"
            onClick={() => setIsSearching(!isSearching)}
          >
            Search
          </EButton>
        </div>
      )}
    </div>
  );
}
