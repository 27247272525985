import React, { useContext, useEffect, useState } from 'react'
import { AssessmentFilterContext } from '../../Components/Hooks/useAssessmentFilter';
import { ISpotAssessmentDownloadReport, ISpotAssessmentCompletedData, ISpotAssessmentCompleteExcelReport, ISpotAssessmentCompletedMentorWiseExcelReport } from '../../../../../libs/data-access/types';
import moment from 'moment';
import { calculateYearForDashboard, showToast } from '../../../../../utils/utility';
import { useStudentLearning } from '../../../../../libs/data-access';

export const useSpotAssessmentsCompleted = () =>  {

    const {
      fetchSpotAssessment
    } =useStudentLearning()
    
    const {
      handleChangeFilters,
      handleIsSearching,
      isSearching,
      district,
      block,
      cluster,
      school,
      grade,
      subject,
      competency,
      month,
      activeTab,
      selectedSession,
    } = useContext(AssessmentFilterContext);
    const [isFirst,setIsFirst]=useState<boolean>(true)
    const [isLoading,setIsLoading]=useState<boolean>(false)
    const [isLoadingDownloadReport,setIsLoadingDownloadReport]=useState<boolean>(false)
    const [isMapLoading,setIsMapLoading]=useState<boolean>(false)
    const [keysForTableHeader,setKeysForTableHeader] =useState<any[]>([])
    const [keysForCompetency,setKeysForCompetency] =useState<any[]>([])
    const [spotAssessmentCompletedData,setSpotAssessmentCompletedData]=useState<ISpotAssessmentCompletedData[]>([])
    const [spotAssessmentMapData,setSpotAssessmentMapData]=useState<ISpotAssessmentCompletedData[]>([])
    // data for CSV download
    const [spotAssessmentCompletedCSV, setSpotAssessmentCompletedCSV] = useState<{ headers: any[], content: any[] }>();

    const [totalForData,setTotalForData] = useState<any>({})

  useEffect(()=>{
    if(isSearching && activeTab=="2"){
      getSpotAssessmentCompletedData()
      getSpotAssessmentMapDate()
      getSpotAssessmentDownloadReport()
  }
    else{
    // console.log(isSearching)
}},[isSearching])

useEffect(()=>{
  setSpotAssessmentCompletedData([])
  setKeysForTableHeader([])
  setSpotAssessmentMapData([])
  setSpotAssessmentCompletedCSV(undefined)
},[activeTab])

    useEffect(()=>{
       if(isFirst){
        setIsFirst(false)
       }
       else{

       }
       },[isFirst])

  //   const formatSpotAssessmentResultReport = (visitCompliance: ISpotAssessmentCompleteExcelReport[]) => {

  //     const headers = [
  //         { label: "District", key: "District_Name" },
  //         { label: "Block", key: "Block_Name" },
  //         { label: "Cluster School Name", key: "Cluster_School_Name" },
  //         { label: "School Name", key: "School_Name" },
  //         { label: "Subject Name", key: "Subject_Name" },
  //         { label: "Mentor Name", key: "Mentor_Name" },
  //         { label: "Total Spot Completed", key: "TOTAL_SPOT_COMPLETED" },  
  //     ];

  //     const data = visitCompliance?.map(each => Object.assign({  
  //       District_Name: each?.District_Name || "-",
  //       Block_Name: each?.Block_Name || "-",
  //       Cluster_School_Name :each?.Cluster_School_Name || "-",
  //       School_Name: each?.School_Name || "-",
  //       Subject_Name: each?.Subject_Name || "-",
  //       Mentor_Name:each?.Mentor_Name || "-",
  //       TOTAL_SPOT_COMPLETED:each?.TOTAL_SPOT_COMPLETED || "0"
  //     }));

  //     setSpotAssessmentCompletedCSV({
  //         headers: headers,
  //         content: data
  //     });

  // };
    const formatSpotAssessmentResultReport = (visitCompliance: ISpotAssessmentCompletedMentorWiseExcelReport[]) => {

      const headers = [
          { label: "Mentor Name", key: "Mentor_Name" },
          { label: "District", key: "District_Name" },
          { label: "Block", key: "Block_Name" },
          { label: "Cluster School Name", key: "Cluster_School_Name" },
          { label: "School Name", key: "School_Name" },
          { label: "Total Spot Target", key: "TOTAL_SPOT_Target" },
          { label: "Total Spot Completed", key: "TOTAL_SPOT_COMPLETED" },  
          { label: "Total Spot Percentage", key: "TOTAL_SPOT_PERCENTAGE" },
      ];

      const data = visitCompliance?.map(each => Object.assign({  
        Mentor_Name: each?.Mentor_Name || "-",
        District_Name: each?.District_Name || "-",
        Block_Name: each?.Blocks.join(" , ") || "-",
        Cluster_School_Name :each?.Clusters.join(" , ") || "-",
        School_Name: each?.Schools.join(" , ") || "-",
        TOTAL_SPOT_Target:each?.TOTAL_SPOT_Target || "0" ,
        TOTAL_SPOT_COMPLETED: each?.TOTAL_SPOT_COMPLETED || "-",
        TOTAL_SPOT_PERCENTAGE:each?.TOTAL_SPOT_PERCENTAGE|| "-",
      }));

      setSpotAssessmentCompletedCSV({
          headers: headers,
          content: data
      });

  };
 
    //  API Functions

    const getSpotAssessmentCompletedData = async()=>{
        setIsLoading(true)
        setSpotAssessmentCompletedData([])
        setKeysForTableHeader([])
        const res = await fetchSpotAssessment(
          `Compliance/NumberOfSpotAssessmentTargetCompleted?DistrictId=${
            district ?? 0
          }&BlockId=${block ?? 0}&ClusterId=${cluster ?? 0}&Date=${`${
            month
              ? calculateYearForDashboard(+month, Number(selectedSession))
              : moment().format("YYYY")
          }/${month}/01`}&ClassId=${grade ?? 0}&SubjectId=${[subject]}`
        );
          if(res?.isSuccess){    
          setIsLoading(false)
          handleIsSearching(false)
            setSpotAssessmentCompletedData(res?.data)
               let KEYS = Object.keys(res?.data?.[0])
               setKeysForTableHeader(KEYS)

               let total = {
                 name: "total",
                 Total_Mentor: res?.data?.reduce(
                   (sum: any, item: any) => sum + item?.Total_Mentor,
                   0
                 ),
                 Total_Spot_Completed: res?.data?.reduce(
                   (sum: any, item: any) => sum + item?.Total_Spot_Completed,
                   0
                 ),
                 PercentageOfSpotMentor: (
                   res?.data?.reduce(
                     (sum: any, item: any) =>
                       sum + Number(item?.PercentageOfSpotMentor?.slice(0, -1)),
                     0
                   ) / res?.data?.length
                 ).toFixed(2),
               };

               setTotalForData(total)
          }
          else{
            showToast({
              type:"error",
              message: res?.message ?? "Error while generating spot assessment result"
            })
          }
          setIsLoading(false)
          handleIsSearching(false)
    }

    // MAP DATA

    const getSpotAssessmentMapDate = async()=>{
      setSpotAssessmentMapData([])
        setIsMapLoading(true)
        const res = await fetchSpotAssessment(
          `Compliance/NumberOfSpotAssessmentTargetCompleted?DistrictId=${0}&BlockId=${0}&ClusterId=${0}&Date=${`${
            month
              ? calculateYearForDashboard(+month, Number(selectedSession))
              : moment().format("YYYY")
          }/${month}/01`}&ClassId=${grade ?? 0}&SubjectId=${[subject]}`
        );
          if(res?.isSuccess){
            setSpotAssessmentMapData(res?.data)
          }
          else{
            showToast({
              type:"error",
              message: res?.message ?? "Error while generating spot assessment result"
            })
          }
          setIsMapLoading(false)
          handleIsSearching(false)
    }
    const getSpotAssessmentDownloadReport = async()=>{
      setIsLoadingDownloadReport(true)
      setSpotAssessmentCompletedCSV(undefined)
        const res = await fetchSpotAssessment(
          `Compliance/NumberOfSpotAssessmentCompleted/ReportPart2/Download?SubjectId=${
            subject.length ? subject : [0]
          }&Date=${`${
            month
              ? calculateYearForDashboard(+month, Number(selectedSession))
              : moment().format("YYYY")
          }/${month}/01`}&ClassId=${grade ?? 0}`
        );
          if(res?.isSuccess){
            formatSpotAssessmentResultReport(res?.data)
          }
          else{
            showToast({
              type:"error",
              message: res?.message ?? "Error while generating spot assessment Excel"
            })
          }
          setIsLoadingDownloadReport(false)
    }
  return {
    isLoading,
    spotAssessmentCompletedData,
    spotAssessmentCompletedCSV,
    keysForTableHeader, 
    keysForCompetency,
    spotAssessmentMapData,
    isMapLoading,
    isLoadingDownloadReport,
    totalForData
  }
}

